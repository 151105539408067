import React, { Component } from 'react'
import Menu from './Navigation/Menu'
import QuickNav from './Navigation/QuickNav'
import MobileSideBar from './Navigation/Mobile/MobileSideBar'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class Template extends Component {
    constructor(){
        super()
        this.state = {
            openMobileSideBar: false,
            scrolledNav: false
        }
    }

    toggleSideBar = (indicator = false) => {
        this.props.onToggleCartSlider(false)
        this.setState({
            openMobileSideBar: indicator,
        })
    }

    toggleCart = (indicator = false) => {
        this.props.onToggleCartSlider(indicator)
        this.setState({
            openMobileSideBar: false
        })
    }

    render() {
        const { openSliderCart, cartData, config } = this.props
        const { scrolledNav } = this.state

        const sideBarHandler = {
            open: this.state.openMobileSideBar,
            toggleSideBar: this.toggleSideBar
        }

        const cartPlugin = {
            open: openSliderCart,
            toggleCart: this.toggleCart,
            cartInfo: {
                items: cartData
            }
        }
        
        return (
            <div className={ `wrapper ${ openSliderCart ? 'slide-cart-open' : '' }` }>
                {
                    !config?.hideNavBar &&
                    <nav className={ `navbar navbar-expand-lg ${ scrolledNav ? 'has-shadow' : '' }` }>
                        <button className="toggle-mobile-menu" type="button" onClick={ () => this.toggleSideBar(true) }>
                            <img src="/images/icon/more.svg" alt="more"/>
                        </button>
                        <div className="brand-area">
                            <NavLink to={ `/` }>
                                <img className="brand" src="/images/logo/baller.png" alt="Baller Main Logo"/>
                            </NavLink>
                        </div>
                        <Menu { ...this.props } sideBarHandler={ sideBarHandler }/>
                        <QuickNav { ...this.props } cartPlugin={ cartPlugin }/>
                        <MobileSideBar { ...this.props }sideBarHandler={ sideBarHandler }/>
                    </nav>
                }
                <div id="content" className={ config?.hideNavBar ? 'content-without-navbar' : 'normal-content' }>
                    { this.props.children }
                </div>
                <Footer />
            </div>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(Template) 