import React from 'react'

const ColorPicker = (props) => {
    const { detailConfig, handleColorChange } = props
    const { detailData, selection } = detailConfig
    const { size, color } = selection

    const availableColors = detailData.available_sizes.find(el => {
        return parseInt(el.id) === parseInt(size)
    })?.available_colors

    if(!availableColors || !detailData.have_colors){
        return null
    }

    const selectedColor = availableColors.find(el => {
        return el.id === parseInt(color)
    })
    
    return (
        <div className="color-picker text-left">
            <p className="detail-info-selected">choose color <strong>{ selectedColor?.name }</strong></p>
            <div className="color-circles">
                {
                    availableColors.map(
                        (el, index) =>
                        <button  
                            className={ `color-button ${ parseInt(color) === parseInt(el.id) ? 'selected' : '' }` }  
                            type="button"
                            key={ index }
                            onClick={ () => handleColorChange(el.id) }
                        >
                            <span className="color" style={ {
                                backgroundColor: el.hexcode
                            } } title={ el.name }/>
                        </button>
                    )
                }
            </div>
        </div>
    )
}

export default ColorPicker