import React, { Component } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { dateObjToString, stringToCurrency } from 'Services/Helpers/Main'

class ResponsiveTable extends Component {
    generateHead = () => {
        const { config } = this.props
        const columnsHeader = config?.columnsHeader
        return(
            <Thead>
                <Tr>
                    {
                        columnsHeader && columnsHeader.map((row, index) => {
                            let title = row.header
                            if(title === 'action'){
                                title = ''
                            }
                            return(<Th key={ index }>{ title }</Th>)
                        })
                    }
                </Tr>
            </Thead>
        )
    }

    renderCellData = (cellData, type = 'string') => {
        let mutatedCell = cellData
        if(type === 'date'){
            mutatedCell = dateObjToString(cellData)
        }else if(type === 'currency'){
            mutatedCell = stringToCurrency(cellData)
        }
        return mutatedCell
    }

    handleClickRowTable = (data) => {
        const { config } = this.props
        if(config.onClickRow){
            return config.onClickRow(data)
        }

        return false
    }

    rowTable = (index, rowOriginal) => {
        const { config } = this.props
        const columnsHeader = config?.columnsHeader
        let rowData = []

        columnsHeader && columnsHeader.map((row, index) => {
            let cellData = '-'
            let classAction = null
            if(row.header === 'action'){
                classAction = 'is-action-row'
            }
            if(row.Cell){
                cellData = <row.Cell original={ rowOriginal }/>
            }else{
                if(row.accessor && rowOriginal[row.accessor]){
                    if(rowOriginal[row.accessor]){
                        cellData = this.renderCellData(rowOriginal[row.accessor], row.type)
                    }
                }
            }

            cellData = <Td className={ classAction } key={ index }>{ cellData }</Td>
            return rowData.push(cellData)
        })

        let defaultClassRow = `table-row`
        if(config?.onClickRow){
            defaultClassRow = `${ defaultClassRow }  can-hover`
        }

        return(
            <Tr key={ index } className={ defaultClassRow } onClick={ () => this.handleClickRowTable(rowOriginal) }>
                { rowData }
            </Tr>
        )
    }

    generateBody = () => {
        const { data, config } = this.props
        if(!data) return null

        return(
            <Tbody>
                {
                    data.length <= 0 ? 
                    <Tr className="table-row">
                        <Td className="text-center" colSpan={ config?.columnsHeader.length }>No Data</Td>
                    </Tr> :
                    null
                }
                {
                    data.map((rowData, index) => {
                        return this.rowTable(index, rowData)
                    })
                }
            </Tbody>
        )
    }
    
    render() {
        const { className } = this.props
        let classNameText = 'base-table'
        if(className){
            classNameText = `${ classNameText } ${ className }`
        }
        return (
            <div className="table-container">
                <div className="table-body-wrapper">
                    <Table className={ classNameText }>
                        { this.generateHead() }
                        { this.generateBody() }
                    </Table>
                </div>
            </div>
        )
    }
}

export default ResponsiveTable