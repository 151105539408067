import React, { Component } from 'react'
import AccountTemplate from '../../Section/AccountTemplate'
import AddressForm from './AddressForm'

class AddAddress extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false,
            provinceOpt: []
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    handleSubmitForm = formData => {
        this.toggleProcess(true)
    }

    render() {
        const accountConfig = {
            backPathUrl: '/account/address',
            backPathLabel: 'Back to my address'
        }
        return (
            <AccountTemplate config={ accountConfig } title="My Address" { ...this.props }>
                <div className="section-account">
                    <AddressForm { ...this.props } formType={ 'add' }/>
                </div>
            </AccountTemplate>
        )
    }
}

export default AddAddress