import React from 'react'
import Profile from './Content/Profile'
import Address from './Content/Address'
import AddAddress from './Content/Address/Add'
import EditAddress from './Content/Address/Edit'
import OrderHistoryList from './Content/History/List'
import OrderHistoryDetail from './Content/History/Detail'
import Support from './Content/Support'

export const AccountPageConfig = {
    pageName: 'Account',
    routes: [   
        {
            path: '/account/profile',
            exact: true,
            authorizedOnly: true,
            Component: props => {
                return(<Profile { ...props } />)
            }
        },
        {
            path: '/account/address',
            exact: true,
            authorizedOnly: true,
            Component: props => {
                return(<Address { ...props } />)
            }
        },
        {
            path: '/account/address/add',
            exact: true,
            authorizedOnly: true,
            Component: props => {
                return(<AddAddress { ...props } />)
            }
        },
        {
            path: '/account/address/:id',
            exact: true,
            authorizedOnly: true,
            Component: props => {
                return( <EditAddress { ...props }/>)
            }
        },
        {
            path: '/account/order-history',
            exact: true,
            authorizedOnly: true,
            Component: props => {
                return(<OrderHistoryList { ...props } />)
            }
        },
        {
            path: '/account/order-history/:orderId',
            exact: true,
            authorizedOnly: true,
            Component: props => {
                return(<OrderHistoryDetail { ...props } />)
            }
        },
        {
            path: '/account/support',
            exact: true,
            authorizedOnly: true,
            Component: props => {
                return(<Support { ...props } />)
            }
        }
    ]
}