import React, { Component } from 'react'
import { thousandSeparator } from 'Services/Helpers/Main'

class UserItems extends Component {
    renderPersonalize = (personalizeDetail) => {
        let cleanPersonalized = personalizeDetail.filter(el => {
            return el.value.length > 0
        })

        if(cleanPersonalized && cleanPersonalized.length > 0){
            return(
                <div className="personalize-wrapper">
                    <div className="head-personalize">
                        Personalization
                    </div>
                    {
                        cleanPersonalized.map((row, index ) => {
                            return(
                            <span key={ index } className="personalize-info">{ row.label }: { row.value } ({ row.price === 0 ? 'FREE' : row.price })</span>
                            )
                        })
                    }
                </div>
            )
        }

        return null
    }

    render() {
        const { cartData } = this.props
        return (
            <div className="item-content">
                {
                    cartData.map((item, index) => {
                        let attributes = []
                        let personalizeDetail = []
                        if(item.colorName){
                            attributes.push({
                                label: 'Color',
                                value: item.colorName
                            })
                        }

                        attributes.push({
                            label: 'Qty',
                            value: 1
                        })

                        if(item.personalized){
                            const personalizeInfo = item.personalized
                            if(personalizeInfo.suitcase){
                                personalizeDetail.push({
                                    label: 'Suitcase',
                                    value: personalizeInfo.suitcase.letter,
                                    price: personalizeInfo.suitcase.price
                                })
                            }
                            if(personalizeInfo.tag){
                                personalizeDetail.push({
                                    label: 'Initial',
                                    value: personalizeInfo.tag.letter,
                                    price: personalizeInfo.tag.price
                                })
                            }
                        }

                        return(
                            <div className="cart-item" key={ index }>
                                <div className="image-wrapper">
                                    {
                                        item.image && <img src={ item.image.image_url } alt="prod" />
                                    }
                                </div>
                                <div className="item-detail">
                                    <span className="title">{ `${ item.name } ${ item.sizeName }` }</span>
                                    {
                                        attributes.map((attr, index) => {
                                            return(<span key={ index }>{ attr.label }: { attr.value }</span>)
                                        })
                                    }
                                    { this.renderPersonalize(personalizeDetail) }
                                    <span className="price">Rp.{ thousandSeparator(item.price) }</span>
                                </div>
                            </div> 
                        )
                    })
                }
            </div>
            
        )
    }
}

export default UserItems