import React, { Component } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import PageTemplate from 'Components/Page/Template'
import BreadCrumb from './BreadCrumb'
import UserInformation from './Cards/UserInformation'
import ShippingAddress from './Cards/ShippingAddress'
import ShipOptions from './Cards/ShipOptions'
import Cart from '../Cart'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import PaymentAPI from 'Services/API/Authorized/PaymentAPI'
import { handleToast } from 'Services/Toastify'

class AuthorizedMain extends Component {
    constructor(){
        super()
        this.state = {
            currentStep: 'information',
            userInfo: null,
            selectedAddress: null,
            shipmentInfo: null,
            allStep: [
                'information',
                'shipment',
                'payment'
            ],
            isLoadingAddress: true,
            isProcessingPayment: false
        }
    }

    toggleLoadingAddress = flag => {
        this.setState({
            isLoadingAddress: flag ? true: false
        })
    }

    toggleProcessPayment = flag => {
        this.setState({
            isProcessingPayment: flag ? true: false
        })
    }

    componentDidMount(){
        // set user info
        const { currentUser } = this.props
        if(currentUser){
            this.setState({
                userInfo: {
                    name: currentUser.name,
                    email: currentUser.email,
                    phone_number: currentUser.phone_number
                } 
            })

            AccountAPI.getPrimaryAddress().then(res => {
                if(res.status === 200){
                    this.setState({
                        selectedAddress: res.data.data
                    })
                }

                this.toggleLoadingAddress(false)
            })
        }
    }

    setUserInfo = (obj) => {
        if(this.state.isProcessingPayment){
            return false
        }
        this.setState({
            userInfo: obj
        })
    }

    setSelectedAddress = (obj) => {
        if(this.state.isProcessingPayment){
            return false
        }
        this.setState({
            selectedAddress: obj,
            shipmentInfo: null
        })
    }

    setStep = step => {
        if(this.state.isProcessingPayment){
            return false
        }
        this.setState({
            currentStep: step
        })
    }

    setShipmentInfo = obj => {
        if(this.state.isProcessingPayment){
            return false
        }
        this.setState({
            shipmentInfo: obj
        })
    }

    handlePurchase = () => {
        this.toggleProcessPayment(true)
        const { cartData } = this.props
        const { userInfo, selectedAddress, shipmentInfo } = this.state

        let objPurchase = {
            contact_name: userInfo.name,
            contact_number: userInfo.phone_number,
            contact_email: userInfo.email,
            address: selectedAddress.address,
            province_id: selectedAddress.province_id,
            province: selectedAddress.province_name,
            city_id: selectedAddress.city_id,
            city: selectedAddress.city_name,
            latitude: selectedAddress.latitude,
            longitude: selectedAddress.longitude,
            zip_code: selectedAddress.zip_code,
            remarks: selectedAddress.remarks,
            shipping_code: shipmentInfo.code,
            shipping_service: shipmentInfo.service,
            items: []
        }

        const mappedCartItems = cartData.map(el => {
            // suitcase
            let suitcaseTextLabel = el.personalized && el.personalized.suitcase ? el.personalized.suitcase.letter : null
            let suitcaseTextPosition = null
            if(suitcaseTextLabel !== null){
                suitcaseTextPosition = el.personalized && el.personalized.suitcase ? el.personalized.suitcase.selectedMargin : null
            }

            // tag
            let tagTextLabel = el.personalized && el.personalized.tag ? el.personalized.tag.letter : null
            let tagTextProductId = null
            let tagTextSizeId = null
            let tagTextColorId = null
            if(tagTextLabel !== null){
                tagTextProductId = 2
                tagTextSizeId = 1
                tagTextColorId = el.personalized && el.personalized.tag ? el.personalized.tag.tagColor : null
            }

            return {
                product_id: el.id,
                size_id: el.sizeId,
                color_id: el.colorId,
                suitcase_text: suitcaseTextLabel,
                suitcase_text_position: suitcaseTextPosition,
                tag_text: tagTextLabel,
                tag_text_product_id: tagTextProductId,
                tag_text_size_id: tagTextSizeId,
                tag_text_color_id: tagTextColorId
            }
        })

        objPurchase = {
            ...objPurchase,
            items: mappedCartItems
        }

        PaymentAPI.checkout(objPurchase).then(res => {
            this.toggleProcessPayment(false)
            if(res.status === 200){
                this.props.onSyncCart([])
                window.location.replace(res.data.data.payment_url)
            }else{
                handleToast(res.data.message, 'warning')
            }
        })
    }

    renderPayButton = () => {
        const { userInfo, selectedAddress, shipmentInfo, isProcessingPayment } = this.state
        let disabled = true
        if(userInfo && selectedAddress && shipmentInfo){
            disabled = false
        }
        return(
            <div className="info-card">
                <div className="payment-button">
                    <button 
                        type="button" 
                        className="action invert" 
                        disabled={ disabled || isProcessingPayment }
                        onClick={ this.handlePurchase }
                    >
                        { !isProcessingPayment ? 'Continue to Payment' : 'Loading...' }
                    </button>
                </div>
            </div>
        )
    }

    render() {
        const { currentStep, allStep, userInfo, isLoadingAddress, selectedAddress, shipmentInfo, isProcessingPayment } = this.state
        const { currentUser, cartData } = this.props
        if(!currentUser){
            return(<Redirect to={ `/checkout/authorize` } />)
        }

        return (
            <PageTemplate { ...this.props } config={ { hideNavBar: true } }>
                <div className="checkout-section">
                    <Cart cartData={ cartData } shipmentInfo={ shipmentInfo }/>
                    <div className="user-info order-md-1 order-sm-2">
                        <div className="navbar-checkout">
                            <div className="brand-area">
                                <NavLink className="link-brand" to="/">
                                    <img className="brand" src="/images/logo/baller.png" alt="Baller Main Logo" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="info-content-user">
                            <div className="checkout-shipping">
                                <BreadCrumb currentStep={ currentStep } allStep={ allStep }/>
                                <UserInformation 
                                    { ...this.props } 
                                    userInfo={ userInfo } 
                                    setUserInfo={ this.setUserInfo } 
                                    isProcessingPayment={ isProcessingPayment }
                                />
                                <ShippingAddress 
                                    selectedAddress={ selectedAddress }
                                    setSelectedAddress={ this.setSelectedAddress } 
                                    isLoadingAddress={ isLoadingAddress } 
                                    toggleLoadingAddress={ this.toggleLoadingAddress }
                                    isProcessingPayment={ isProcessingPayment }
                                />
                                <ShipOptions 
                                    cartData={ cartData } 
                                    selectedAddress={ selectedAddress } 
                                    setStep={ this.setStep }
                                    shipmentInfo={ shipmentInfo }
                                    setShipmentInfo={ this.setShipmentInfo }
                                />
                                { this.renderPayButton() }
                            </div>
                        </div>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(AuthorizedMain) 