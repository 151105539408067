import React, { Component } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import LoginCheckout from './Login/LoginCheckout'
import PageTemplate from 'Components/Page/Template'
import Cart from '../Cart'
import GuestCheckout from './Checkout'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class Main extends Component {
    constructor(){
        super()
        this.state = {
            guestPage : 'login',
            shipmentInfo: null
        }
    }

    toggleGuestPage = value => {
        this.setState({
            guestPage: value
        })
    }

    setShipmentInfo = obj => {
        this.setState({
            shipmentInfo: obj
        })
    }

    render() {
        const { shipmentInfo } = this.state
        const { currentUser, cartData } = this.props
        if(currentUser){
            return(<Redirect to={ `/checkout/shipment` } />)
        }

        return (
            <PageTemplate { ...this.props } config={ { hideNavBar: true } }>
                <div className="checkout-section">
                    <Cart cartData={ cartData } shipmentInfo={ shipmentInfo }/>
                    <div className="user-info order-md-1 order-sm-2">
                            <div className="navbar-checkout">
                                <div className="brand-area">
                                    <NavLink className="link-brand" to="/">
                                        <img className="brand" src="/images/logo/baller.png" alt="Baller Main Logo" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="info-content-user">
                                <div className="checkout-shipping checkout-guest-main">
                                    <PerfectScrollbar>
                                        <LoginCheckout toggleGuestPage={ this.toggleGuestPage } />
                                        <div className="guest-checkout">
                                            <h3 className="separate-form">OR</h3>
                                            <GuestCheckout { ...this.props } setShipping={ this.setShipmentInfo }/>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </div>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(Main) 