import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import Icon from 'Components/Icons/FontAwesome/Icon'
import { NavLink, Redirect } from 'react-router-dom'

class PaymentMain extends Component {
    render() {
        const { params } = this.props.match
        const flag = params.flag

        let icon
        let title
        let desc
        if(flag === 'success'){
            icon = 'check-circle'
            title = 'Payment Successful'
            desc = 'We have received your payment successfully. Please view your order history in account menu.'
        }else if(flag === 'unfinished'){
            icon = 'clock'
            title = 'Waiting for payment'
            desc = 'Please complete your payment purchase to complete your order.'
        }else if(flag === 'error'){
            icon = 'times'
            title = 'Payment Failed'
            desc = 'We apologize your payment is not completed, please try again or contact our customer service.'
        }

        if(!icon && !title && !desc){
            return(<Redirect to={ '/' } />)
        }
        
        return (
            <PageTemplate { ...this.props }>
                <div className="payment-result-wrapper">
                    <div className="container">
                        <div className="payment-result text-center">
                            <div className={ `icon-wrapper ${ flag}` }>
                                <Icon name={ icon }/>
                            </div>
                            <h3>{ title }</h3>
                            <p>{ desc }</p>
                            <NavLink className="button action invert" to={ `/catalog` }>shop again</NavLink>
                        </div>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default PaymentMain