import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import BannerSlider from 'Components/Image/BannerSlider'
import BannerAPI from 'Services/API/Guest/BannerAPI'

class SliderBanner extends Component {
    constructor(){
        super()
        this.state = {
            dragging: false,
            banners: []
        }
    }

    toggleDrag = flag => {
        this.setState({
            dragging: flag ? true : false
        })
    }

    componentDidMount(){
        BannerAPI.get().then(res => {
            if(res.status === 200){
                this.setState({
                    banners: res.data.data
                })
            }
        })
    }

    render() {
        const { dragging, banners } = this.state
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 5000,
            beforeChange: () => this.toggleDrag(true),
            afterChange: () => this.toggleDrag(false),
        }

        return (
            <div className="slider-banner">
                <Slider { ...settings } ref={ slider => ( this.slider = slider ) }>
                    {
                        banners.map((el, index) => {
                            return(
                                <a 
                                    key={ index }
                                    href={ el.link } 
                                    target={ el.is_open_new_window ? '_blank' : '_self' } 
                                    className="clicker"
                                    onClick={ e => dragging && e.preventDefault() }
                                >
                                    <BannerSlider src={ el.image_url } />
                                </a>
                            )
                        })
                    }
                </Slider>
            </div>
        );
    }
}

export default SliderBanner;