import React from 'react'
import Guest from './Sections/Guest/Main'
import Authorized from './Sections/Authorized/Main'

export const CheckoutPageConfig = {
    pageName: 'Checkout',
    routes: [
        {
            path: '/checkout/authorize',
            authorizedOnly: false,
            exact: true,
            Component: props => {
                return(<Guest { ...props } />)
            }
        },
        {
            path: '/checkout/shipment',
            authorizedOnly: false,
            exact: true,
            Component: props => {
                return(<Authorized { ...props } />)
            }
        }
    ]
}