import React, { Component } from 'react'
import DefaultForm from 'Components/Form/Default'
import FormButton from 'Components/Form/Buttons/Default'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import { handleToast } from 'Services/Toastify'

class ProfileForm extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false
        }
    }

    toggleProcess = (flag) => {
        this.setState({
            isProcessingProfile: flag ? true : false
        })
    }

    handleSubmitPassword = (formData) => {
        this.toggleProcess(true)
        AccountAPI.changePassword(formData.value).then(res => {
            this.toggleProcess(false)
            if(res.status === 200){
                handleToast('Successfully update your password')
            }else{
                handleToast(res.data.message, 'warning')
            }
        })
    }

    render() {
        return (
            <div className="section-account">
                <h5 className="form-title">Password</h5>
                <DefaultForm 
                    { ...this.props }
                    className={ `account-form` }
                    isProcessing={ this.state.isProcessing }
                    handleSubmit={ this.handleSubmitPassword }
                    resetFormAfterSubmit={ true }
                    formInputs={ 
                        [
                            {
                                name: 'old_password',
                                type: 'TextInputPassword',
                                defaultValue: '',
                                validator: 'required',
                                meta: {
                                    label: {
                                        text: 'Old Password',
                                        show: false
                                    },
                                    placeHolder: 'Old Password',
                                }
                            },
                            {
                                name: 'new_password',
                                type: 'TextInputPassword',
                                defaultValue: '',
                                validator: 'required',
                                meta: {
                                    label: {
                                        text: 'New Password',
                                        show: false
                                    },
                                    placeHolder: 'New Password',
                                }
                            },
                            {
                                name: 'new_password_confirmation',
                                type: 'TextInputPassword',
                                defaultValue: '',
                                validator: 'required',
                                meta: {
                                    label: {
                                        text: 'Confirm Password',
                                        show: false
                                    },
                                    placeHolder: 'Confirm Password',
                                }
                            },
                        ]
                    }
                    actionComponent={ props => {
                        return(
                            <div className="form-group form-action-d-flex mb-3">
                                <FormButton 
                                    className="action invert"
                                    label={ `Change Password` }
                                    type={ `submit` }
                                    isProcessing={ this.state.isProcessing }
                                    disabled={ props.invalid } 
                                />
                            </div>
                        )
                    }}
                />
            </div>
        )
    }
}

export default ProfileForm