import React, { Component } from 'react'
import { thousandSeparator } from 'Services/Helpers/Main'
import { NavLink } from 'react-router-dom'
import PersonalizeAddToCart from 'Components/Buttons/PersonalizeAddToCart'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class ActionButtons extends Component {
    getProductDetailLink = () => {
        let currentUrlParams = new URLSearchParams(this.props.location.search)
        currentUrlParams.delete('bundling')
        let url = this.props.location.pathname.replace('personalize', 'detail')
        if(currentUrlParams){
            url = `${ url }?${ currentUrlParams.toString() }`
        }

        return url
    }
    renderSuitCaseButton = () => {
        const { handler } = this.props
        const { toggleStep } = handler
        const url = this.getProductDetailLink()
        return(
            <div className="action-wrap separate-section">
                <button className="action invert" type="button" onClick={ () => toggleStep('tag') }>
                    <span>NEXT: personalise your tag</span>
                </button>
                <NavLink className="link-action text-center personalize-detail" to={ url }>
                    <span>return to product page</span>
                </NavLink>
            </div>
        )
    }

    getFinalCartData = () => {
        const { suitcase, tag, location, detailProduct, cartData } = this.props
        const query = new URLSearchParams(location.search)
        const size = query.get('s') ? parseInt(query.get('s')) : 1
        const color = query.get('c') ? parseInt(query.get('c')) : 1
        const { personalized } = detailProduct
        let selectedSize = []
        
        selectedSize = detailProduct.available_sizes.filter(el => {
            return parseInt(el.id) === parseInt(size)
        })
        
        // generate cart data
        let cartDataFinal = cartData
        let canEditPersonalize = false
        if(personalized.suitcase || personalized.tag){
            canEditPersonalize = true
        }
        
        const suitcasePersonalized = suitcase.letter.length > 0 ? suitcase : null 
        const tagPersoanlized = tag.letter.length > 0 ? tag : null
        selectedSize.forEach((el) => {
            let personalizeData = {
                suitcase: suitcasePersonalized,
                tag: tagPersoanlized
            }
            const selectedColor = el.available_colors.find(el => {
                return el.id === color
            })

            if(selectedColor){
                return cartDataFinal.push({
                    id: detailProduct.id,
                    name: detailProduct.name,
                    image: selectedColor.product_images ? selectedColor.product_images[0] : null,
                    sizeId: el.id,
                    sizeName: el.name,
                    quantity: 1,
                    price: el.price,
                    colorId: selectedColor.id,
                    colorName: selectedColor.name,
                    personalized: personalizeData,
                    canEditPersonalize: canEditPersonalize
                })
            }
            
            return false
        })

        return cartDataFinal
    }

    renderTagButtons = () => {
        const { defaultStep, handler, productPrice, tag, syncCartIndex, suitcase } = this.props
        const { toggleStep } = handler
        const url = this.getProductDetailLink()
        let hasSuitCaseStep = false
        
        if(defaultStep.includes('suitcase')){
            hasSuitCaseStep = true
        }

        return(
            <div className="action-wrap separate-section">
                <PersonalizeAddToCart 
                    className="action invert" 
                    getFinalCartData={ this.getFinalCartData }
                    syncCartIndex={ syncCartIndex }
                    syncPersonalized={
                        {
                            suitcase: suitcase,
                            tag: tag
                        }
                    }
                    { ...this.props }>
                    <span>
                        {
                            syncCartIndex && typeof syncCartIndex !== 'undefined' ? 
                            'Update Cart Item' :
                            `Add to Cart: Rp. ${ thousandSeparator(productPrice) }`
                        }
                    </span>
                </PersonalizeAddToCart>
                {
                    hasSuitCaseStep ? 
                    <button className="action" type="button" onClick={ () => toggleStep('suitcase') }>
                        <span>return to personalize suitcase</span>
                    </button> :
                    <NavLink className="link-action text-center personalize-detail" to={ url }>
                        <span>return to product page</span>
                    </NavLink>
                }
            </div>
        )
    }

    renderButtons = type => {
        if(type === 'suitcase'){
            return this.renderSuitCaseButton()
        }else if(type === 'tag'){
            return this.renderTagButtons()
        }
    }

    render() {
        const { currentStep } = this.props
        return (
            <div>
                { this.renderButtons(currentStep) }
            </div>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(ActionButtons) 