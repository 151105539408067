import React, { Component } from 'react'
import AccountTemplate from '../../Section/AccountTemplate'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import OrderDetailTemplate from './Section/OrderDetailTemplate'
import Loader from 'Components/Loader/Skeleton'

class OrderHistoryDetail extends Component {
    constructor(){
        super()
        this.state = {
            isLoading: true,
            detailData: null
        }
    }

    toggleLoading = flag => {
        this.setState({
            isLoading: flag ? true : false
        })
    }

    fetchDetailOrder = id => {
        this.toggleLoading(true)
        AccountAPI.getOrderDetail(id).then(res => {
            this.toggleLoading(false)
            if(res.status === 200){
                this.setState({
                    detailData: res.data.data
                })
            }
        })
    }

    showLoading = () => {
        return(
            <>
                <Loader count={ 1 } height={ 35 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
                <Loader count={ 3 } height={ 120 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
            </>
        )
    }

    componentDidMount(){
        const { match } = this.props
        const orderId = match?.params.orderId
        this.fetchDetailOrder(orderId)
    }

    render() {
        const { detailData, isLoading } = this.state
        const accountConfig = {
            backPathUrl: '/account/order-history',
            backPathLabel: 'Back to my orders'
        }
        
        return (
            <AccountTemplate config={ accountConfig} { ...this.props }>
                {
                    isLoading ? 
                    this.showLoading() :
                    <OrderDetailTemplate detailData={ detailData }/>
                }
            </AccountTemplate>
        )
    }
}

export default OrderHistoryDetail