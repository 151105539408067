import React from 'react'
import Main from './Main'

export const HelpPageConfig = {
    pageName: 'Help',
    routes: [   
        {
            path: '/help',
            authorizedOnly: false,
            Component: props => {
                return(<Main { ...props } />)
            }
        }
    ],
    constants: {
        faqData: [
            {
                headTitle: 'Product Information',
                body: [
                    {
                        title: 'Lorem Ipsum dolor sip amet? 1',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    },
                    {
                        title: 'Lorem Ipsum dolor sip amet? 2',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    },
                    {
                        title: 'Lorem Ipsum dolor sip amet? 3',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    }
                ]
            },
            {
                headTitle: 'Payment',
                body: [
                    {
                        title: 'Lorem Ipsum dolor sip amet? 1',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    },
                    {
                        title: 'Lorem Ipsum dolor sip amet? 2',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    },
                    {
                        title: 'Lorem Ipsum dolor sip amet? 3',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    }
                ]
            },
            {
                headTitle: 'Shipping and Delivery',
                body: [
                    {
                        title: 'Lorem Ipsum dolor sip amet? 1',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    },
                    {
                        title: 'Lorem Ipsum dolor sip amet? 2',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    },
                    {
                        title: 'Lorem Ipsum dolor sip amet? 3',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    }
                ]
            },
            {
                headTitle: 'Returns and warranty',
                body: [
                    {
                        title: 'Lorem Ipsum dolor sip amet? 1',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    },
                    {
                        title: 'Lorem Ipsum dolor sip amet? 2',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    },
                    {
                        title: 'Lorem Ipsum dolor sip amet? 3',
                        content: 'Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget. Lorem ipsum dolor sit ametTincidunt eget.'
                    }
                ]
            }
        ]
    },
}