import React, { Component } from 'react'
import './Core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Icon extends Component {
    render() {
        let { type, name } = this.props
        return <FontAwesomeIcon icon={[type ? type : 'fas', name]} />
    }
}

export default Icon