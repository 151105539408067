import React from 'react'
import AddToCart from './AddToCart'
import Personalize from './Personalize'

const index = (props) => {
    return (
        <div className="action-wrap separate-section">
            <Personalize { ...props }/>
            <AddToCart { ...props }/>
        </div>
    )
}

export default index