import React from 'react'
import { NavLink } from 'react-router-dom'
import SvgIcon from 'Components/Icons/Svg/Icon'
import Sticky from 'react-sticky-el'

const LeftMenu = () => {
    return (
        <div className="col-md-3 account-left-menu">
            <Sticky stickyClassName={ 'content-sticky' }>
                <NavLink to={ `/account/profile` }>
                    <span className="account-menu-icon"><SvgIcon name={ `menu-user` }/></span>
                    <span>My Account</span>
                </NavLink>
                <NavLink to={ `/account/address` }>
                    <span className="account-menu-icon"><SvgIcon name={ `menu-location` }/></span>
                    <span>My Address</span>
                </NavLink>
                <NavLink to={ `/account/order-history` }>
                    <span className="account-menu-icon"><SvgIcon name={ `menu-order` }/></span>
                    <span>My Orders</span>
                </NavLink>
                <NavLink to={ `/account/support` }>
                    <span className="account-menu-icon"><SvgIcon name={ `menu-support` }/></span>
                    <span>Support</span>
                </NavLink>
            </Sticky>
        </div>
    )
}

export default LeftMenu