import React, { Component } from 'react'
import { svgIcons } from './Core'

class Icon extends Component {
    render() {
        const { name } = this.props
        
        const targetIcon = svgIcons.filter(el => {
            return el.name === name
        })

        if(!targetIcon[0]){
            return null
        }
        
        return (
            <img src={ targetIcon[0].src } alt={ `icon-${ targetIcon[0].name }` } />
        )
    }
}

export default Icon