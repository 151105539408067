import React, { Component } from 'react'
import LoaderImage from './Loader'
import Image from './Image'

class ImageContainer extends Component {
    handleSetPreview = img => {
        this.setState({
            previewImage: img
        })
    }

    getGalleryImages = () => {
        const { detailData, selection } = this.props.detailConfig
        let sizeData = []
        if(detailData.available_sizes){
            detailData.available_sizes.forEach(element => {
                return sizeData.push({
                    id: element.id,
                    colors: element.available_colors
                })
            })
        }

        // filter the size
        let selectedSize = sizeData.find(el => {
            return el.id === selection.size
        })

        if(!selectedSize){
            selectedSize = sizeData[0]
        }

        // color image
        let selectedColor = selectedSize.colors.find(el => {
            return el.id === selection.color
        })
        if(!selectedColor){
            selectedColor = selectedSize.colors[0]
        }
        
        const finalImage = selectedColor?.product_images

        return finalImage
    }

    render() {
        const { isLoading } = this.props.detailConfig
        if(isLoading){
            return <LoaderImage />
        }

        const galleryImages = this.getGalleryImages()
        return (
            <div className="col-md-6 order-md-2 order-sm-1">
                <Image imageData={ galleryImages } { ...this.props }/>
            </div>
        )
    }
}

export default ImageContainer