import React from 'react'

const CheckBoxInput = (props) => {
    const { handler, touched, hasError, meta, errors } = props
    let classIndicator = touched && errors ? 'form-control error-input' : 'form-control'
    const label = meta.label
    let errClass = touched && hasError("required") ? 'has-error' : ''

    let extraClass = ''
    if(meta.extraClass){
        extraClass = meta.extraClass
    }
    return(
        <div className={ `form-group checkbox-wrapper ${ extraClass }` }>
            <label className="container-checkbox">
                { label.text }
                <input 
                    id={ meta.id }
                    className={ `checkbox-input ${ classIndicator } ${ errClass }` } 
                    type="checkbox" 
                    placeholder={ meta.placeHolder }
                    { ...handler() }
                />
                <span className="checkmark"></span>
            </label>
        </div>
    )
}

export default CheckBoxInput