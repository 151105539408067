import React, { Component } from 'react'
import AccountTemplate from '../../Section/AccountTemplate'
import ResponsiveTable from 'Components/Table/Responsive'
import OrderStatus from 'Components/Status/Order'
import Loader from 'Components/Loader/Skeleton'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import { getPaginationFromHeader } from 'Services/Helpers/Main'
import SimplePaginator from 'Components/Paginator/Simple'

class HistoryOrder extends Component {
    constructor(){
        super()
        this.state = {
            listConfig: {
                isLoading: true,
                data: [],
                page: 0,
                lastPage: 0,
                total: 0
            }
        }
    }

    handleClick = ({id}) => {
        this.props.history.push(`/account/order-history/${ id }`)
    }

    showLoading = () => {
        return(
            <Loader count={ 5 } height={ 35 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
        )
    }

    fetchList = (obj = null) => {
        this.setState({
            listConfig:{
                ...this.state.listConfig,
                isLoading: true
            }
        })
        const page = obj?.page ? obj.page : 1
        AccountAPI.getOrderHistoryList(page).then(res => {
            if(res.status === 200){
                const listData = res.data.data
                const header = res.headers
                const { page, lastPage, total } = getPaginationFromHeader(header)

                this.setState({
                    listConfig:{
                        ...this.state.listConfig,
                        data: listData,
                        page: page,
                        lastPage: lastPage,
                        total: total
                    }
                })
            }

            this.setState({
                listConfig:{
                    ...this.state.listConfig,
                    isLoading: false
                }
            })
        })
    }

    handleChangePage = (page) => {
        this.fetchList({page: page})
    }

    generatePaginator = () => {
        const { listConfig } = this.state
        const { page, lastPage, total } = listConfig
        const paginationObj = {
            page: page,
            lastPage: lastPage,
            total: total
        }

        return(<SimplePaginator config={ paginationObj } handleChangePage={ this.handleChangePage }/>)
    }

    componentDidMount(){
        this.fetchList()
    }

    render() {
        const { listConfig } = this.state
        const tableConfig = {
            columnsHeader: [
                {
                    header: 'Date',
                    accessor: 'created_at',
                    type: 'date'
                },
                {
                    header: 'Ship To',
                    accessor: 'ship_name',
                    Cell: row => {
                        return(
                            <>
                                <span className="ship-name">{ row.original.contact_name } </span>
                                <span className="order-no">#{ row.original.order_code } </span>
                            </>
                        )
                    }
                },
                {
                    header: 'Total Amount',
                    accessor: 'grand_total',
                    type: 'currency'
                },
                {
                    header: 'Status',
                    accessor: 'status',
                    Cell: row => {
                        return <OrderStatus value={ row.original.status }/>
                    }
                }
            ],
            onClickRow: this.handleClick
        }

        return (
            <AccountTemplate title="My Orders" { ...this.props }>
                <div className="section-account">
                    <h5 className="form-title">
                        <span>Order History</span>
                    </h5>
                    {
                        listConfig.isLoading ?
                        this.showLoading() :
                        <>
                            <div className="order-history-container">
                                <ResponsiveTable className="table-order-history" config={ tableConfig } data={ listConfig?.data }/>
                            </div>
                            { this.generatePaginator() }
                        </>
                    }
                </div>
            </AccountTemplate>
        )
    }
}

export default HistoryOrder