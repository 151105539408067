import React from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const AccordionComponent = ({data}) => {
    return (
        <div className={ `main-accordion` }>
            {
                data.map((el, index) => {
                    return(
                        <Accordion key={ index } classes={
                            {
                                root: 'main-accordion-base',
                                expanded: 'main-accordion-expanded'
                            }
                        }>
                            <AccordionSummary expandIcon={ <ExpandMoreIcon /> } classes={
                                {
                                    root: 'main-accordion-summary'
                                }
                            }>
                                <h5>{ el.title }</h5>
                            </AccordionSummary>
                            <AccordionDetails classes={
                                {
                                    root: 'main-accordion-details'
                                }
                            }>
                                <p>{ el.content }</p>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </div>
    )
}

export default AccordionComponent