import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'

class AboutScreen extends Component {
    render() {
        return (
            <PageTemplate { ...this.props }>
                <div className="main-banner">
                    <img src="/images/about/banner.jpg" alt="banner about" />
                    <div className="about-title">
                        <h3>Brand value here</h3>
                        <p>
                            Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. 
                            Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. 
                            Egestas diam in arcu cursus.
                        </p>
                    </div>
                </div>
                <div className="products-note wrapper-white">
                    <p className="text-center about-text">
                    The brand was established in 2018, Baller is the first brand of aluminium suitcase in Indonesia. 
                    Baller is designed by considering four essential elements of Premium-Quality suitcase. 
                    Baller is manufactured using fine materials and assembled with care. We would like to give assurance on your journey. 
                    An elegant travelling experience wherever you are.
                    </p>
                </div>
                <div className="products-note wrapper-white">
                    <div className="container-intro">
                        <div className="row">
                            <div className="col-md-6 order-md-2 order-sm-1">
                                <div className="card-info">
                                    <div className="head">
                                        <span className="subtitle">our story</span>
                                        <h1>Lorem Ipsum <br />dolor sit amet</h1>
                                    </div>
                                    <div className="body">
                                        <p className="notes">
                                        Lorem Ipsum dolor sip rorentum ronaldo amet Lorem Ipsum dolor sv rorentum ronaldo amet  ip amet Lorem Ipsum dolor sip amet Lorem Ipsum dolor sip amet.
                                        Lorem Ipsum dolor sip amet Lorem Ipsum dolor sip amet Lorem Ip rorentum ronaldo amet  sum dolor sip amet Lorem Ipsum dolor sip amet
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-1 order-sm-2">
                                <img className="intro-image" src="images/about/about1.jpg" alt="intro 1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="products-note wrapper-white">
                    <div className="container-intro">
                        <div className="row">
                            <div className="col-md-6 order-md-1 order-sm-2">
                                <div className="card-info">
                                    <div className="head">
                                        <span className="subtitle">our story</span>
                                        <h1>Lorem Ipsum <br />dolor sit amet</h1>
                                    </div>
                                    <div className="body">
                                        <p className="notes">
                                        Lorem Ipsum dolor sip rorentum ronaldo amet Lorem Ipsum dolor sv rorentum ronaldo amet  ip amet Lorem Ipsum dolor sip amet Lorem Ipsum dolor sip amet.
                                        Lorem Ipsum dolor sip amet Lorem Ipsum dolor sip amet Lorem Ip rorentum ronaldo amet  sum dolor sip amet Lorem Ipsum dolor sip amet
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 order-md-2 order-sm-1">
                                <img className="intro-image" src="images/about/about1.jpg" alt="intro 1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default AboutScreen