export const SUCCESS_SYNC_CART = 'SUCCESS_SYNC_CART'
export const TOGGLE_CART_SLIDER = 'TOGGLE_CART_SLIDER'

export const initialState = {
    cartData: [],
    openSliderCart: false
}

export const cartMapDispatchToProps = dispatch => {
    return {
        onSyncCart: (obj) => dispatch(
            {
                type: SUCCESS_SYNC_CART,
                items: obj
            }
        ),
        onToggleCartSlider: indicator => dispatch(
            {
                type: TOGGLE_CART_SLIDER,
                flag: indicator
            }
        )
    }
}

export const cartMapStateToProps = state => {
    return {
        cartData: state.CartReducer.cartData,
        openSliderCart: state.CartReducer.openSliderCart
    }
}