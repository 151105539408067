import React, { Component } from 'react'
import {
    FormBuilder,
    FieldGroup,
    FieldControl
} from 'react-reactive-form'
import { generateValidators } from './Validators'
import FormInput from './Inputs'

class DefaultForm extends Component {
    constructor(props){
        super(props)

        const { formInputs } = props
        let formContent = {}
        formInputs.forEach(el => {
            let inputProperties = [el.defaultValue]
            inputProperties.push(generateValidators(el.validator)) 
            return formContent[el.name] = inputProperties
        })

        this.state = {
            formData: FormBuilder.group(formContent)
        }
    }

    renderInputField = (input, index) => {
        return(
            <FieldControl 
                strict={ false }
                key={ index } 
                name={ input.name }
                render={ FormInput[input.type] }
                meta={ input.meta }
            />
        )
    }

    onSubmitForm = e => {
        e.preventDefault()
        const { formData } = this.state
        this.props.handleSubmit(formData)
        if(this.props.resetFormAfterSubmit){
            this.resetForm()
        }
    }

    resetForm = () => {
        this.state.formData.reset()
    }

    render() {
        const { className, actionComponent, formInputs } = this.props
        const { formData } = this.state
        const formClass = className ? `default-form ${ className }` : `default-form` 

        return (
            <FieldGroup
                control={ formData }
                strict={ false }
                render={
                    props => (
                        <form className={ formClass } onSubmit={ this.onSubmitForm }>
                            <div className="default-form-content">
                                {
                                    formInputs.map((input, index) => {
                                        return this.renderInputField(input, index)
                                    })
                                }
                            </div>
                            <div className="default-form-action">
                                { actionComponent(props) }
                            </div>
                        </form>
                    ) 
                } 
            />
        )
    }
}

export default DefaultForm