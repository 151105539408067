import React from 'react'
import Main from './Main'
import Detail from './Detail'

export const CatalogPageConfig = {
    pageName: 'Catalog',
    routes: [   
        {
            path: '/catalog',
            authorizedOnly: false,
            exact: true,
            Component: props => {
                return(<Main { ...props } />)
            }
        },
        {
            path: '/catalog/:type',
            authorizedOnly: false,
            exact: true,
            Component: props => {
                return(<Main { ...props } />)
            }
        },
        {
            path: '/catalog/detail/:id/:productName',
            authorizedOnly: false,
            exact: false,
            Component: props => {
                return(<Detail { ...props } />)
            }
        }
    ]
}