import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { getImageOrientation } from 'Services/Helpers/Main'

class ReviewLightBox extends Component {
    constructor(){
        super()
        this.state = {
            openPreview: false
        }
    }

    togglePreview = flag => {
        this.setState({
            openPreview: flag ? true : false
        })
    }

    render() {
        const { src } = this.props
        const { openPreview } = this.state
        if(!src){
            return null
        }
        const imageOrientation = getImageOrientation(src)
        return (
            <>
                <button className="review-thumbnail" onClick={ () => this.togglePreview(true) }>
                    <img src={ src } alt="img-review" className={ imageOrientation }/>
                </button>
                {
                    openPreview &&
                    <Lightbox 
                        mainSrc={ src }
                        onCloseRequest={ () => this.togglePreview(false) }
                        enableZoom={ false }
                    />
                }
            </>
        )
    }
}

export default ReviewLightBox