import React from 'react'
import Accordion from 'Components/Accordion'

const MaterialDesc = ({ desc, warranty }) => {

    let description = []
    if(desc){
        description.push({
            title: 'Details and Materials',
            content: desc
        })
    }

    if(warranty){
        description.push({
            title: 'Warranty',
            content: warranty
        })
    }

    return (
        <div className="more-desc">
            <Accordion data={ description }/>
        </div>
    )
}

export default MaterialDesc