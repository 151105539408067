import React from 'react'

const renderWarnMessage = (touched, hasError, label) => {
    let message = null

    if(touched){
        if(hasError('required')){
            message = `${ label.text } is required`
        }else if(hasError('email')){
            message = `${ label.text } wrong format`
        }
    }

    return message
}

const TextInput = (props) => {
    const { handler, touched, hasError, meta, errors } = props
    let classIndicator = touched && errors ? 'form-control error-input' : 'form-control'
    const label = meta.label
    let errClass = ''

    const errorMessage = renderWarnMessage(touched, hasError, label)
    if(errorMessage){
        errClass = 'has-error'
    }

    return(
        <div className="form-group">
            {
                label.show &&
                <label htmlFor={ label.text } className="placeholder"><b>{ label.text }</b></label>
            }
            <input 
                id={ label.text } 
                className={ `${ classIndicator } ${ errClass }` } 
                type="text" 
                placeholder={ meta.placeHolder } 
                autoFocus={ meta.autoFocus }
                readOnly={ meta.readOnly }
                { ...handler() }
                disabled={ meta.disabled }
            />
            <span className="warning-input">
                { errorMessage }
            </span>
        </div>
    )
}

export default TextInput