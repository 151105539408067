import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import MainBanner from './Containers/MainBanner'
import FAQ from './Containers/FAQ'
import SearchBar from './Containers/SearchBar'
import EmailSection from './Containers/EmailSection'
import { HelpPageConfig } from './config'

class HelpScreen extends Component {
    constructor(){
        super()
        this.state = {
            faqData: HelpPageConfig.constants.faqData,
            keyWord: ''
        }
    }

    handleChangeKeyWord = (el) => {
        this.setState({
            keyWord: el.currentTarget.value
        })

        if(el.currentTarget.value.length === 0){
            this.setState({
                faqData: HelpPageConfig.constants.faqData
            })
        }
    }

    onSubmitSearch = (e) => {
        e.preventDefault()

        let faqData = this.state.faqData
        const keyWord = this.state.keyWord
        if(keyWord){
            faqData = faqData.filter(el => {
                return el.headTitle.toLowerCase().includes(keyWord)
            })
        }else{
            faqData = HelpPageConfig.constants.faqData
        }

        this.setState({
            faqData: faqData
        })
    }

    render() {
        const { faqData, keyWord } = this.state
        const searchHandler = {
            value: keyWord,
            handler: this.handleChangeKeyWord,
            submit: this.onSubmitSearch
        }
        return (
            <PageTemplate { ...this.props }>
                <MainBanner />
                <SearchBar keyWord={ keyWord } searchHandler={ searchHandler }/>
                <FAQ faqData={ faqData }/>
                <EmailSection />
            </PageTemplate>
        )
    }
}

export default HelpScreen