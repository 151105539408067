import React from 'react'
import Main from './Main'

export const PersonalizePageConfig = {
    pageName: 'Personalize',
    routes: [   
        {
            path: '/catalog/personalize/:id/:productName',
            exact: true,
            authorizedOnly: false,
            Component: props => {
                return(<Main { ...props } />)
            }
        }
    ]
}