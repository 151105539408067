import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import Icon from 'Components/Icons/Svg/Icon'
import { NavLink } from 'react-router-dom'
import { getSocialAuthEndpoint } from 'Services/Helpers/Main'

class Register extends Component {
    handleClickSocial = (type) => {
        return window.location.replace(getSocialAuthEndpoint(type))
    }
    render() {
        return (
            <PageTemplate { ...this.props }>
                <div className="container">
                    <div className="auth-content">
                        <h3>Register</h3>
                        <NavLink to="/auth/register/email" className="button-link email">
                            <div>
                                <Icon name="email"></Icon>
                                <span>Sign up with email</span>
                            </div>
                        </NavLink>
                        <button type="button" className="google" onClick={ () => this.handleClickSocial('google') }>
                            <div>
                                <Icon name="google"></Icon>
                                <span>Sign up with google</span>
                            </div>
                        </button>
                        <button type="button" className="facebook" onClick={ () => this.handleClickSocial('facebook') }>
                            <div>
                                <Icon name="fb-square"></Icon>
                                <span>Sign up with facebook</span>
                            </div>
                        </button>
                        <NavLink to={ '/auth/sign-in' } className="link-outside has-border">
                            Already have an account?
                        </NavLink>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default Register 