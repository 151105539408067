import React from 'react'
import ResponsiveDialog from 'Components/Dialogs/Responsive'
import { thousandSeparator  } from 'Services/Helpers/Main'

const CompareDialog = (props) => {
    return (
        <ResponsiveDialog open={ props.open } handler={ props.handler } className="dialog-compare">
            <div className="compare-information">
                <div className="row">
                    <div className="col-md-4 wrap-product-compare">
                        <div className="compare-item">
                            <img src={ '/images/placeholder/compareimage.jpg' } alt={ 'img-compare' } />
                            <span className="title">{ 'The Continental 28' }</span>
                            <span className="price">Rp. { thousandSeparator(3000000) }</span>
                            <p>
                                Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. 
                                Mauris vitae ultricies leo integer malesuada. 
                                Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus.
                            </p>
                            <button className="btn-load-review action invert" type="button">
                                <span>Shop Now</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4 wrap-product-compare">
                        <div className="compare-item">
                            <img src={ '/images/placeholder/compareimage.jpg' } alt={ 'img-compare' } />
                            <span className="title">{ 'The Continental 28' }</span>
                            <span className="price">Rp. { thousandSeparator(3000000) }</span>
                            <p>
                                Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. 
                                Mauris vitae ultricies leo integer malesuada. 
                                Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus.
                            </p>
                            <button className="btn-load-review action invert" type="button">
                                <span>Shop Now</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4 wrap-product-compare">
                        <div className="compare-item">
                            <img src={ '/images/placeholder/compareimage.jpg' } alt={ 'img-compare' } />
                            <span className="title">{ 'The Continental 28' }</span>
                            <span className="price">Rp. { thousandSeparator(3000000) }</span>
                            <p>
                                Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. 
                                Mauris vitae ultricies leo integer malesuada. 
                                Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus.
                            </p>
                            <button className="btn-load-review action invert" type="button">
                                <span>Shop Now</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ResponsiveDialog>
    )
}

export default CompareDialog