import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import DefaultForm from 'Components/Form/Default'
import FormButton from 'Components/Form/Buttons/Default'
import { NavLink } from 'react-router-dom'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import { handleToast } from 'Services/Toastify'
import { connect } from 'react-redux'
import { authMapStateToProps, authMapDispatchToProps } from 'Constants/Redux/Auth'

class RegisterSocialForm extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false,
            loading: true,
            profileSocial: null
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    handleSubmit = (formData) => {
        const { token } = this.state
        AccountAPI.updateProfileManual(token, {
            name: formData.value.name,
            email: formData.value.email,
            phone_number: formData.phone_number
        }).then(res => {
            this.toggleProcess(false)
            if(res.status === 200){
                const data = formData.value
                const objLogin = {
                    name: data.name,
                    phone_number: data.phone_number,
                    email: data.email,
                    api_token: token
                }
                this.props.onSuccessLogin(objLogin)
                handleToast('Successfully login with Social Media', 'success')
            }else{
                handleToast('Oops, something wrong, please try again later.', 'warning')
            }
        })
    }

    componentDidMount(){
        const query = new URLSearchParams(this.props.location.search)
        const token = query.get('t')
        if(!token){
            this.props.history.push('/')
        }

        AccountAPI.getProfileManual(token).then(res => {
            if(res.status === 200){
                const data = res.data?.data
                this.setState({
                    loading: false
                })

                if(data){
                    this.setState({
                        profileSocial: data,
                        token: token
                    })
                }
            }
        })
    }

    render() {
        const { loading, profileSocial } = this.state
        return (
            <PageTemplate { ...this.props }>
                <div className="container">
                    {
                        !loading && profileSocial ?
                        <div className="auth-content register">
                            <h3>Register</h3>
                            <DefaultForm 
                                { ...this.props }
                                className={ `auth-form` }
                                isProcessing={ this.state.isProcessing }
                                handleSubmit={ this.handleSubmit }
                                formInputs={ 
                                    [
                                        {
                                            name: 'name',
                                            type: 'TextInput',
                                            defaultValue: profileSocial.name,
                                            validator: 'required',
                                            meta: {
                                                label: {
                                                    text: 'Name',
                                                    show: false
                                                },
                                                placeHolder: 'Name',
                                                autoFocus: true
                                            }
                                        },
                                        {
                                            name: 'email',
                                            type: 'TextInput',
                                            defaultValue: profileSocial.email,
                                            meta: {
                                                label: {
                                                    text: 'Email',
                                                    show: false
                                                },
                                                placeHolder: 'Email Address',
                                                readOnly: true
                                            }
                                        },
                                        {
                                            name: 'phone_number',
                                            type: 'TextInput',
                                            defaultValue: profileSocial.phone_number,
                                            validator: 'required',
                                            meta: {
                                                label: {
                                                    text: 'Phone Number',
                                                    show: false
                                                },
                                                placeHolder: 'Phone Number'
                                            }
                                        }
                                    ]
                                }
                                actionComponent={ props => {
                                    return(
                                        <div className="form-group form-action-d-flex mb-3">
                                            <FormButton 
                                                className="btn submit-btn"
                                                label={ `Register` }
                                                type={ `submit` }
                                                isProcessing={ this.state.isProcessing }
                                                disabled={ props.invalid } 
                                            />
                                        </div>
                                    )
                                }}
                            />
                            <NavLink to={ '/auth/sign-in' } className="link-outside has-border">
                                <span>Already have an account?</span>
                            </NavLink>
                        </div> : 
                        null
                    }
                </div>
            </PageTemplate>
        )
    }
}

export default connect(authMapStateToProps, authMapDispatchToProps)(RegisterSocialForm) 