import React from 'react'
import { stringToCurrency } from 'Services/Helpers/Main'

const OrderSummary = (props) => {
    const { detailData } = props
    return (
        <>
            <div className="summary">
                <div className="row">
                    <div className="label">subtotal</div>
                    <div className="value">{ stringToCurrency(detailData.sub_total) }</div>
                </div>
                <div className="row">
                    <div className="label">bundling discount</div>
                    <div className="value">{ stringToCurrency(detailData.discount) }</div>
                </div>
                <div className="row">
                    <div className="label">shipping</div>
                    <div className="value">{ stringToCurrency(detailData.delivery_fee) }</div>
                </div>
            </div>
            <div className="grand-total">
                <div className="row">
                    <div className="label">total amount</div>
                    <div className="value">{ stringToCurrency(detailData.grand_total) }</div>
                </div>
            </div>
        </>
    )
}

export default OrderSummary