import React, { Component } from 'react'
import Loader from 'Components/Loader/Skeleton'
import { getPaginationFromHeader } from 'Services/Helpers/Main'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import SimplePaginator from 'Components/Paginator/Simple'

class AddressList extends Component {
    constructor(){
        super()
        this.state = {
            listAddressConfig: {
                isLoading: true,
                data: [],
                page: 0,
                lastPage: 0,
                total: 0
            }
        }
    }

    showLoading = () => {
        return(
            <>
                <Loader count={ 1 } height={ 35 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
                <Loader count={ 1 } height={ 120 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
            </>
        )
    }

    handleChangePage = (page) => {
        this.fetchList({page: page})
    }
    
    generatePaginator = () => {
        const { listAddressConfig } = this.state
        const { page, lastPage, total } = listAddressConfig
        const paginationObj = {
            page: page,
            lastPage: lastPage,
            total: total
        }

        return(<SimplePaginator config={ paginationObj } handleChangePage={ this.handleChangePage }/>)
    }

    getFullAddress = (el) => {
        let address = el.address
        if(el.province_name){
            address = `${ address }, ${ el.province_name }`
        }
        if(el.city_name){
            address = `${ address }, ${ el.city_name }`
        }

        if(el.zip_code){
            address = `${ address }, ${ el.zip_code }`
        }

        return address
    }

    fetchList = (obj = null) => {
        this.setState({
            listAddressConfig:{
                ...this.state.listAddressConfig,
                isLoading: true
            }
        })
        const page = obj?.page ? obj.page : 1
        AccountAPI.getAddressList(page).then(res => {
            if(res.status === 200){
                const listData = res.data.data
                const header = res.headers
                const { page, lastPage, total } = getPaginationFromHeader(header)

                this.setState({
                    listAddressConfig:{
                        ...this.state.listAddressConfig,
                        data: listData,
                        page: page,
                        lastPage: lastPage,
                        total: total
                    }
                })
            }

            this.setState({
                listAddressConfig:{
                    ...this.state.listAddressConfig,
                    isLoading: false
                }
            })
        })
    }

    handleSelectAddress = (obj) => {
        this.props.setSelectedAddress(obj)
        this.props.toggleopenDialog(false)

        return true
    }

    renderAddressRow = (data) => {
        const { selectedAddress } = this.props
        if(!data || data.length <= 0){
            return(
                <div className="address-wrap">
                    <div className="col-md-12 text-center">
                        <span>No Data</span>
                    </div>
                </div>
            )
        }

        let addressRow = []
        
        data.map((el, index) => {
            const fullAddress = this.getFullAddress(el)
            return addressRow.push(
                <div className={ `address-wrap ${ el.is_primary ? 'primary-address' : '' }` }  key={ index }>
                    <span className="bolder">Receiver : { el.receiver_name ? el.receiver_name : '-' }</span>
                    <span className="bolder">{ el.receiver_number }</span>
                    <span>{ fullAddress }</span>
                    <span className="remarks">Remarks: { el.remarks }</span>
                    <div className="wrap-buttons">
                        <button className="button-link" onClick={ () => this.props.toggleOpenFormEdit(el.id) }>edit</button>
                        {
                            selectedAddress && selectedAddress.id === el.id ?
                            <div className="current-info-text font-weight-bold">selected address</div> :
                            <button className="button-link" onClick={ () => this.handleSelectAddress(el) }>select</button> 
                        }
                    </div>
                </div>
            )
        })

        return addressRow
    }

    componentDidMount(){
        this.fetchList()
    }

    render() {
        const { selectedAddress } = this.props
        const { listAddressConfig } = this.state
        const { isLoading, data } = listAddressConfig
        
        if(!selectedAddress){
            return(
                <div className="checkout-section-form"></div>
            )
        }
        return (
            <div className="checkout-section-form">
                <h5 className="form-title">Change Address</h5>
                <button className="add-address-checkout" type="button" onClick={ () => this.props.toggleOpenForm(true) }>
                    add new address
                </button>
                
                <div className="address-list">
                    {
                        isLoading ? 
                        this.showLoading() :
                        this.renderAddressRow(data)
                    }
                </div>
                { this.generatePaginator() }
            </div>
        )
    }
}

export default AddressList