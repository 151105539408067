import React, { Component } from 'react'
import Icon from 'Components/Icons/FontAwesome/Icon'

class Rating extends Component {

    renderLoveRate = (obj) => {
        const { black, white } = obj
        let blackHeartComponent = []
        for(let i = 0; i < black; i++){
            blackHeartComponent.push(<Icon name="heart" key={ i }/>)
        }

        let whiteHeartComponent = []
        for(let i = 0; i < white; i++){
            whiteHeartComponent.push(<Icon type="far" name="heart" key={ i }/>)
        }

        return(
            <>
                { blackHeartComponent }
                { whiteHeartComponent }
            </>
        )
    }
    render() {
        let defaultBlack = 0
        let defaultWhite = 5
        if(this.props.rate > 0 && this.props.rate <= 5){
            defaultBlack = this.props.rate
            defaultWhite = defaultWhite - defaultBlack 
        }

        const rate = {
            black: defaultBlack,
            white: defaultWhite
        }
        
        return (
            <div className="review-rate">
                { this.renderLoveRate(rate) }
                <span>{ defaultBlack }/5</span>
            </div>
        )
    }
}

export default Rating