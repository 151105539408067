import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class PersonalizeAddToCart extends Component {
    onClick = () => {
        // sync cart
        const { history, onSyncCart, onToggleCartSlider, getFinalCartData, syncCartIndex, syncPersonalized, cartData } = this.props
        if(syncCartIndex && typeof syncCartIndex !== 'undefined'){
            let updatedCartData = cartData
            if(updatedCartData[syncCartIndex] && syncPersonalized){
                updatedCartData[syncCartIndex].personalized = syncPersonalized
                onSyncCart(updatedCartData)
            }
        }else{
            // open cart slider & redirect to catalog on sync
            const cartObj = getFinalCartData()
            onSyncCart(cartObj)
        }
        history.push('/catalog')
        onToggleCartSlider(true)
        return false
    }

    render() {
        const { children, className } = this.props
        return (
            <button className={ className } type="button" onClick={ this.onClick }>
                { children }
            </button>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(PersonalizeAddToCart) 