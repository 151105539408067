import React from 'react';
import FormButton from 'Components/Form/Buttons/Default'

const DynamicFormButtons = (props) => {
    return (
        <div className="form-group form-action-d-flex mb-3">
            <FormButton 
                className="action invert"
                label={ props.confirmConfig.label }
                type={ `submit` }
                isProcessing={ props.processing }
                disabled={ props.invalid }
            />
            {
                props.cancelConfig &&
                <FormButton 
                    className='action mt-20'
                    label={ props.cancelConfig.label }
                    type={ 'button' }
                    disabled={ props.processing } 
                    onClick={ props.cancelConfig.onClick }
                />
            }
        </div>
    );
};

export default DynamicFormButtons;