import React, { Component } from 'react'
import Loader from 'Components/Loader/Skeleton'

class OrderDetailImage extends Component {
    constructor(){
        super()
        this.state = {
            imageLoaded: false,
            imageError: false
        }
    }

    handleImageLoaded = () => {
        this.setState({
            imageLoaded: true,
            imageError: false
        })
    }

    handleImageError = () => {
        this.setState({
            imageLoaded: true,
            imageError: true
        })
    }

    render() {
        const { src } = this.props
        const { imageLoaded, imageError } = this.state

        if(imageLoaded && imageError){
            return(<img className="image-order-detail" src="/images/placeholder/no-image.png" alt="product-detail-thumb"/>)
        }

        return (
            <>
                { !imageLoaded && <Loader width={ 150 } height={ 150 }/> }
                <img 
                    className={ `image-order-detail ${ !imageLoaded ? 'hide-content' : '' }` }
                    src={ src } 
                    alt="product detail thumbnail" 
                    onLoad={ this.handleImageLoaded }
                    onError={ this.handleImageError }
                />
            </>
        )
    }
}

export default OrderDetailImage