import axios from 'axios'
import GuestConfig from '../GuestConfig'

class CatalogAPI {
    static get = (params) => {
        let url = '/categories/products'
        if(params.category_id){
            url = url + '?category_id=' + params.category_id
        }
        return axios.get( url , GuestConfig )
    }

    static detail = (id) => {
        return axios.get( `/products/${ id }`, GuestConfig )
    }

    static getReviews = (id, page) => {
        let pageProp = ''
        if(page){
            pageProp = `?page=${ page }`
        }
        return axios.get( `/products/${ id }/reviews${ pageProp }`, GuestConfig )
    }
    static getBundlingPromo = (productId, sizeId, colorId) => {
        return axios.post( `/bundling_promotions/check`, { 
            product_id: productId,
            size_id: sizeId,
            color_id: colorId
        }, GuestConfig )
    }
    static claimBundlingPromo = (itemsObj) => {
        return axios.post( `/bundling_promotions/claim`, itemsObj, GuestConfig )
    } 
}

export default CatalogAPI