export const SUCCESS_LOGIN = 'SUCCESS_LOGIN'
export const SUCCESS_UPDATE_USER = 'SUCCESS_UPDATE_USER'
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT'

export const initialState = {
    currentUser: null,
}

export const authMapDispatchToProps = dispatch => {
    return {
        onSuccessLogin: (authUser) => dispatch(
            {
                type: SUCCESS_LOGIN,
                currentUser: authUser
            }
        ),
        onSuccessUpdateUser: (authUserData) => dispatch(
            {
                type: SUCCESS_UPDATE_USER,
                currentUser: authUserData
            }
        ),
        onSuccessLogout: () => dispatch(
            {
                type: SUCCESS_LOGOUT,
                currentUser: null
            }
        )
    }
}

export const authMapStateToProps = state => {
    return {
        currentUser: state.AuthReducer.currentUser
    }
}