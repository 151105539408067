import React from 'react'
import { NavLink } from 'react-router-dom'

const QuickLink = () => {
    return (
        <div className="product-nav-quick wrapper-white">
            <div className="row">
                <div className="col-md-12 title">
                    <h5>Shop The</h5>
                    <h2>Latest Style</h2>
                </div>
            </div>
            <div className="row image-links justify-content-center">
                <div className="col-md-4 col-link">
                    <NavLink to ={ `/catalog/suitcases` }>
                        <img src="/images/home/quick/luggage-nav.jpg" alt="nav-suitcases-quick" />
                        <span>suitcases</span>
                    </NavLink>
                </div>
                <div className="col-md-4 col-link">
                    <NavLink to ={ `/catalog/luggage-tags` }>
                        <img src="/images/home/quick/tags-nav.jpg" alt="nav-tags-quick" />
                        <span>Engraved tags</span>
                    </NavLink>
                </div>
                <div className="col-md-4 col-link">
                    <NavLink to ={ `/catalog/stickers` }>
                        <img src="/images/home/quick/stickers-nav.jpg" alt="nav-stickers-quick" />
                        <span>stickers</span>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default QuickLink