import React, { Component } from 'react'
import Icon from 'Components/Icons/FontAwesome/Icon'
import { NavLink } from 'react-router-dom'
import BlogMenu from './BlogMenu'
import BlogAPI from 'Services/API/Guest/BlogAPI'

class Menu extends Component {
    constructor(){
        super()
        this.state = {
            blogData: []
        }
    }
    componentDidMount(){
        BlogAPI.get().then(res => {
            if(res?.data?.data){
                this.setState({
                    blogData: res.data.data
                })
            }
        })
    }
    render() {
        const { blogData } = this.state

        return (
            <ul id="master-menu">
                <li className="has-hover">
                    <span className="label-menu">Shop</span>
                    <span className="dropdown"><Icon name="chevron-down"/></span>
                    <div className="shop-hover plain">
                        <ul className="explore-list">
                            <li><NavLink to={ '/catalog/suitcases' }>Suitcases</NavLink></li>
                            <li><NavLink to={ '/catalog/luggage-tags' }>Luggage Tags</NavLink></li>
                            <li><NavLink to={ '/catalog/stickers' }>Stickers</NavLink></li>
                            <li className="separator-top"><NavLink to={ '/catalog' }>Shop All</NavLink></li>
                        </ul>
                    </div>
                </li>
                <li className="has-hover">
                    <span className="label-menu">Explore</span>
                    <span className="dropdown"><Icon name="chevron-down"/></span>
                    <div className="shop-hover plain">
                        <ul className="explore-list">
                            <li><NavLink to={ `/about` }>About Us</NavLink></li>
                        </ul>
                    </div>
                </li>
                <li className="has-hover">
                    <span className="label-menu">Blog</span>
                    <span className="dropdown"><Icon name="chevron-down"/></span>
                    <div className="shop-hover plain">
                        <BlogMenu blogData={ blogData }/>
                    </div>
                </li>
                <li><NavLink to={ '/help' }>Help</NavLink></li>
            </ul>
        )
    }
}

export default Menu