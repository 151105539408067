import React, { Component } from 'react'
import Icon from 'Components/Icons/FontAwesome/Icon'

class SimplePaginator extends Component {

    onPageChange = (e, page) => {
        e.preventDefault()
        this.props.handleChangePage(page)
    }

    render() {
        const { page, lastPage, total } = this.props.config
        let disabledPrev = false
        let disabledNext = false

        if(parseInt(page) === 0 || parseInt(lastPage) === 1 || parseInt(total) === 0){
            return null
        }

        if(page === 1){
            disabledPrev = true
        }

        if(page === lastPage){
            disabledNext = true
        }
        return (
            <div className="pagination-wrapper">
                <button disabled={ disabledPrev } onClick={ (e) => this.onPageChange(e, page - 1) }><Icon name="chevron-left" /></button>
                <button disabled={ disabledNext } onClick={ (e) => this.onPageChange(e, page + 1) }><Icon name="chevron-right" /></button>
            </div>
        )
    }
}

export default SimplePaginator