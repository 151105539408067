import React from 'react'
import Loader from 'Components/Loader/Skeleton'

const LoaderPersonalize = () => {
    return (
        <div className="wrapper-white">
            <div className="container personalize-area">
                <div className="row">
                    <div className="col-xl-6 col-md-12 personalize-product order-xl-2 order-md-1 order-sm-1">
                        <Loader count={ 1 } height={ 350 } style={ {marginTop: '20px'} }/>
                    </div>
                    <div className="col-xl-6 col-md-12 personalize-input order-xl-1 order-md-2 order-sm-2">
                        <Loader count={ 9 } height={ 25 } style={ {marginTop: '20px'} }/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoaderPersonalize