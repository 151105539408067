import React, { Component } from 'react'
import ColorPicker from './ColorPicker'
import LoaderInfo from './Loader'
import MaterialDesc from './MaterialDesc'
import BundlingOptions from './BundlingOptions'
import CompareDialog from './Dialog/CompareDialog'
import ActionButton from '../ActionButton'
import ReviewRate from './ReviewRate'

class Info extends Component {
    constructor(props){
        super(props)
        this.state = {
            viewCompareSizeOpen: false,
        }
    }

    handleClickButton = (flag = false) => {
        this.setState({
            viewCompareSizeOpen: flag ? true : false
        })
    }

    handleSizeChange = (e) => {
        const { location, detailConfig } = this.props
        const { setSelection, detailData } = detailConfig
        let params = {
            s: e.target.value
        }

        let  defaultColor = null
        const selectedSize = detailData.available_sizes.find(el => {
            return parseInt(el.id) === parseInt(e.target.value)
        })

        if(selectedSize){
            const colors = selectedSize.available_colors
            defaultColor = colors[0]?.id
        }

        if(defaultColor){
            params = {
                ...params,
                c: parseInt(defaultColor)
            }
        }

        const qString = new URLSearchParams(params).toString()
        setSelection({
            color: parseInt(params.c),
            size: parseInt(params.s)
        })
        this.props.history.push({
            pathname: location.pathname,
            search: `?${ qString }`
        })
    }

    handleColorChange = id => {
        const { location, detailConfig } = this.props
        const { selection, setSelection } = detailConfig
        const params = {
            c: id,
            s: selection.size
        }

        const qString = new URLSearchParams(params).toString()
        setSelection({
            color: parseInt(params.c),
            size: parseInt(params.s)
        })
        this.props.history.push({
            pathname: location.pathname,
            search: `?${ qString }`
        })
    }

    renderSizeOptions = () => {
        const { detailConfig } = this.props
        const { detailData, selection } = detailConfig

        let sizes = []
        if(detailData.have_sizes){
            sizes = detailData.available_sizes
        }else{
            return null
        }

        return(
            <div className="wrap-select">
                <select value={ selection?.size ? selection.size : '' } onChange={ this.handleSizeChange }>
                    {
                        sizes.map((el, index) => <option value={ el.id } key={ index } >{ el.name }</option>)
                    }
                </select>
            </div>
        )
    }

    render() {
        const { detailConfig, location } = this.props
        const { isLoading, detailData } = detailConfig
        const { viewCompareSizeOpen } = this.state
        const query = new URLSearchParams(location.search)
        const sizeId = query.get('s')
        
        let descriptionText = ''
        let warrantyText = ''
        
        if(sizeId){
            const targetSize = detailData && detailData.available_sizes.find(el => {
                return el.id === parseInt(sizeId)
            })
    
            if(targetSize){
                descriptionText = targetSize.description
                warrantyText = targetSize.warranty
            }
        }

        if(isLoading){
            return <LoaderInfo />
        }

        let displayCompareSizeDialogButton = false
        if(detailData?.personalized?.suitcase === true){
            displayCompareSizeDialogButton = true
        }

        return (
            <div className="col-md-6 order-md-1 order-sm-2 information-detail separate-section ">
                <ReviewRate { ...this.props }/>
                <h2 className="title">
                    <span>{ detailData.name }</span>
                    { this.renderSizeOptions() }
                </h2>
                {
                    displayCompareSizeDialogButton && 
                    <button className="button-link compare-button" onClick={ () => this.handleClickButton(true) }>ⓘ Compare Size</button>
                }
                <p className="description">{ descriptionText }</p>
                <ColorPicker { ...this.props } handleColorChange={ this.handleColorChange }/>
                <MaterialDesc desc={ descriptionText } warranty={ warrantyText }/>
                <BundlingOptions { ...this.props }/>
                <ActionButton { ...this.props }/>
                <CompareDialog open={ viewCompareSizeOpen } handler={ this.handleClickButton } { ...this.props }/>
            </div>
        )
    }
}

export default Info