import React, { Component } from 'react'
import Rating from './Rating'
import { dateObjToString } from 'Services/Helpers/Main'
import ReviewLightBox from 'Components/LightBox/Review'

class Reviews extends Component {

    loadMore = () => {
        const { detailConfig } = this.props

        return detailConfig.loadMoreReview()
    }

    renderButtonLoadMore = () => {
        const { detailConfig } = this.props
        const { reviews } = detailConfig
        const { isLoadingReview } = reviews

        if(isLoadingReview){
            return null
        }

        return(
            <div className="load-more">
                <button className="btn-load-review action invert" type="button" onClick={ this.loadMore }>
                    <span>load more reviews</span>
                </button>
            </div>
        )
    }

    renderReviewImage = (src) => {
        if(src){
            return(
                <div className="review-image">
                    <ReviewLightBox src={ src }/>
                </div>
            )
        }
        
        return null
    }

    render() {
        const { detailConfig } = this.props
        const { reviews, detailData } = detailConfig
        if(!reviews){
            return null
        }

        const { page, lastPage } = reviews
        
        return (
            <div className="user-reviews">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-review">
                                { detailData?.name && <h5>{ detailData?.name }</h5> }
                                <h3>Reviews</h3>
                            </div>
                        </div>
                    </div>
                    {
                        reviews?.data.length <= 0 &&
                        <div className="row review-list text-center">
                            No Reviews yet
                        </div>
                    }
                    {
                        reviews?.data.map((el, index) => {
                            return(
                                <div className="row review-list" key={ index }>
                                    <div className="user-info">
                                        <span><img src="/images/icon/review/user.svg" alt="user-icon"/></span>
                                        <span className="username">{ el.contact_name }</span>
                                        <span className="date">{ dateObjToString(el.created_at) }</span>
                                    </div>
                                   <Rating rate={ el.rating }/>
                                    <div className="review-text">
                                        { el.content }
                                    </div>
                                    {
                                        this.renderReviewImage(el.review_image_url)
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        page < lastPage ? 
                        this.renderButtonLoadMore() :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default Reviews