import React, { Component } from 'react'
import SlidingPane from 'react-sliding-pane'
import Icon from 'Components/Icons/FontAwesome/Icon'
import CartItem from './Product/CartItem'
import PriceInfo from './Product/PriceInfo'
import CatalogApi from 'Services/API/Guest/CatalogAPI'
import Skeleton from 'react-loading-skeleton'

class CartSlider extends Component {
    constructor(){
        super()
        this.state = {
            cartItems: null,
            loading: true,
            bundlingDiscount: 0
        }
    }
    closeCartSlider = () => {
        const { cartPlugin } = this.props
        cartPlugin.toggleCart(false)
    }

    onHandleCheckout = () => {
        this.closeCartSlider()
        this.props.history.push('/checkout/shipment')    
    }

    syncCartItemsView = (items) => {
        this.setState({
            cartItems: items
        })

        this.refreshDiscount(items)
    }

    componentDidMount(){
        const { cartPlugin } = this.props
        this.setState({
            cartItems: cartPlugin?.cartInfo?.items ? cartPlugin.cartInfo.items : []
        })
    }

    toggleLoading = flag => {
        this.setState({
            loading: flag
        })
    }

    refreshDiscount = (cartItems) => {
        if(cartItems && cartItems.length > 0){
            this.toggleLoading(true)

            // get discount data by cart items
            const bundlingDiscountItemCheck = cartItems.map(el => {
                return {
                    product_id: el.id,
                    size_id: el.sizeId
                }
            })
            CatalogApi.claimBundlingPromo({
                items: bundlingDiscountItemCheck
            }).then(res => {
                if(res.data.data){
                    this.setState({
                        bundlingDiscount: res.data.data
                    })
                }else{
                    this.setState({
                        bundlingDiscount: 0
                    })
                }
                this.toggleLoading(false)
            })
        }else{
            this.toggleLoading(false)
        }
    }

    onOpenSlider = () => {
        const cartItems = this.state.cartItems
        this.refreshDiscount(cartItems)
    }

    render() {
        const { history, location } = this.props
        const { cartPlugin } = this.props
        const { cartItems, loading, bundlingDiscount } = this.state
        const cartQty = cartItems ? cartItems.length : 0

        return (
            <SlidingPane
                className="slide-cart"
                from="right"
                overlayClassName="slide-cart-overlay"
                isOpen={ cartPlugin?.open }
                hideHeader={ true }
                width={ '30%' }
                onRequestClose={ this.closeCartSlider }
                onAfterOpen={ this.onOpenSlider }
            >
                <div className="content-wrapper" style={{height:window.innerHeight}}>
                    <div className="head">
                        <div className="title-info">
                            <h2>
                                <button className="close-cart-mobile" type="button" onClick={ this.closeCartSlider }>
                                    <Icon name="angle-left" />
                                </button>
                                <span>Your Cart</span>
                            </h2>
                            <button className="cart-icon" type="button">
                                <img src="/images/icon/icon-luggage.svg" alt="cart"/>
                                <span className={ `cart-counter` }>{ cartQty ? cartQty : 0 }</span>
                            </button>
                        </div>
                    </div>
                    {
                        !loading ?
                        <>
                            <div className="body">
                                <div className="item-content">
                                    {
                                        cartItems !== null && cartItems.map((item, index) => {
                                            return(
                                                <CartItem 
                                                    key={ index }
                                                    item={ item } 
                                                    cartIndex={ index } 
                                                    history={ history }
                                                    location={ location }
                                                    closeCartSlider={ this.closeCartSlider }
                                                    syncCartItemsView={ this.syncCartItemsView }
                                                    refreshDiscount={ this.refreshDiscount }
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="summary">
                                <PriceInfo items={ cartItems } bundlingDiscount={ bundlingDiscount }/>
                                <div className="cart-action">
                                    <button 
                                        className="cart continue-shopping" 
                                        type="button" 
                                        onClick={ this.closeCartSlider }
                                    >
                                        continue shopping
                                    </button>
                                    <button 
                                        className="cart checkout" 
                                        type="button" 
                                        onClick={ this.onHandleCheckout }
                                        disabled={ !cartItems || cartItems.length <= 0 ? true : false }
                                    >
                                        checkout
                                    </button>
                                </div>
                            </div>
                        </> :
                        <div className="body" style={{textAlign: 'center'}}>
                            <Skeleton circle={ true } height={ 50 } width={ 50 }/>
                        </div>
                    }
                </div>
                <button className="close-cart" type="button" onClick={ this.closeCartSlider }>
                    <Icon name="angle-double-right" />
                </button>
            </SlidingPane>
        )
    }
}

export default CartSlider