import React, { Component } from 'react'
import Loader from 'Components/Loader/Skeleton'

class CatalogThumb extends Component {
    constructor(){
        super()
        this.state = {
            imageLoaded: false,
            imageError: false
        }
    }

    handleImageLoaded = () => {
        this.setState({
            imageLoaded: true,
            imageError: false
        })
    }

    handleImageError = () => {
        this.setState({
            imageLoaded: true,
            imageError: true
        })
    }

    render() {
        const { src } = this.props
        const { imageLoaded, imageError } = this.state

        if(imageLoaded && imageError){
            return null
        }

        return (
            <>
                { !imageLoaded && <Loader width={ 362 } height={ 551 }/> }
                <img 
                    className={ `wrap-product-image ${ !imageLoaded ? 'hide-content' : 'animate__animated animate__fadeIn' }` }
                    src={ src } 
                    alt="product-catalog-thumb" 
                    onLoad={ this.handleImageLoaded }
                    onError={ this.handleImageError }
                />
            </>
        )
    }
}

export default CatalogThumb