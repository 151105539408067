import React, { Component } from 'react'
import Step from './Step'
import ActionButtons from './ActionButtons'
import { getTagProductId } from 'Services/Helpers/Main'
import CatalogAPI from 'Services/API/Guest/CatalogAPI'

class PersonalizeTag extends Component {
    constructor(){
        super()
        this.state = {
            loadingTag: true,
            tagData: null
        }
    }

    handleBack = (e) => {
        e.preventDefault()
        return this.props.toggleStep('suitcase')
    }

    toggleLoading = flag => {
        this.setState({
            loadingTag: flag ? true : false
        })
    }

    fetchDetail = (tagProdId) => {
        this.toggleLoading(true)
        CatalogAPI.detail(tagProdId).then(res => {
            if(res.status === 200){
                const detailData = res.data.data
                let sizeData = detailData.available_sizes[0]
                this.setState({
                    tagData: sizeData
                })
                const tagColors = sizeData && sizeData.available_colors.map(el => {
                    return {
                        id: el.id,
                        name: el.name,
                        hexcode: el.hexcode
                    }
                })

                this.props.handler.updateTagColorSet(tagColors, sizeData.id)

            }

            this.toggleLoading(false)
        })
    }
    componentDidMount(){
        const tagProdId = getTagProductId()
        this.fetchDetail(tagProdId)
    }

    render() {
        const { loadingTag } = this.state
        const { tag, handler, defaultData } = this.props
        const { letter, textColor, tagColor } = tag

        const { tagTextColor, tagColors } = defaultData

        const { 
            handleChangeLetterTag, 
            handleChangeTextColorTag,
            handleChangeTagColor 
        } = handler

        if(loadingTag){
            return null
        }


        const selectedTagColorName = tagColors && tagColors.find(el => {
            return el.id === tagColor
        })

        return (
            <div className="wrapper-white">
                <div className="container personalize-area">
                    <div className="row">
                        <div className="col-xl-6 col-md-12 personalize-product order-xl-2 order-md-1 order-sm-1">
                            <div className="personalize-image tag">
                                <img src="/images/placeholder/personalize/tag.jpg" alt="personalize-suitcase"/>
                                <span className={ `text-letter-tag ${ textColor }` }>{ letter }</span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12 personalize-input order-xl-1 order-md-2 order-sm-2">
                            <Step { ...this.props }/>
                            <h1>Personalize your tag</h1>
                            <span className="message">Engrave your name, initials, or lucky number</span>
                            <div className="input-area">
                                <span className="letter-info">letters</span>
                                <input type="text" value={ letter } placeholder="BLR" maxLength="3" onChange={ handleChangeLetterTag }/>
                                <span className="text-guide">Enter up to three letters or numbers</span>
                            </div>
                            <div className="option-switch">
                                <p className="guide">choose text color: <strong>{ textColor }</strong></p>
                                <div className="color-buttons">
                                    {
                                        tagTextColor?.map((el, index) => {
                                            return(
                                                <button 
                                                    className={ `${ el } ${ textColor === el ? 'selected' : '' }` } 
                                                    key={ index }
                                                    onClick={ () => handleChangeTextColorTag(el) }
                                                >
                                                    <span>{ el.toUpperCase() }</span>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="option-switch">
                                <p className="guide">choose tag color: <strong>{ selectedTagColorName && selectedTagColorName.name }</strong></p>
                                <div className="color-picker text-left">
                                    <div className="color-circles">
                                        {
                                            tagColors?.map((el, index) => {
                                                return(
                                                    <button 
                                                        key={ index } 
                                                        className={ `color-button ${ tagColor === el.id ? 'selected' : '' }` }  
                                                        type="button" 
                                                        onClick={ () => handleChangeTagColor(el) }
                                                    >
                                                        <span className="color" style={ {
                                                            backgroundColor: el.hexcode
                                                        } } title={ el.name }/>
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <ActionButtons { ...this.props }/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PersonalizeTag