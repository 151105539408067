export const svgIcons = [
    {
        name: 'ig',
        src: '/images/icon/social/ig.svg'
    },
    {
        name: 'email',
        src: '/images/icon/social/email.svg'
    },
    {
        name: 'pinterest',
        src: '/images/icon/social/pinterest.svg'
    },
    {
        name: 'whatsapp',
        src: '/images/icon/social/whatsapp.svg'
    },
    {
        name: 'fb',
        src: '/images/icon/social/fb.svg'
    },
    {
        name: 'fb-square',
        src: '/images/icon/social/fb-square.svg'
    },
    {
        name: 'google',
        src: '/images/icon/social/google.svg'
    },
    {
        name: 'menu-user',
        src: '/images/icon/account/user.svg'
    },
    {
        name: 'menu-user',
        src: '/images/icon/account/user.svg'
    },
    {
        name: 'menu-user-invert',
        src: '/images/icon/account/user-invert.svg'
    },
    {
        name: 'menu-location',
        src: '/images/icon/account/location.svg'
    },
    {
        name: 'menu-location-invert',
        src: '/images/icon/account/location-invert.svg'
    },
    {
        name: 'menu-order',
        src: '/images/icon/account/order.svg'
    },
    {
        name: 'menu-location-invert',
        src: '/images/icon/account/order-invert.svg'
    },
    {
        name: 'menu-support',
        src: '/images/icon/account/support.svg'
    },
    {
        name: 'menu-support-invert',
        src: '/images/icon/account/support-invert.svg'
    }
]