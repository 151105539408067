import React from 'react'
import Thumb from '../Products/Thumb'

const SubCategory = (props) => {
    const { data } = props
    return (
        <div className="row sub-categories">
            <div className="col-md-12 category-information"><h2>{ data.name }</h2></div>
            { data.products.map((product, index) => <Thumb key={ index } data={ product }/>) }
        </div>
    )
}

export default SubCategory