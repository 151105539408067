import { 
    initialState,
    SUCCESS_LOGIN,
    SUCCESS_LOGOUT,
    SUCCESS_UPDATE_USER
} from '../Constants/Auth'

export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case SUCCESS_LOGIN:
            return {
                currentUser: action.currentUser
            }
        case SUCCESS_UPDATE_USER:
            let updatedUserData = action.currentUser
            return {
                currentUser: updatedUserData
            }
        case SUCCESS_LOGOUT:
            return {
                currentUser: null
            }
        default:
            return state
    }
}