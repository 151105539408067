import React from 'react'
import Icon from 'Components/Icons/FontAwesome/Icon'

const SearchBar = ({searchHandler}) => {
    const value = searchHandler.value
    const handler = searchHandler.handler
    const submit = searchHandler.submit
    return (
        <div className="section">
            <div className="container">
                <div className="col-md-12 faq-search-area">
                    <h5>find your</h5>
                    <h3>answer here</h3>
                    <form onSubmit={ el => submit(el) }>
                        <div className="form-group">
                            <Icon name="search" />
                            <input type="text" placeholder="Type your question here" value={ value } onChange={ el => handler(el) }/>
                            <button type="submit" className="btn-submit">
                                <img className="icon-svg" src="/images/icon/arrow-right.png" alt="submit-subscription"/>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SearchBar