import React, { Component } from 'react'
import AccountTemplate from '../../Section/AccountTemplate'
import ProfileForm from './Forms/Profile'
import PasswordForm from './Forms/Password'
import Loader from 'Components/Loader/Skeleton'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import { handleToast } from 'Services/Toastify'

class AccountProfile extends Component {
    constructor(){
        super()
        this.state = {
            isLoading: true,
            profileData: null
        }
    }

    showLoading = () => {
        return(
            <Loader count={ 3 } height={ 45 } width={ '75%' } style={{ marginBottom: '20px', marginTop: '40px', display:'block' }}/>
        )
    }

    componentDidMount(){
        AccountAPI.getProfile().then(res => {
            if(res.status === 200){
                const profileData = res.data.data
                this.setState({
                    profileData: profileData
                })
            }else{
                handleToast('Cannot load ')
            }

            this.setState({
                isLoading: false
            })
        })
    }

    render() {
        const { isLoading, profileData } = this.state
        return (
            <AccountTemplate title="My Account" { ...this.props }>
                {
                    isLoading ? 
                    this.showLoading() :
                    <div className="account-form-content">
                        <ProfileForm { ...this.props } profileData={ profileData }/>
                        <PasswordForm { ...this.props }/>
                        {/* <div className="section-account">
                            <h5 className="form-title">Email Subscription</h5>
                            <div className="form-group checkbox-wrapper">
                                <label className="container-checkbox">
                                    Order Updates
                                    <input 
                                        className={ `checkbox-input` } 
                                        type="checkbox"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-group checkbox-wrapper">
                                <label className="container-checkbox">
                                    News and Promotion
                                    <input 
                                        className={ `checkbox-input` } 
                                        type="checkbox"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="form-group checkbox-wrapper">
                                <label className="container-checkbox">
                                    Exclusive offers
                                    <input 
                                        className={ `checkbox-input` } 
                                        type="checkbox"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div> */}
                    </div>
                }
            </AccountTemplate>
        )
    }
}

export default AccountProfile