import React from 'react'

const SelectBoxInput = (props) => {
    const { handler, touched, hasError, meta, errors } = props
    let classIndicator = touched && errors ? 'form-control select-autocomplete-wrapper error-input' : 'form-control select-autocomplete-wrapper'
    const label = meta.label
    return(
        <div className="form-group select-group">
            {
                label.show &&
                <label htmlFor={ label.text } className="placeholder"><b>{ label.text }</b></label>
            }
            <select
                className={ classIndicator } 
                options={ meta.options }
                disabled={ meta.disabled }
                { ...handler() }
            >
                {
                    meta?.options.map((el, index) => <option key={ index } value={ el.value }>{ el.label }</option>)
                }
            </select>
            <span className="warning-input">
                { touched && hasError("required") && `${ label.text } is required` }
            </span>
        </div>
    )
}

export default SelectBoxInput