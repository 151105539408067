import React, { Component } from 'react'
import GuestUserInformation from './UserInformation'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class Checkout extends Component {
    constructor(){
        super()
        this.state = {
            guestUserInfo: null,
            processing: false
        }
    }

    setUserInfo = obj => {
        this.setState({
            guestUserInfo: obj
        })
    }

    render() {
        const { cartData, setShipping, onSyncCart } = this.props
        const { guestUserInfo, processing } = this.state
        return (
            <div className="checkout-guess">
                <GuestUserInformation
                    cartData={ cartData }
                    processing={ processing }
                    userInfo={ guestUserInfo }
                    setUserInfo={ this.setUserInfo }
                    setShipping={ setShipping }
                    onSyncCart={ onSyncCart }
                />
            </div>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(Checkout) 