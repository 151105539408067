import React from 'react'
import Main from './Main'

export const AboutPageConfig = {
    pageName: 'About',
    routes: [   
        {
            path: '/about',
            authorizedOnly: false,
            Component: props => {
                return(<Main { ...props } />)
            }
        }
    ]
}