import React from 'react'
import Select from 'react-select'

const handleChange = (e, props) => {
    const { handler } = props
    if(props.callBack){
        props.callBack(e)
    }
    
    return handler().onChange(e)
}

const AutoCompleteInput = (props) => {
    const { handler, touched, hasError, meta, errors, options, value, disabled } = props
    let classIndicator = touched && errors ? 'select-autocomplete-wrapper error-input' : 'select-autocomplete-wrapper'
    const label = meta.label
    return(
        <div className="form-autocomplete-group">
            {
                label.show &&
                <label htmlFor={ label.text } className="placeholder"><b>{ label.text }</b></label>
            }
            <Select
                className={ classIndicator } 
                classNamePrefix={ 'autocomplete-select' }
                options={ options }
                isDisabled={ disabled }
                placeholder={ meta.placeHolder }
                onChange={ (e) => handleChange(e, props) }
                onBlur={ handler().onBlur }
                value={ value }
           />
            <span className="warning-input">
                { touched && hasError("required") && `${ label.text } is required` }
            </span>
        </div>
    )
}

export default AutoCompleteInput