import axios from 'axios'

const AuthorizedAxios = (manualToken) => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_API_HOST,
        method: 'get',
        validateStatus: function (status) {
            return status < 500
        },
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 30000,
    }

    // Create instance
    let instance = axios.create(defaultOptions)

    // Set the AUTH token for any request
    instance.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${ manualToken }`
        return config
    });

    // redirect to unauthorize
    instance.interceptors.response.use(null, function(error) {
        const resp = error.response;

        if (typeof resp === 'undefined') {
            return Promise.reject(error)
        }

        return Promise.reject(error)
    });

    return instance;
}

export default AuthorizedAxios