import React from 'react'
import SignIn from './SignIn'
import SignInForm from './SignIn/Form'
import Register from './Register'
import RegisterEmailForm from './Register/FormEmail'
import RegisterSocialForm from './Register/FormSocial'
import ForgotPassForm from './ForgotPassword/Form'
import ResetPassForm from './ResetPassword/Form'
import SocialAuth from './SocialAuth'
import Logout from './Logout'

export const AuthPageConfig = {
    pageName: 'Sign In',
    routes: [   
        {
            path: '/auth/sign-in',
            exact: true,
            authorizedOnly: false,
            authRoute: true,
            Component: props => {
                return(<SignIn { ...props } />)
            }
        },
        {
            path: '/auth/sign-in/email',
            exact: true,
            authorizedOnly: false,
            authRoute: true,
            Component: props => {
                return(<SignInForm { ...props } />)
            }
        },
        {
            path: '/auth/register',
            exact: true,
            authorizedOnly: false,
            authRoute: true,
            Component: props => {
                return(<Register { ...props } />)
            }
        },
        {
            path: '/auth/register/email',
            exact: true,
            authorizedOnly: false,
            authRoute: true,
            Component: props => {
                return(<RegisterEmailForm { ...props } />)
            }
        },
        {
            path: '/auth/register/social',
            exact: true,
            authorizedOnly: false,
            authRoute: true,
            Component: props => {
                return(<RegisterSocialForm { ...props } />)
            }
        },
        {
            path: '/auth/forgot-password',
            exact: true,
            authorizedOnly: false,
            authRoute: true,
            Component: props => {
                return(<ForgotPassForm { ...props } />)
            }
        },
        {
            path: '/auth/reset-password',
            exact: false,
            authorizedOnly: false,
            authRoute: true,
            Component: props => {
                return(<ResetPassForm { ...props } />)
            }
        },
        {
            path: '/auth/social',
            exact: false,
            authorizedOnly: false,
            authRoute: true,
            Component: props => {
                return(<SocialAuth { ...props } />)
            }
        },
        {
            path: '/auth/logout',
            exact: false,
            authorizedOnly: false,
            Component: props => {
                return(<Logout { ...props } />)
            }
        }
    ]
}