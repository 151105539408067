import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import SliderBanner from './Containers/SliderBanner'
import QuickLink from './Containers/QuickLink'
import HomeIntro from './Containers/HomeIntro'
import Benefit from './Containers/Benefit'
import Testimonials from './Containers/Testimonials'
import ShowCase from './Containers/ShowCase'

class HomeScreen extends Component {
    render() {
        return (
            <PageTemplate { ...this.props }>
                <SliderBanner />
                <QuickLink />
                <HomeIntro />
                <Benefit />
                <Testimonials />
                <ShowCase />
            </PageTemplate>
        )
    }
}

export default HomeScreen