import React, { Component } from 'react'
import CatalogThumb from 'Components/Image/CatalogThumb'
import { NavLink } from 'react-router-dom'
import { thousandSeparator, slugify } from 'Services/Helpers/Main'

class Thumb extends Component {
    constructor(){
        super()
        this.state = {
            selectedColor: null
        }
    }

    componentDidMount(){
        const { data } = this.props
        const product = data
        const { available_colors } = product

        if(available_colors.length > 0){
            this.setState({
                selectedColor: available_colors[0]
            })
        }
    }

    handleClickColor = (e, color) => {
        e.preventDefault()
        this.setState({
            selectedColor: color
        })
    }

    render() {
        let { selectedColor } = this.state
        const { data } = this.props
        const product = data
        const colors = product.available_colors
        if(!selectedColor){
            return null
        }

        let productName = product.name
        let param = null
        
        if(product.have_colors){
            param = {
                ...param,
                c: selectedColor.id
            }
        }

        if(product.have_sizes){
            productName = `${ product.name } ${ product.size_name }`
            param = {
                ...param,
                s: product.size_id
            }
        }
        

        let productLink = `/catalog/detail/${ product.id }/${ slugify(productName) }`
        if(param){
            const qString = new URLSearchParams(param).toString()
            productLink = `${ productLink }?${ qString }`
        }

        return (
            <div className="col-md-4 wrap-product">
                <NavLink to={ productLink }>
                    <CatalogThumb src={ selectedColor.thumbnail_image_url } />
                    <span className="title">{ productName }</span>
                    <span className="price">Rp. { thousandSeparator(product.price) }</span>
                </NavLink>
                <div className="color-picker">
                    <span className="title">Available Colors</span>
                    <div className="color-circles">
                        {
                            product.have_colors && colors.map((el,index) => {
                                let selectedClass = ''
                                if(el.id === selectedColor.id){
                                    selectedClass = 'selected'
                                }
                                return(
                                    <button 
                                        key={ index } 
                                        className={ `color-button ${ selectedClass }` }  
                                        type="button" 
                                        onClick={ (e) => this.handleClickColor(e, el) }
                                    >
                                        <span className="color" style={ {
                                            backgroundColor: el.hexcode
                                        } } title={ el.name }/>
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Thumb