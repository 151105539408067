import React from 'react'

const Step = (props) => {
    const { defaultStep, currentStep } = props
    let stepLength = defaultStep.length
    let currentStepCount = 1

    if(currentStep === 'tag'){
        currentStepCount = 2
    }
    if(currentStep === defaultStep[0]){
        currentStepCount = 1
        stepLength = 1
    }

    return (
        <h3>Step { currentStepCount } of { stepLength }</h3>
    )
}

export default Step