import React from 'react'
import Loader from 'Components/Loader/Skeleton'

const LoaderImage = () => {
    return (
        <div className="col-md-6 order-md-2 order-sm-1">
            <div className="product-gallery">
                <Loader count={ 1 } height={ 635 } width={ 525 } style={ {marginTop: '20px'} }/>
            </div>
        </div>
    )
}

export default LoaderImage