import React from 'react'

const TextInputPassword = (props) => {
    const { handler, touched, hasError, meta, errors } = props
    let classIndicator = touched && errors ? 'form-control error-input' : 'form-control'
    const label = meta.label
    return(
        <div className="form-group">
            {
                label.show &&
                <label htmlFor={ label.text } className="placeholder"><b>{ label.text }</b></label>
            }
            <input 
                id={ label.text } 
                className={ classIndicator }
                type="password" 
                placeholder={ meta.placeHolder } 
                autoFocus={ meta.autoFocus }
                { ...handler() }
            />
            <span className="warning-input">
                { touched && hasError("required") && `${ label.text } is required` }
            </span>
        </div>
    )
}

export default TextInputPassword