import React from 'react'

const availableStatus = [
    {
        value: 'processing',
        label: 'Processing',
        bgColor: '#f1a56f',
        textColor: '#FFFFFF'
    },
    {
        value: 'pending-payment',
        label: 'Pending Payment',
        bgColor: '#EDE392',
        textColor: '#6A6332'
    },
    {
        value: 'paid',
        label: 'Paid',
        bgColor: '#ACC8FF',
        textColor: '#48556E'
    },
    {
        value: 'shipped',
        label: 'Shipped',
        bgColor: '#B4E6C7',
        textColor: '#326747'
    },
    {
        value: 'canceled',
        label: 'Canceled',
        bgColor: '#EA5252',
        textColor: '#761012'
    },
    {
        value: 'delivered',
        label: 'Delivered',
        bgColor: '#272727',
        textColor: '#FFFFFF'
    }
]

const OrderStatus = ({ value }) => {
    const targetStatus = availableStatus.find(el => {
        return el.value === value
    })

    if(!targetStatus) return null

    return (
        <div className="base-status" style={
            {
                backgroundColor: targetStatus.bgColor,
                color: targetStatus.textColor
            }
        }>
            { targetStatus.label }
        </div>
    )
}

export default OrderStatus