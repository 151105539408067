import React, { Component } from 'react'
import Icon from 'Components/Icons/FontAwesome/Icon'

class ReviewRate extends Component {
    renderBlackHeart = () => {
        const { detailData } = this.props.detailConfig
        if(!detailData.reviews) return null
        const { ratings } = detailData.reviews
        const blackHeart = ratings

        let blackHeartComponent = []
        for(let i = 0; i < blackHeart; i++){
            blackHeartComponent.push(<Icon name="heart" key={ i }/>)
        }

        return blackHeartComponent
    }

    renderWhiteHeart = () => {
        const { detailData } = this.props.detailConfig
        if(!detailData.reviews) return null
        const { ratings } = detailData.reviews
        
        let defaultCountRate = 5
        const blackHeart = ratings
        const whiteHeart = defaultCountRate - blackHeart

        let whiteHeartComponent = []
        for(let i = 0; i < whiteHeart; i++){
            whiteHeartComponent.push(<Icon type="far" name="heart" key={ i }/>)
        }

        return whiteHeartComponent
    }

    render() {
        const { detailData } = this.props.detailConfig
        if(!detailData.reviews) return null
        const { ratings, total_reviews } = detailData.reviews

        return (
            <div className="review-rate">
                { this.renderBlackHeart() }
                { this.renderWhiteHeart() }
                <span>{ ratings }/5 ({ total_reviews } reviews)</span>
            </div>
        )
    }
}

export default ReviewRate