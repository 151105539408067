import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Step from './Step'
import ActionButtons from './ActionButtons'

class PersonalizeSuitCase extends Component {
    constructor(){
        super()
        this.state = {
            redirectTo: null
        }
    }
    
    getButtonIcon = (el) => {
        let selectedType = ''
        const { suitcase } = this.props
        const selectedMargin = suitcase.selectedMargin
        if(selectedMargin === el){
            selectedType = '-1'
        }
        return `/images/icon/personalize/position/${ el }${ selectedType }.svg`
    }

    handleAccept = (e) => {
        e.preventDefault()
        const { handler } = this.props
        return handler.toggleStep('tag')
    }

    setRedirectTo = path => {
        this.setState({
            redirectTo: path
        })
    }

    render() {
        const { suitcase, handler } = this.props
        const { selectedMargin, letter } = suitcase
        const { redirectTo } = this.state
        const marginPost = ['left', 'center', 'right']

        if(redirectTo){
            return(<Redirect to={ redirectTo } />)
        }

        const { onChangeMargin, handleChangeLetterSuitcase } = handler

        return (
            <div className="wrapper-white">
                <div className="container personalize-area">
                    <div className="row">
                        <div className="col-xl-6 col-md-12 personalize-product order-xl-2 order-md-1 order-sm-1">
                            <div className="personalize-image suitcase">
                                <img src="/images/placeholder/personalize/1.png" alt="personalize-suitcase"/>
                                <span className={ `text-letter text-${ selectedMargin }` }>{ letter }</span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12 personalize-input order-xl-1 order-md-2 order-sm-2">
                            <Step { ...this.props }/>
                            <h1>Personalize your suitcase</h1>
                            <span className="message">Engrave your name, initials, or lucky number</span>
                            <div className="input-area">
                                <span className="letter-info">letters</span>
                                <input type="text" value={ suitcase.letter } placeholder="BLR" maxLength="3" onChange={ handleChangeLetterSuitcase }/>
                                <span className="text-guide">Enter up to three letters or numbers</span>
                            </div>
                            <div className="option-switch">
                                <p className="guide">choose position: <strong>LEFT</strong></p>
                                <div className="wrap-button">
                                    {
                                        marginPost.map((el, index) => {
                                            return(
                                                <button key={ index } className={ selectedMargin === el ? 'selected' : '' } onClick={ () => onChangeMargin(el) }>
                                                    <div className="margin-button">
                                                        <img src={ this.getButtonIcon(el) } alt="text-position"/>
                                                        <span>{ el }</span>
                                                    </div>
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <ActionButtons { ...this.props }/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PersonalizeSuitCase