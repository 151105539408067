import React, { Component } from 'react'
import ResponsiveDialog from 'Components/Dialogs/Responsive'
import AddressForm from './Forms/Address'
import AddressList from './AddressList'
import Loader from 'Components/Loader/Skeleton'

class ShippingAddress extends Component {
    constructor(){
        super()
        this.state = {
            openDialog: false,
            addNewAddress: false,
            formType: 'add',
            editId: null
        }
    }

    toggleopenDialog = (flag) => {
        const { isProcessingPayment } = this.props
        if(isProcessingPayment){
            return false
        }
        this.setState({
            openDialog: flag ? true : false,
            addNewAddress: false
        })
    }

    toggleOpenForm = (flag) => {
        const { selectedAddress, isProcessingPayment } = this.props
        if(isProcessingPayment){
            return false
        }
        this.setState({
            addNewAddress: flag ? true : false,
            openDialog: !selectedAddress ? false : this.state.openDialog,
            formType: 'add',
            editId: null
        })
    }

    toggleOpenFormEdit = (editId) => {
        this.setState({
            addNewAddress: true,
            openDialog: true,
            formType: 'edit',
            editId: editId
        })
    }

    toggleOpenDialogAddAddress = () => {
        this.setState({
            openDialog: true,
            addNewAddress: true
        })
    }

    renderSelectedAddress = (selectedAddress) => {
        let address = selectedAddress.address
        if(selectedAddress.province_name){
            address = `${ address }, ${ selectedAddress.province_name }`
        }
        if(selectedAddress.city_name){
            address = `${ address }, ${ selectedAddress.city_name }`
        }

        if(selectedAddress.zip_code){
            address = `${ address }, ${ selectedAddress.zip_code }`
        }
        return(
            <div className="info-box">
                <span className="highlight">Receiver : { selectedAddress.receiver_name ? selectedAddress.receiver_name : '-' }</span>
                <span className="highlight">{ selectedAddress.receiver_number }</span>
                <span>{ address }</span>
                <span><em>Remarks: { selectedAddress.remarks ? selectedAddress.remarks : '-' }</em></span>
                <button className="button-link" onClick={ () => this.toggleopenDialog(true) }>change</button>
            </div>
        )
    }

    render() {
        const { openDialog, addNewAddress, formType, editId } = this.state
        const { selectedAddress, isLoadingAddress } = this.props
        
        if(isLoadingAddress){
            return(
                <div className="info-card">
                    <h3><Loader count={ 1 } style={ { width: '75%' }}/></h3>
                    <Loader count={ 3 } style={ { width: '75%' }}/>
                </div>
            )
        }

        return (
            <>
                <div className="info-card">
                    <h3>Shipping Address</h3>
                    {
                        selectedAddress ?
                        this.renderSelectedAddress(selectedAddress) :
                        <div className="info-box">
                            <span><em>No address found, please add new address</em></span>
                            <button className="button-link" onClick={ () => this.toggleOpenDialogAddAddress() }>Add Address</button>
                        </div>
                    }
                </div>
                <ResponsiveDialog open={ openDialog } handler={ this.toggleopenDialog } className="dialog-medium">
                    <div className="form-wrapper-checkout">
                        {
                            !addNewAddress ?
                            <AddressList 
                                { ...this.props } 
                                selectedAddress={ selectedAddress }
                                toggleOpenForm={ this.toggleOpenForm } 
                                toggleOpenFormEdit={ this.toggleOpenFormEdit }
                                toggleopenDialog={ this.toggleopenDialog }
                            /> :
                            <AddressForm 
                                { ...this.props } 
                                formType={ formType }
                                editId={ editId }
                                dialogToggle={ this.toggleopenDialog } 
                                toggleOpenForm={ this.toggleOpenForm }
                            />
                        }
                    </div>
                </ResponsiveDialog>
            </>
        )
    }
}

export default ShippingAddress