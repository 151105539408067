import React, { Component } from 'react'

export default class BlogMenu extends Component {
    render() {
        const { blogData } = this.props
        if(blogData.length <= 0){
            return null
        }
        return (
            <ul className="blog-list">
                {
                    blogData.map((blog, index) => {
                        return(
                            <li key={ index }>
                                <a href={ blog.link } target="blank">
                                    <div className="image-blog">
                                        <img src={ blog.image_url } alt="blog-img"/>
                                    </div>
                                    <div className="title">{ blog.title }</div>
                                </a>
                            </li>
                        )
                    })
                }
                <li className="view-more">
                    <a href={ `https://baller.co.id/blog/` } target="blank">Find out more</a>
                </li>
            </ul>
        )
    }
}
