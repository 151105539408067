import React, { Component } from 'react'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import { handleToast } from 'Services/Toastify'
import { connect } from 'react-redux'
import { authMapStateToProps, authMapDispatchToProps } from 'Constants/Redux/Auth'

class SocialAuth extends Component {
    componentDidMount(){
        const query = new URLSearchParams(this.props.location.search)
        const token = query.get('t')
        AccountAPI.getProfileManual(token).then(res => {
            if(res.status === 200){
                const data = res.data?.data
                let forceRedirect = null
                if(localStorage.getItem('after_auth_redirect')){
                    forceRedirect = localStorage.getItem('after_auth_redirect')
                    localStorage.removeItem('after_auth_redirect')
                }
                if(data){
                    let redirectTo = forceRedirect ? forceRedirect : `/`
                    let directAuthenticate = true
                    if(!data.phone_number || !data.name){
                        redirectTo = `/auth/register/social?t=${ token }`
                        directAuthenticate = false
                    }

                    if(directAuthenticate){
                        const objLogin = {
                            name: data.name,
                            phone_number: data.phone_number,
                            email: data.email,
                            api_token: token
                        }
                        this.props.onSuccessLogin(objLogin)
                        handleToast('Successfully login', 'success')
                    }

                    console.log(redirectTo)
                    return this.props.history.push(redirectTo)
                }

                return false;
            }
        })
    }
    render() {
        return (
            <p>Checking social media...</p>
        )
    }
}

export default connect(authMapStateToProps, authMapDispatchToProps)(SocialAuth) 