import fetchAxios from '../AuthorizedConfig'
import fetchManualAxios from '../AuthorizeManualConfig'
//import fetchSwagger from '../SwaggerConfig'

class AccountAPI {
    // profile
    static getProfile = json => fetchAxios.get( '/users/auth/profile' , json )
    static getProfileManual = (token) => {
        const manualAxios = fetchManualAxios(token)
        return manualAxios.get( '/users/auth/profile' )
    }
    static updateProfile = json => fetchAxios.put( '/users/auth/profile', json )
    static updateProfileManual = (token, json) => {
        const manualAxios = fetchManualAxios(token)
        return manualAxios.put( '/users/auth/profile', json )
    }
    static changePassword = json => fetchAxios.post( '/users/auth/change_password', json )
    static createSupportTicket = json => fetchAxios.post( '/users/auth/supports', json )
    
    //support
    static getSupportList = (page) => {
        const params = {
            page: page
        }
        return fetchAxios.get( '/users/auth/supports', { params } )
    }

    //orders
    static getOrderHistoryList = (page) => {
        const params = {
            page: page
        }
        return fetchAxios.get( '/users/auth/orders', { params } )
    }
    static getOrderDetail = id => {
        return fetchAxios.get( `/users/auth/orders/${ id }` )
    }

    //address
    static getAddressList = (page) => {
        const params = {
            page: page
        }
        return fetchAxios.get( '/users/auth/address', { params } )
    }
    static getProvinceOpt = () => fetchAxios.get( `/provinces` )
    static getCitiesOpt = (provinceId) => fetchAxios.get( `/cities?province_id=${ provinceId }` )
    static createAddress = json => fetchAxios.post( '/users/auth/address', json )
    static detailAddress = id => fetchAxios.get( `/users/auth/address/${ id }` )
    static getPrimaryAddress = () => fetchAxios.get( `/users/auth/address/primary` )
    static updateAddress = (id, json) => fetchAxios.put( `/users/auth/address/${ id }`, json )
    static deleteAddress = id => {
        return fetchAxios.delete( `/users/auth/address/${ id }` )
    }
}

export default AccountAPI