import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import DefaultForm from 'Components/Form/Default'
import FormButton from 'Components/Form/Buttons/Default'
import { NavLink } from 'react-router-dom'
import AuthApi from 'Services/API/Guest/AuthAPI'
import { handleToast } from 'Services/Toastify'

class ForgotPassForm extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    handleSubmit = (formData) => {
        this.toggleProcess(true)

        const { value } = formData

        let cencorEmailName = value.email.substring(0, value.email.lastIndexOf('@'))
        cencorEmailName = cencorEmailName.substring(0,2) + '###'  

        const cencorEmailDomain = value.email.substring(value.email.lastIndexOf('@') +1)
        
        AuthApi.recoverPassword(value.email).then(response => {
            this.toggleProcess(false)
            let type = 'warning'
            let message = 'Failed to recover password, please try again later.'
            if(response.status === 200){
                type = 'success'
                message = `Request recover password sent, please check your email ${ cencorEmailName }@${ cencorEmailDomain }`
            }
            handleToast(message, type)
        })
    }

    render() {
        return (
            <PageTemplate { ...this.props }>
                <div className="container">
                    <div className="auth-content">
                        <h3>Recover Password</h3>
                        <DefaultForm 
                            { ...this.props }
                            className={ `auth-form` }
                            isProcessing={ this.state.isProcessing }
                            handleSubmit={ this.handleSubmit }
                            formInputs={ 
                                [
                                    {
                                        name: 'email',
                                        type: 'TextInput',
                                        defaultValue: '',
                                        validator: 'required,email',
                                        meta: {
                                            label: {
                                                text: 'Email',
                                                show: false
                                            },
                                            placeHolder: 'Email Address',
                                            autoFocus: true
                                        }
                                    }
                                ]
                            }
                            actionComponent={ props => {
                                return(
                                    <div className="form-group form-action-d-flex mb-3">
                                        <FormButton 
                                            className="btn submit-btn"
                                            label={ `Reset Password` }
                                            type={ `submit` }
                                            isProcessing={ this.state.isProcessing }
                                            disabled={ props.invalid } 
                                        />
                                    </div>
                                )
                            }}
                        />
                        <NavLink to={ '/auth/sign-in' } className="link-outside">
                            <span>Already have an account</span>
                        </NavLink>
                        <NavLink to={ '/auth/register' } className="link-outside has-border">
                            <span>Don't have an account?</span>
                        </NavLink>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default ForgotPassForm 