import React, { Component } from 'react'
import Icon from 'Components/Icons/FontAwesome/Icon'
import { thousandSeparator, slugify } from 'Services/Helpers/Main'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class Item extends Component {
    constructor(){
        super()
        this.state = {
            deletedAction: 0
        }
    }
    handleRemoveItem = () => {
        const { cartIndex, cartData, onSyncCart, syncCartItemsView} = this.props
        let updatedCart = cartData.filter((el, index) => {
            return index !== cartIndex
        })

        syncCartItemsView(updatedCart)
        return onSyncCart(updatedCart)
    }

    handleClickPersonalize = (url) => {
        const { history, closeCartSlider } = this.props 
        closeCartSlider()
        history.push(url)
    }

    handleClickEditPersonalize = (e, url) => {
        e.preventDefault()
        this.handleClickPersonalize(url)
    }

    handleDeletePersonalize = (e) => {
        e.preventDefault()
        const { cartIndex, cartData, onSyncCart, item, location, history, syncCartItemsView } = this.props
        let updatedCart = cartData
        if(updatedCart[cartIndex]){
            updatedCart[cartIndex].personalized = null
        }

        const baseUrlPersonalize = `/catalog/personalize/${ item.id }/${ slugify(item.name) }`
        const currentPathName = location.pathname

        if(currentPathName === baseUrlPersonalize){
            history.push('/catalog')
        }

        this.setState({
            deletedAction: this.state.deletedAction + 1
        })
        syncCartItemsView(updatedCart)
        return onSyncCart(updatedCart)
    }

    renderPersonalize = (personalizeDetail) => {
        const { item, cartIndex } = this.props
        const baseUrlPersonalize = `/catalog/personalize/${ item.id }/${ slugify(item.name) }`
        let urlParam = {}
        if(item.sizeId){
            urlParam = {
                ...urlParam,
                s: item.sizeId
            }
        }

        if(item.colorId){
            urlParam = {
                ...urlParam,
                c: item.colorId
            }
        }

        const urlParamEdit = {
            ...urlParam,
            cart: cartIndex
        }

        const urlParamEditText = new URLSearchParams(urlParamEdit)
        const urlEditPersonalize = baseUrlPersonalize + '?' + urlParamEditText.toString()
        if(!item.canEditPersonalize){
            return null
        }

        let cleanPersonalized = personalizeDetail.filter(el => {
            return el.value.length > 0
        })

        if(cleanPersonalized && cleanPersonalized.length > 0){
            return(
                <div className="personalize-wrapper">
                    <div className="head-personalize">
                        Personalization
                        <a 
                            className="action-personalize" 
                            href="/" 
                            title="Edit personalization"
                            onClick={ e => this.handleClickEditPersonalize(e, urlEditPersonalize) }
                        >
                            <Icon name="pencil-alt"/>
                        </a> 
                        <a
                            className="action-personalize"
                            href="/"
                            title="Remove personalization"
                            onClick={ e => this.handleDeletePersonalize(e) }
                        >
                            <Icon name="times"/>
                        </a>
                    </div>
                    {
                        cleanPersonalized.map((row, index ) => {
                            return(
                            <span key={ index } className="personalize-info">{ row.label }: { row.value } ({ row.price === 0 ? 'FREE' : row.price })</span>
                            )
                        })
                    }
                </div>
            )
        }

        return(
            <button 
                className="personalize-it"
                onClick={ () => this.handleClickPersonalize(urlEditPersonalize) }
            >
                Personalize it
            </button>
        )
    }

    render(){
        const { item } = this.props

        let attributes = []
        let personalizeDetail = []
        if(item.colorName){
            attributes.push({
                label: 'Color',
                value: item.colorName
            })
        }

        attributes.push({
            label: 'Qty',
            value: 1
        })

        if(item.personalized){
            const personalizeInfo = item.personalized
            if(personalizeInfo.suitcase){
                personalizeDetail.push({
                    label: 'Suitcase',
                    value: personalizeInfo.suitcase.letter,
                    price: personalizeInfo.suitcase.price
                })
            }
            if(personalizeInfo.tag){
                personalizeDetail.push({
                    label: 'Initial',
                    value: personalizeInfo.tag.letter,
                    price: personalizeInfo.tag.price
                })
            }
        }

        return(
            <div className="cart-item">
                <div className="image-wrapper">
                    <img src={ item.image && item.image.image_url } alt="prod" />
                </div>
                <div className="item-detail">
                    <span className="title">{ `${ item.name } ${ item.sizeName }` }</span>
                    {
                        attributes.map((attr, index) => {
                            return(<span key={ index }>{ attr.label }: { attr.value }</span>)
                        })
                    }
                    {
                        this.renderPersonalize(personalizeDetail)
                    }
                    <span className="price">Rp.{ thousandSeparator(item.price) }</span>
                    <div className="item-action">
                        <button type="button" className="delete" onClick={ this.handleRemoveItem }>
                            <span>Delete</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(Item)