import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './Styles/Core.scss'
import 'react-toastify/dist/ReactToastify.css'
import RouteSwitch from 'Routes/Switch'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { persistor, store } from './Services/Redux/Store'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
    <Provider store={ store }>
        <PersistGate loading={ null } persistor={ persistor }>
            <BrowserRouter>
                <RouteSwitch />
            </BrowserRouter>
            <ToastContainer />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)

serviceWorker.unregister()