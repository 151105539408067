import React, { Component } from 'react'
import ShipmentAPI from 'Services/API/Guest/ShipmentAPI'
import Loader from 'Components/Loader/Skeleton'
import { stringToCurrency } from 'Services/Helpers/Main'

class ShipOptions extends Component {
    constructor(){
        super()
        this.state = {
            loadingShipment: true,
            options: []
        }
    }

    toggleLoading = flag => {
        this.setState({
            loadingShipment: flag ? true : false
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const { cartData, selectedAddress } = this.props
        if(selectedAddress !== nextProps.selectedAddress){
            if(selectedAddress?.city_id === nextProps?.selectedAddress?.city_id){
                return false
            }
            let shipmentObj = null
            let cartObj = []
            cartData.forEach(el => {
                let check = cartObj.find(element => {
                    return element.product_id === el.id
                })
                if(!check){
                    return cartObj.push({
                        product_id: el.id,
                        size_id: el.sizeId,
                        quantity: 1
                    })
                }else{
                    check.quantity = check.quantity + 1
                    return cartObj
                }
            })
    
            const finalAddress = nextProps.selectedAddress
            shipmentObj = {
                city_id: finalAddress.city_id,
                items: cartObj
            }
            this.toggleLoading(true)
            ShipmentAPI.getShipmentOptions(shipmentObj).then(res => {
                if(res.status === 200){
                    this.setState({
                        options: res.data.data
                    })
                }
                this.toggleLoading(false)
            })
        }
    }

    handleClickService = (e, obj) => {
        e.preventDefault()
        this.props.setShipmentInfo(obj)
    }

    render() {
        const { selectedAddress, shipmentInfo } = this.props
        const { loadingShipment, options } = this.state
        if(!selectedAddress){
            return(
                <div className="info-card" style={{textAlign: 'center'}}>
                    <h3>Shipping Method</h3>
                    <div className="ship-options" style={{fontStyle:'italic', color: '#969494'}}>
                        Please complete the shipping address first
                    </div>
                </div>
            )
        }

        if(loadingShipment){
            return(
                <div className="info-card" style={{textAlign: 'center'}}>
                    <h3>Shipping Method</h3>
                    <Loader count={ 3 }/>
                </div>
            )
        }

        return (
            <div className="info-card" style={{textAlign: 'center'}}>
                 <h3>Shipping Method</h3>
                 <div className="ship-options">
                    {
                        options.map((el, index) => {
                            return(
                                <div key={ index }>
                                    <h5>{ el.name }</h5>
                                    <div className="service-options">
                                        {
                                            el.services.map((service, index) => {
                                                let checked = false
                                                if(shipmentInfo){
                                                    if(el.code === shipmentInfo.code && service.name === shipmentInfo.service){
                                                        checked = true
                                                    }
                                                }
                                                return(
                                                    <div className={ `row-service checkbox-wrapper ${ checked ? 'selected' : ''}` } key={ index }>
                                                        <label className={ `container-checkbox` } 
                                                            onClick={ (e) => this.handleClickService(e, {code: el.code, service: service.name, price: service.services}) }>
                                                            <div className="service-value">
                                                                <span className="text-left">{ service.name }</span>
                                                                <span className="text-right">{ stringToCurrency(service.services) }</span>
                                                            </div>
                                                            <input 
                                                                id="checkbox-input" 
                                                                className="checkbox-input form-control" 
                                                                type="checkbox" 
                                                                readOnly
                                                                checked={ checked }
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                 </div>
            </div>
        )
    }
}

export default ShipOptions