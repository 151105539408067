import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import Categories from './Containers/Categories'
import CatalogAPI from 'Services/API/Guest/CatalogAPI'
import Loader from 'Components/Loader/Skeleton'
import { mapCategoryIdWithText } from 'Services/Helpers/Main'

class CatalogScreen extends Component {
    constructor(){
        super()
        this.state = {
            categories: [],
            isLoading: true
        }
    }

    toggleLoading = flag => {
        this.setState({
            isLoading: flag ? true : false
        })
    }

    showLoader = () => {
        return(
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-12 mt-5 mb-5 ml-2">
                        <Loader height={ 50 }/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Loader width={ 362 } height={ 551 } style={{ padding: '20px', display:'block' }}/>
                    </div>
                    <div className="col-md-4">
                        <Loader width={ 362 } height={ 551 } style={{ padding: '20px', display:'block' }}/>
                    </div>
                    <div className="col-md-4">
                        <Loader width={ 362 } height={ 551 } style={{ padding: '20px', display:'block' }}/>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-4">
                        <Loader width={ 362 } height={ 551 } style={{ padding: '20px', display:'block' }}/>
                    </div>
                    <div className="col-md-4">
                        <Loader width={ 362 } height={ 551 } style={{ padding: '20px', display:'block' }}/>
                    </div>
                    <div className="col-md-4">
                        <Loader width={ 362 } height={ 551 } style={{ padding: '20px', display:'block' }}/>
                    </div>
                </div>
            </div>
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const { match } = this.props
        const params = match.params
        if(params.type !== nextProps.match.params.type){
            const categoryId = mapCategoryIdWithText(nextProps.match.params.type)
            this.fetchDialog({category_id: categoryId})
        }
    }

    fetchDialog = (params = null) => {
        this.toggleLoading(true)
        CatalogAPI.get(params).then(res => {
            if(res.status === 200){
                this.setState({
                    categories: res.data.data
                })
            }
            this.toggleLoading(false)
        })
    }

    componentDidMount(){
        const categoryId = mapCategoryIdWithText(this.props.match.params.type)
        this.fetchDialog({category_id: categoryId})
    }

    render() {
        const { categories, isLoading } = this.state
        
        return (
            <PageTemplate { ...this.props }>
                <div className="info-area wrapper-white">
                    <div className="container">
                        <div className="col-md-6 text-left">
                            <p>
                                We would like to give assurance on your journey. 
                                An elegant travelling experience wherever you are.
                            </p>
                        </div>
                    </div>
                </div>
                {
                    !isLoading ? 
                    <Categories categories={ categories }/> :
                    this.showLoader()
                }
            </PageTemplate>
        )
    }
}

export default CatalogScreen