import { library } from '@fortawesome/fontawesome-svg-core'
import { faCode, faClock, faChevronDown, faTimes, faChevronRight, faChevronLeft, faAngleDoubleRight, faAngleLeft, faSearch, faHeart as faHeardSolid, faCheckCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle, faEdit, faTrashAlt, faHeart }  from '@fortawesome/free-regular-svg-icons'
import { faTwitter, faFacebook, faWhatsapp, faPinterest } from '@fortawesome/free-brands-svg-icons'

library.add(
    faCheckCircle,
    faClock,
    faCode,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faTimesCircle,
    faTimes,
    faFacebook,
    faTwitter,
    faWhatsapp,
    faPinterest,
    faSearch,
    faEdit,
    faTrashAlt,
    faHeardSolid,
    faHeart,
    faPencilAlt
)