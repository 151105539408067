import React, { Component } from 'react'
import AccountTemplate from '../../Section/AccountTemplate'
import DefaultForm from 'Components/Form/Default'
import FormButton from 'Components/Form/Buttons/Default'
import ResponsiveTable from 'Components/Table/Responsive'
import Loader from 'Components/Loader/Skeleton'
import SimplePaginator from 'Components/Paginator/Simple'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import { handleToast } from 'Services/Toastify'
import { getPaginationFromHeader } from 'Services/Helpers/Main'

class AccountProfile extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false,
            supportListConfig: {
                isLoading: true,
                data: [],
                page: 0,
                lastPage: 0,
                total: 0
            }
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    showLoading = () => {
        return(
            <Loader count={ 3 } height={ 35 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
        )
    }

    handleSubmitSupport = (formData) => {
        AccountAPI.createSupportTicket(formData.value).then(res => {
            this.toggleProcess(false)
            if(res.status === 200){
                handleToast('Successfully register your complaint.')
            }else{
                handleToast('Oops, something wrong, please try again later.', 'warning')
            }
            this.fetchList()
        })
    }

    fetchList = (obj = null) => {
        this.setState({
            supportListConfig:{
                ...this.state.supportListConfig,
                isLoading: true
            }
        })
        const page = obj?.page ? obj.page : 1
        AccountAPI.getSupportList(page).then(res => {
            if(res.status === 200){
                const listData = res.data.data
                const header = res.headers
                const { page, lastPage, total } = getPaginationFromHeader(header)

                this.setState({
                    supportListConfig:{
                        ...this.state.supportListConfig,
                        data: listData,
                        page: page,
                        lastPage: lastPage,
                        total: total
                    }
                })
            }

            this.setState({
                supportListConfig:{
                    ...this.state.supportListConfig,
                    isLoading: false
                }
            })
        })
    }

    handleChangePage = (page) => {
        this.fetchList({page: page})
    }

    componentDidMount(){
        this.fetchList()
    }

    generatePaginator = () => {
        const { supportListConfig } = this.state
        const { page, lastPage, total } = supportListConfig
        const paginationObj = {
            page: page,
            lastPage: lastPage,
            total: total
        }

        return(<SimplePaginator config={ paginationObj } handleChangePage={ this.handleChangePage }/>)
    }

    render() {
        const { supportListConfig } = this.state
        const tableConfig = {
            columnsHeader: [
                {
                    header: 'Date',
                    accessor: 'created_at',
                    type: 'date'
                },
                {
                    header: 'Ship To',
                    accessor: 'contact_name',
                    Cell: row => {
                        return(
                            <>
                                <span className="ship-name">{ row.original.contact_name } </span>
                                <span className="order-no">#{ row.original.order_code } </span>
                            </>
                        )
                    }
                },
                {
                    header: 'Category',
                    accessor: 'category'
                }
            ],
            onClickRow: this.handleClick
        }

        return (
            <AccountTemplate title="Support" { ...this.props }>
                <div className="section-account">
                    <h5 className="form-title">
                        <span>Warranty / Return Order Form</span>
                    </h5>
                    <DefaultForm 
                        { ...this.props }
                        className={ `account-form mt-30` }
                        isProcessing={ this.state.isProcessing }
                        handleSubmit={ this.handleSubmitSupport }
                        resetFormAfterSubmit={ true }
                        formInputs={ 
                            [
                                {
                                    name: 'order_no',
                                    type: 'TextInput',
                                    defaultValue: '',
                                    validator: 'required',
                                    meta: {
                                        label: {
                                            text: 'Order No',
                                            show: false
                                        },
                                        placeHolder: 'Enter your order number',
                                    }
                                },
                                {
                                    name: 'category',
                                    type: 'SelectBoxInput',
                                    defaultValue: 'WARRANTY',
                                    meta: {
                                        label: {
                                            text: 'Issue Type',
                                            show: false
                                        },
                                        placeHolder: 'Select type of the issue',
                                        options: [
                                            {
                                                value: 'WARRANTY',
                                                label: 'I want to claim warranty'
                                            },
                                            {
                                                value: 'RETURN',
                                                label: 'I want to return the suitcase'
                                            }
                                        ]
                                    }
                                },
                                {
                                    name: 'content',
                                    type: 'TextAreaInput',
                                    defaultValue: '',
                                    validator: 'required',
                                    meta: {
                                        label: {
                                            text: 'Remarks',
                                            show: false
                                        },
                                        placeHolder: 'Describe the issue',
                                    }
                                }
                            ]
                        }
                        actionComponent={ props => {
                            return(
                                <div className="form-group form-action-d-flex mb-3">
                                    <FormButton 
                                        className="action invert"
                                        label={ `Send` }
                                        type={ `submit` }
                                        isProcessing={ this.state.isProcessing }
                                        disabled={ props.invalid } 
                                    />
                                </div>
                            )
                        }}
                    />
                    <div className="complain-history-container">
                        <h5 className="form-title">
                            <span>Complaint History</span>
                        </h5>
                        {
                            supportListConfig?.isLoading ?
                            this.showLoading() : 
                            <>
                                <ResponsiveTable className="table-order-history" config={ tableConfig } data={ supportListConfig.data }/>
                                { this.generatePaginator() }
                            </>
                        }
                    </div>
                </div>
            </AccountTemplate>
        )
    }
}

export default AccountProfile