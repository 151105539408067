import React, { Component } from 'react'
import AddToCartDetail from 'Components/Buttons/AddToCartDetail'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class AddToCart extends Component {
    getItemData = () => {
        const { detailConfig, cartData } = this.props
        const { detailData, selection, bundlings } = detailConfig
        const size = selection.size ? selection.size : 1
        const color = selection.color ? selection.color : 1
        const { personalized } = detailData

        let selectedSize = []
        if(bundlings){
            if(bundlings.bundling){
                selectedSize = detailData.available_sizes.filter(el => {
                    const bundlingData = bundlings.bundling
                    const targetSize = bundlingData.find(bundling => {
                        return parseInt(bundling.size_id) === parseInt(el.id)
                    })

                    if(!targetSize && parseInt(el.id) !== parseInt(size)){
                        return null
                    }else{
                        return el
                    }
                })
            }
        }else{
            selectedSize = detailData.available_sizes.filter(el => {
                return parseInt(el.id) === parseInt(size)
            })
        }

        // generate cart data
        let cartDataFinal = cartData
        let canEditPersonalize = false
        if(personalized.suitcase || personalized.tag){
            canEditPersonalize = true
        }
        selectedSize.forEach((el) => {
            let personalizeData = null
            const selectedColor = el.available_colors.find(el => {
                return el.id === color
            })

            if(selectedColor){
                return cartDataFinal.push({
                    id: detailData.id,
                    name: detailData.name,
                    image: selectedColor.product_images ? selectedColor.product_images[0] : null,
                    sizeId: el.id,
                    sizeName: el.name,
                    quantity: 1,
                    price: el.price,
                    colorId: selectedColor.id,
                    colorName: selectedColor.name,
                    personalized: personalizeData,
                    canEditPersonalize: canEditPersonalize
                })
            }
            
            return false
        })

        return cartDataFinal
    }
    render() {
        const { detailConfig, history } = this.props
        const { detailData, selection } = detailConfig
        const { have_colors, have_sizes } = detailData
    
        let disabled = false
        if(have_sizes){
            if(!selection.size){
                disabled = true
            }
        }
        if(have_colors){
            if(!selection.color){
                disabled = true
            }
        }
    
        return (
            <AddToCartDetail className="action invert" type="button" disabled={ disabled } getItemData={ this.getItemData } history={ history }>
                <span>add to cart</span>
            </AddToCartDetail>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(AddToCart) 