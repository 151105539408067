import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'
import PageTemplate from 'Components/Page/Template'
import SuitCases from './Sections/SuitCases'
import Tag from './Sections/Tag'
import BundlingStepContainer from './BundlingSections/Main'
import CatalogAPI from 'Services/API/Guest/CatalogAPI'
import LoaderPersonalize from './Sections/Loader'
import { getTagProductId } from 'Services/Helpers/Main'

class Page extends Component {
    constructor(){
        super()
        this.state = {
            suitcase: {
                letter: '',
                selectedMargin: 'left',
                price: 0
            },
            tag: {
                letter: '',
                textColor: 'gold',
                tagColor: null,
                tagProductId: getTagProductId(),
                tagSizeId: null,
                price: 0
            },
            defaultData: {
                tagTextColor: ['gold', 'silver'],
                tagColors: null
            },
            rawDetailData: null,
            isLoading: true,
            defaultStep: [
                'suitcase',
                'tag'
            ],
            currentStep: 'suitcase',
            productPrice: 0,
            syncCartIndex: null,
            bundlingSteps: [],
            currentBundlingStep: 1,
            tagData: null
        }
    }

    onChangeMargin = (position) => {
        const { suitcase } = this.state
        this.setState({
            suitcase: {
                ...suitcase,
                selectedMargin: position
            }
        })
    }

    handleChangeLetterSuitcase = e => {
        const { suitcase } = this.state
        this.setState({
            suitcase: {
                ...suitcase,
                letter: e.currentTarget.value.toUpperCase()
            }
        })
    }

    handleChangeLetterTag = e => {
        const { tag } = this.state
        this.setState({
            tag: {
                ...tag,
                letter: e.currentTarget.value.toUpperCase()
            }
        })
    }

    handleChangeTextColorTag = value => {
        const { tag } = this.state
        this.setState({
            tag: {
                ...tag,
                textColor: value
            }
        })
    }

    handleChangeTagColor = value => {
        const { tag } = this.state
        this.setState({
            tag: {
                ...tag,
                tagColor: value.id
            }
        })
    }

    toggleStep = (par) => {
        this.setState({
            currentStep: par
        })
    }

    toggleLoading = flag => {
        this.setState({
            isLoading: flag ? true : false
        })
    }

    updateTagColorSet = (obj, sizeId) => {
        let defaultFirst = this.state.tag.tagColor
        const firstColor = obj[0].id
        if(firstColor && defaultFirst === null){
            defaultFirst = firstColor
        }

        if(defaultFirst){
            this.setState({
                ...this.state,
                tag: {
                    ...this.state.tag,
                    tagColor: defaultFirst,
                    tagSizeId: sizeId
                },
                defaultData: {
                    ...this.state.defaultData,
                    tagColors: obj
                }
            })
        }
    }

    setProductPrice = data => {
        const query = new URLSearchParams(this.props.location.search)
        const size = query.get('s')
        let matched = false
        let targetSizeId = null
        if(data && data.available_sizes){
            if(size){
                targetSizeId = size
            }else if(data?.available_sizes[0]){
                targetSizeId = data?.available_sizes[0]?.id
            }
            if(targetSizeId){
                const targetSize = data.available_sizes.find(el => {
                    return parseInt(el.id) === parseInt(targetSizeId)
                })
    
                if(targetSize){
                    matched = targetSize.price > 0 ? true : false
                    this.setState({
                        productPrice: targetSize.price
                    })

                    if(targetSize.available_colors){
                        const tagColors = targetSize.available_colors.map(el => {
                            return {
                                id: el.id,
                                name: el.name,
                                hexcode: el.hexcode
                            }
                        })
                        this.setState({
                            ...this.state,
                            defaultData: {
                                ...this.state.defaultData,
                                tagColors: tagColors
                            }
                        })
                    }
                }
            }
        }

        if(!matched){
            this.props.history.push('/catalog')
        }
    }

    fetchDetail = () => {
        const tagDefaultId = 2
        let tagColorSetOption = []
        CatalogAPI.detail(tagDefaultId).then(res => {
            if(res.status === 200){
                const { params } = this.props.match
                // handle check qstring
                const query = new URLSearchParams(this.props.location.search)
                const size = query.get('s')
                const color = query.get('c')
                const cartIndex = query.get('cart')
                const bundlings = query.get('bundling')
                if(res.data && res.data.data && res.data.data.available_sizes[0].available_colors){
                    tagColorSetOption = res.data.data.available_sizes[0].available_colors
                }
        
                this.toggleLoading(true)
                CatalogAPI.detail(params.id).then(res => {
                    let canPersonalize = []
                    let currentStep = ''
                    let bundlingSteps = []
        
                    let personalizeSuitcasePrice = 0
                    let personalizeSuitcaseTag = 0
                    if(res.status === 200){
                        const detailData = res.data.data
                        if(detailData.personalized){
                            if(detailData.personalized.suitcase){
                                personalizeSuitcasePrice = detailData.personalized.suitcase_price
                                canPersonalize.push('suitcase')
                                currentStep = 'suitcase' 
                            }
        
                            if(detailData.personalized.tag){
                                personalizeSuitcaseTag = detailData.personalized.tag_price
                                canPersonalize.push('tag')
                                if(!currentStep || currentStep.length === 0){
                                    currentStep = 'tag'
                                }
                            }
                        }
        
                        // validate is bundling personalize set bundling step
                        if(bundlings){
                            let bundlingSizes = [parseInt(size)]
                            const qstringBundlings = bundlings.split(',').map(el => {
                                return parseInt(el)
                            })
            
                            qstringBundlings.forEach(element => {
                                return bundlingSizes.push(element)
                            })
                            
                            if(bundlingSizes && detailData.available_sizes){
                                const targetBundling = detailData.available_sizes.filter(el => {
                                    return bundlingSizes.includes(el.id)
                                })
            
                                const bundlingData = targetBundling.map(el => {
                                    return {
                                        id: el.id,
                                        name: `${ detailData.name } ${ el.name }`,
                                        availableColors: el.available_colors
                                    }
                                })
            
                                let stepBundlingCount = 0
                                bundlingData.forEach(el => {
                                    stepBundlingCount++
                                    bundlingSteps.push({
                                        step: stepBundlingCount,
                                        sizeId: el.id,
                                        name: el.name,
                                        key: 'suitcase',
                                        value: '',
                                        position: 'left'
                                    })
            
                                    stepBundlingCount++
                                    bundlingSteps.push({
                                        step: stepBundlingCount,
                                        sizeId: el.id,
                                        name: el.name,
                                        key: 'tag',
                                        value: '',
                                        textColor: 'gold',
                                        tagColor: el.availableColors && el.availableColors[0] ? el.availableColors[0].id : '',
                                        availableTagColors: tagColorSetOption
                                    })
            
                                    return
                                })
                            }
                        }
                        
        
                        this.setProductPrice(detailData)
                        let objState = {
                            rawDetailData: detailData,
                            defaultStep: canPersonalize,
                            currentStep: currentStep,
                            suitcase: {
                                ...this.state.suitcase,
                                price: personalizeSuitcasePrice
                            },
                            tag: {
                                ...this.state.tag,
                                price: personalizeSuitcaseTag
                            }
                        }
        
                        if(bundlingSteps.length > 0){
                            objState = {
                                ...objState,
                                bundlingSteps: bundlingSteps
                            }
                        }
                        
                        this.setState(objState)
        
                        // target size
        
        
                        if(typeof cartIndex !== 'undefined'){
                            const { cartData } = this.props
                            this.setState({
                                syncCartIndex: cartIndex
                            })
        
                            if(cartData[cartIndex]){
                                const targetCartItem = cartData[cartIndex]
                                if(targetCartItem.personalized){
                                    if(targetCartItem.personalized.suitcase){
                                        this.setState({
                                            suitcase: targetCartItem.personalized.suitcase
                                        })
                                    }
        
                                    if(targetCartItem.personalized.tag){
                                        this.setState({
                                            tag: targetCartItem.personalized.tag
                                        })
                                    }
                                }
                            }
                        }
                    }
        
                    if(canPersonalize.length <= 0){
                        this.props.history.push('/catalog')
                    }else{
                        const { have_colors, have_sizes } = res.data.data
                        if((have_sizes && !size) || (have_colors && !color)){
                            this.props.history.push('/catalog')
                        }else{
                            this.toggleLoading(false)
                        }
                    }
                })
            }
        })
    }

    renderPersonalizeSection = () => {
        const { suitcase, tag, isLoading, currentStep, defaultStep, defaultData, rawDetailData, productPrice, syncCartIndex } = this.state
        if(isLoading){
            return(<LoaderPersonalize />)
        }

        if(currentStep === 'suitcase'){
            const suitcaseHandler = {
                onChangeMargin: this.onChangeMargin,
                handleChangeLetterSuitcase: this.handleChangeLetterSuitcase,
                toggleStep: this.toggleStep
            }
            return(
                <SuitCases 
                    { ...this.props }
                    currentStep={ currentStep }
                    defaultStep={ defaultStep }
                    defaultData={ defaultData }
                    detailProduct={ rawDetailData }
                    suitcase={ suitcase }
                    productPrice={ productPrice }
                    handler={ suitcaseHandler }
                    syncCartIndex={ syncCartIndex }
                />
            )
        }else if(currentStep === 'tag'){
            const tagHandler={
                handleChangeLetterTag: this.handleChangeLetterTag,
                handleChangeTextColorTag: this.handleChangeTextColorTag,
                handleChangeTagColor: this.handleChangeTagColor,
                toggleStep: this.toggleStep,
                updateTagColorSet: this.updateTagColorSet
            }
            return(
                <Tag 
                    { ...this.props }
                    currentStep={ currentStep }
                    defaultStep={ defaultStep }
                    defaultData={ defaultData }
                    detailProduct={ rawDetailData }
                    productPrice={ productPrice }
                    suitcase={ suitcase }
                    tag={ tag }
                    handler={ tagHandler }
                    syncCartIndex={ syncCartIndex }
                />
            )
        }

        return null
    }

    mutateBundlingStep = obj => {
        this.setState({
            bundlingSteps: obj
        })
    }

    toggleBundlingStep = number => {
        this.setState({
            currentBundlingStep: number
        })
    }

    renderPersonalizeBundlings = () => {
        const query = new URLSearchParams(this.props.location.search)
        const color = query.get('c')

        const { 
            isLoading,
            rawDetailData,
            bundlingSteps,
            currentBundlingStep,
            defaultData
        } = this.state

        if(isLoading){
            return(<LoaderPersonalize />)
        }

        return(
            <BundlingStepContainer
                history={ this.props.history }
                color={ color }
                steps={ bundlingSteps }
                currentStep={ parseInt(currentBundlingStep) }
                detailData={ rawDetailData }
                update={ this.mutateBundlingStep }
                toggleStep={ this.toggleBundlingStep }
                location={ this.props.location }
                tagColors={ defaultData.tagColors }
            />
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const { syncCartIndex } = this.state
        const { cartData } = nextProps
        const query = new URLSearchParams(nextProps.location.search)
        const nextCartIndex = query.get('cart')

        if(syncCartIndex !== nextCartIndex){
            this.setState({
                syncCartIndex: nextCartIndex
            })

            if(cartData[nextCartIndex] && cartData[nextCartIndex].personalized){
                const nextPersonalizeData = cartData[nextCartIndex].personalized
                if(nextPersonalizeData.suitcase){
                    this.setState({
                        suitcase: nextPersonalizeData.suitcase
                    })
                }

                if(nextPersonalizeData.tag){
                    this.setState({
                        tag: nextPersonalizeData.tag
                    })
                }
            }
        }
    }

    componentDidMount(){
        this.fetchDetail()
    }

    render() {
        const { bundlingSteps } = this.state
        return (
            <PageTemplate { ...this.props }>
                { bundlingSteps.length <= 0 ? this.renderPersonalizeSection() : this.renderPersonalizeBundlings() }
            </PageTemplate>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(Page) 