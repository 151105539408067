import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core'

class Confirmation extends Component {
    handleClose = () => {
        const { handler, config } = this.props
        if(config?.processing){
            return false
        }
        handler.close()
    }

    handleConfirm = () => {
        const { handler, config } = this.props
        if(config?.processing){
            return false
        }

        if(handler.confirm){
            handler.confirm()
        }else{
            handler.close()
        }

        return
    }

    render() {
        const { config } = this.props
        return (
            <Dialog
                PaperProps={{
                    classes: {
                        root: 'paper-dialog-conformation'
                    }
                }}
                open={ config.open }
                onClose={ this.handleClose }
                aria-labelledby='responsive-dialog-confirmation'
            >
                <DialogTitle id='responsive-dialog-confirmation'>
                    { config?.title }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { config?.text }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        !config.processing ? 
                        <>
                            <Button onClick={ this.handleClose } style={ {color: '#000'} }>
                                Cancel
                            </Button>
                            <Button onClick={ this.handleConfirm } style={ {color: 'red'} }>
                                Confirm
                            </Button>
                        </> :
                        <Button disabled style={ {color: '#000'} }>
                            Processing...
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

export default Confirmation;