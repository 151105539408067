import React from 'react'
import OrderDetailImage from 'Components/Image/OrderDetail'
import { stringToCurrency } from 'Services/Helpers/Main'

const OrderItems = (props) => {
    const { detailData } = props
    const items = detailData.order_items
    return (
        <div className="ordered-items-container">
            <h2>Items Ordered</h2>
            {
                items.map(
                    (el, index) => 
                    <div className="row item-list" key={ index }>
                        <div className="col-md-3 image-wrapper">
                            <OrderDetailImage src={ el.product_thumbnail_image_url } />
                        </div>
                        <div className="col-md-9 product-desc">
                            <div className="product-info">
                                <span><strong>{ `${ el.product_name }${ el.size_name ? ' ' + el.size_name : '' }` }</strong></span>
                                { el.color_name && <span>Color: { el.color_name }</span> }
                                { el.suitcase_text && <span>Suitcase Initials: { el.suitcase_text }</span> }
                                { el.tag_text && <span>Tag Initals: { el.tag_text }</span> }
                                <span><strong>QTY: 1</strong></span>
                            </div>
                            <div className="product-price">
                                { stringToCurrency(el.price) }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default OrderItems