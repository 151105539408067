import React, { Component } from 'react'
import { stringToCurrency } from 'Services/Helpers/Main'

class BundlingOptions extends Component {
    handleClickButton = opt => {
        const { detailConfig } = this.props
        const { setBundlings } = detailConfig
        return setBundlings(opt)
    }
    render() {
        const { detailConfig } = this.props
        const { selection, bundlings, bundlingOptions } = detailConfig

        if(!selection.size){
            return null
        }

        let bundlingSelectedText = '-'
        if(bundlings){
            bundlingSelectedText = bundlings.name
        }

        return (
            <>
                <div className="bundling-menu separate-section">
                    <p className="detail-info-selected">add bundling <strong>{ bundlingSelectedText }</strong></p>
                    <div className="bundling-opt">
                        {
                            bundlingOptions.map((el, index) => {
                                let isSelected = bundlings && bundlings.label === el.label ? true : false
                                let image = el.label
                                if(!['S', 'M', 'L'].includes(el.label)){
                                    image = 'combination'
                                }
                                return(
                                    <button 
                                        className={ `bundle-btn ${ isSelected ? 'selected' : '' }` } 
                                        key={ index }
                                        onClick={ () => this.handleClickButton(el) }
                                    >
                                        <div className="icon-bundling">
                                            <img src={ `/images/icon/suitcases/sizes/${ image }${ isSelected ? '-H' : '' }.png` } alt="icon"/>
                                            <span>{ el.label }</span>
                                        </div>
                                    </button>   
                                )
                            })
                        }
                    </div>
                </div>
                {
                    bundlings && bundlings.value ?
                    <p className="detail-info-selected bundling-info text-center">you will save <strong>{ stringToCurrency(bundlings.value) }</strong> with bundling discount</p> :
                    null
                }
            </>
        )
    }
}

export default BundlingOptions