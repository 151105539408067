import React, { Component } from 'react'
import Icon from 'Components/Icons/FontAwesome/Icon'

class SubMenu extends Component {
    constructor(){
        super()
        this.state = {
            openMenu: false
        }
    }

    toggleMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }
    render() {
        const { openMenu } = this.state
        const { name, children } = this.props
        let iconName = 'chevron-right'
        if(openMenu){
            iconName = 'chevron-down'
        }
        return (
            <>
               <div className="label" onClick={ this.toggleMenu }>
                    <span>{ name }</span>
                    <Icon name={ iconName } />
                </div>
                { openMenu && children }
            </>
        )
    }
}

export default SubMenu