import { createStore  } from 'redux'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import RootReducers from './Reducers/Root'

const config = {
    key: 'primary',
    storage,
    whitelist: [ 'AuthReducer', 'CartReducer' ]
}

let persistedReducer = persistCombineReducers(
    config,
    RootReducers
)

export const store = createStore(persistedReducer)
export const persistor = persistStore(store)