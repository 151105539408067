import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import Info from './Section/Information/Info'
import ImageContainer from './Section/Gallery/ImageContainer'
import Review from './Section/Review'
import CatalogAPI from 'Services/API/Guest/CatalogAPI'
import { getPaginationFromHeader } from 'Services/Helpers/Main'

class DetailProduct extends Component {
    constructor(props){
        super(props)
        const { location } = props
        const query = new URLSearchParams(location.search)
        const sizeId = query.get('s')
        const colorId = query.get('c')

        this.state = {
            detailData: null,
            isLoading: true,
            selection: {
                color: colorId ? parseInt(colorId) : null,
                size: sizeId ? parseInt(sizeId) : null,
            },
            reviews: {
                data: [],
                page: 0,
                lastPage: 0,
                total: 0,
                isLoadingReview: true
            },
            bundlings: null,
            bundlingOptions: null
        }
    }

    setSelection = (obj) => {
        this.setState({
            selection: obj,
            bundlings: null
        })
    }

    setBundlings = (obj) => {
        this.setState({
            bundlings: obj
        })
    }

    toggleLoading = flag => {
        this.setState({
            isLoading: flag ? true : false
        })
    }

    fetchReview = (id, setPage) => {
        this.setState({
            reviews: {
                ...this.state.reviews,
                isLoadingReview: true
            }
        })
        CatalogAPI.getReviews(id, setPage).then(res => {
            if(res.status === 200){
                let currentReview = this.state.reviews.data
                const reviewList = res.data.data
                const header = res.headers
                const { page, lastPage, total } = getPaginationFromHeader(header)
                let newReviewList = null
                if(reviewList){
                    newReviewList = currentReview.concat(reviewList)
                }

                if(newReviewList){
                    this.setState({
                        reviews: {
                            ...this.state.reviews,
                            data: newReviewList,
                            page: page,
                            lastPage: lastPage,
                            total: total
                        }
                    })
                }
            }

            this.setState({
                reviews: {
                    ...this.state.reviews,
                    isLoadingReview: false
                }
            })
        })
    }

    fetchDetail = () => {
        const { params } = this.props.match
        const { selection } = this.state

        this.toggleLoading(true)
        CatalogAPI.detail(params.id).then(res => {
            if(res.status === 200){
                const detailData = res.data.data
                this.setState({
                    detailData: detailData
                })

                const { reviews } = this.state
                const page = reviews.page + 1
                this.fetchReview(params.id, page)

                // fetch bundling promos
                if(selection && selection.size){
                    CatalogAPI.getBundlingPromo(params.id, selection.size, selection.color).then(res => {
                        if(res.status === 200){
                            this.setState({
                                bundlingOptions: res.data.data
                            })
                        }
                        this.toggleLoading(false)
                    })
                }else{
                    this.toggleLoading(false)
                }
            }else{
                this.toggleLoading(false)
            }
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.location.search !== nextProps.location.search){
            const { params } = nextProps.match
            const { location } = nextProps
            const query = new URLSearchParams(location.search)
            const sizeId = parseInt(query.get('s'))
            const colorId = parseInt(query.get('c'))
            if(sizeId && colorId){
                CatalogAPI.getBundlingPromo(params.id, sizeId, colorId).then(res => {
                    if(res.status === 200){
                        this.setState({
                            bundlingOptions: res.data.data
                        })
                    }
                })
            }
        }
    }

    loadMoreReview = () => {
        const { page } = this.state.reviews
        const newPage = page + 1
        const { params } = this.props.match
        this.fetchReview(params.id, newPage)
    }

    componentDidMount(){
        this.fetchDetail()
    }

    render() {
        const { isLoading, detailData, selection, bundlings, bundlingOptions, reviews } = this.state
        const detailConfig = {
            isLoading: isLoading,
            detailData: detailData,
            selection: selection,
            bundlings: bundlings,
            bundlingOptions: bundlingOptions,
            reviews: reviews,
            setSelection: this.setSelection,
            setBundlings: this.setBundlings,
            loadMoreReview: this.loadMoreReview
        }
        
        return (
            <PageTemplate { ...this.props }>
                <div className="product-detail wrapper-white">
                    <div className="container">
                        <div className="row">
                            <ImageContainer 
                                { ...this.props } 
                                detailConfig={ detailConfig }
                            />
                            <Info 
                                { ...this.props }
                                detailConfig={ detailConfig }
                            />
                        </div>
                    </div>
                </div>
                <Review 
                    { ...this.props }
                    detailConfig={ detailConfig }
                />
            </PageTemplate>
        )
    }
}

export default DetailProduct