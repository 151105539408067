import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import DefaultForm from 'Components/Form/Default'
import FormButton from 'Components/Form/Buttons/Default'
import { NavLink } from 'react-router-dom'
import AuthApi from 'Services/API/Guest/AuthAPI'
import { handleToast } from 'Services/Toastify'
import { connect } from 'react-redux'
import { authMapStateToProps, authMapDispatchToProps } from 'Constants/Redux/Auth'

class SignInForm extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    handleSubmit = (formData) => {
        this.toggleProcess(true)
        
        AuthApi.login(formData?.value).then(response => {
            this.toggleProcess(false)
            let type = 'warning'
            if(response.status === 200){
                type = 'success'
                const authObj = response.data?.data
                this.props.onSuccessLogin(authObj)
                this.props.history.push('/')
            }
            handleToast(response.data?.message, type)
        })
    }

    render() {
        return (
            <PageTemplate { ...this.props }>
                <div className="container">
                    <div className="auth-content">
                        <h3>Sign In</h3>
                        <DefaultForm 
                            { ...this.props }
                            className={ `auth-form` }
                            isProcessing={ this.state.isProcessing }
                            handleSubmit={ this.handleSubmit }
                            formInputs={ 
                                [
                                    {
                                        name: 'email',
                                        type: 'TextInput',
                                        defaultValue: '',
                                        validator: 'required,email',
                                        meta: {
                                            label: {
                                                text: 'Email',
                                                show: false
                                            },
                                            placeHolder: 'Email Address',
                                            autoFocus: true
                                        }
                                    },
                                    {
                                        name: 'password',
                                        type: 'TextInputPassword',
                                        defaultValue: '',
                                        validator: 'required',
                                        meta: {
                                            label: {
                                                text: 'Password',
                                                show: false
                                            },
                                            placeHolder: 'Password'
                                        }
                                    }
                                ]
                            }
                            actionComponent={ props => {
                                return(
                                    <div className="form-group form-action-d-flex mb-3">
                                        <FormButton 
                                            className="btn submit-btn"
                                            label={ `Sign In` }
                                            type={ `submit` }
                                            isProcessing={ this.state.isProcessing }
                                            disabled={ props.invalid } 
                                        />
                                    </div>
                                )
                            }}
                        />
                        <NavLink to={ '/auth/forgot-password' } className="link-outside">
                            <span>Forgot Password</span>
                        </NavLink>
                        <NavLink to={ '/auth/register' } className="link-outside has-border">
                            <span>Don't have an account?</span>
                        </NavLink>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default connect(authMapStateToProps, authMapDispatchToProps)(SignInForm) 