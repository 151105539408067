import {
    Validators
} from 'react-reactive-form'

const translateValidator = (validatorType) => {
    let obj
    switch(validatorType){
        case 'required' : 
            obj =  Validators.required
            break
        case 'email' :
            obj = Validators.email
            break
        default:
            obj =  null
    }

    return obj
} 

export const generateValidators = (rulesString) => {
    let validators = []
    if(rulesString && rulesString.split(',').length > 0){
        const objValidators = rulesString.split(',')
        objValidators.forEach(el => {
            return validators.push(translateValidator(el))
        })
    }

    return validators
}