import React from 'react'

const EmailSection = () => {
    return (
        <div className="section">
            <div className="container">
                <div className="col-md-12 info-area">
                    <h5>can't find the answer?</h5>
                    <h3>Send us an email</h3>
                    <a href="mailto: admin@baller.co.id">email us</a>
                </div>
            </div>
        </div>
    )
}

export default EmailSection