import React, { Component } from 'react'
import Icon from 'Components/Icons/Svg/Icon'
import FormLogin from './FormLogin'
import { getSocialAuthEndpoint } from 'Services/Helpers/Main'

class LoginCheckout extends Component {
    constructor(){
        super()
        this.state = {
            viewFormLogin: false
        }
    }

    toggleFormLogin = flag => {
        this.setState({
            viewFormLogin: flag ? true : false
        })
    }

    handleClickSocial = (type) => {
        localStorage.setItem('after_auth_redirect', '/checkout/shipment')
        return window.location.replace(getSocialAuthEndpoint(type))
    }

    render() {
        const { viewFormLogin } = this.state

        if(viewFormLogin){
            return(<FormLogin { ...this.props } goBack={ () => this.toggleFormLogin(false)}/>)
        }

        return (
            <div className="auth-content">
                <h3>Already have account?</h3>
                <button className="button-link email" onClick={ this.toggleFormLogin } >
                    <div>
                        <Icon name="email"></Icon>
                        <span>Sign in with email</span>
                    </div>
                </button>
                <button type="button" className="google" onClick={ () => this.handleClickSocial('google') }>
                    <div>
                        <Icon name="google"></Icon>
                        <span>Sign in with google</span>
                    </div>
                </button>
                <button type="button" className="facebook" onClick={ () => this.handleClickSocial('facebook') }>
                    <div>
                        <Icon name="fb-square"></Icon>
                        <span>Sign in with facebook</span>
                    </div>
                </button>
            </div>
        )
    }
}

export default LoginCheckout