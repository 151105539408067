import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { authMapStateToProps, authMapDispatchToProps } from 'Constants/Redux/Auth'
import PageTemplate from 'Components/Page/Template'
import Icon from 'Components/Icons/Svg/Icon'
import { getSocialAuthEndpoint } from 'Services/Helpers/Main'

class SignIn extends Component {
    handleClickSocial = (type) => {
        return window.location.replace(getSocialAuthEndpoint(type))
    }
    render() {
        return (
            <PageTemplate { ...this.props }>
                <div className="container">
                    <div className="auth-content">
                        <h3>Sign In</h3>
                        <NavLink to={ `/auth/sign-in/email` } className="button-link email">
                            <div>
                                <Icon name="email"></Icon>
                                <span>Sign in with email</span>
                            </div>
                        </NavLink>
                        <button type="button" className="google" onClick={ () => this.handleClickSocial('google') }>
                            <div>
                                <Icon name="google"></Icon>
                                <span>Sign in with google</span>
                            </div>
                        </button>
                        <button type="button" className="facebook" onClick={ () => this.handleClickSocial('facebook') }>
                            <div>
                                <Icon name="fb-square"></Icon>
                                <span>Sign in with facebook</span>
                            </div>
                        </button>
                        <NavLink to={ '/auth/register' } className="link-outside has-border">
                            <span>Don't have an account?</span>
                        </NavLink>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default connect(authMapStateToProps, authMapDispatchToProps)(SignIn) 