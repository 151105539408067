import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class AddToCartDetail extends Component {
    onClick = () => {
        // sync cart
        const { history, onSyncCart, onToggleCartSlider, getItemData } = this.props

        // open cart slider & redirect to catalog on sync
        const cartObj = getItemData()

        onSyncCart(cartObj)
        history.push('/catalog')
        onToggleCartSlider(true)
        return false
    }

    render() {
        const { children, disabled, className } = this.props
        return (
            <button className={ className } type="button" disabled={ disabled } onClick={ this.onClick }>
                { children }
            </button>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(AddToCartDetail) 