import React, { Component } from 'react'

class DefaultButton extends Component {
    render() {
        const { type, className, isProcessing, disabled, label, onClick } = this.props
        return (
            <button 
                type={ type ? type : 'button' }
                className={ className }
                disabled={ disabled || isProcessing }
                onClick={ onClick }
            >
                { !isProcessing ? label : 'Loading...' }
            </button>
        )
    }
}

export default DefaultButton