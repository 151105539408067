import React, { Component } from 'react'
import AccountTemplate from '../../Section/AccountTemplate'
import { NavLink } from 'react-router-dom'
import Icon from 'Components/Icons/FontAwesome/Icon'
import Loader from 'Components/Loader/Skeleton'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import { getPaginationFromHeader } from 'Services/Helpers/Main'
import SimplePaginator from 'Components/Paginator/Simple'
import ConfirmationDialog from 'Components/Dialogs/Confirmation'
import { handleToast } from 'Services/Toastify'

class AccountAddress extends Component {
    constructor(){
        super()
        this.state = {
            listAddressConfig: {
                isLoading: true,
                data: [],
                page: 0,
                lastPage: 0,
                total: 0
            },
            dialogDelete: {
                open: false,
                data: null,
                processing: false
            }
        }
    }

    showLoading = () => {
        return(
            <>
                <Loader count={ 1 } height={ 35 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
                <Loader count={ 3 } height={ 120 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
            </>
        )
    }

    fetchList = (obj = null) => {
        this.setState({
            listAddressConfig:{
                ...this.state.listAddressConfig,
                isLoading: true
            }
        })
        const page = obj?.page ? obj.page : 1
        AccountAPI.getAddressList(page).then(res => {
            if(res.status === 200){
                const listData = res.data.data
                const header = res.headers
                const { page, lastPage, total } = getPaginationFromHeader(header)

                this.setState({
                    listAddressConfig:{
                        ...this.state.listAddressConfig,
                        data: listData,
                        page: page,
                        lastPage: lastPage,
                        total: total
                    }
                })
            }

            this.setState({
                listAddressConfig:{
                    ...this.state.listAddressConfig,
                    isLoading: false
                }
            })
        })
    }

    handleChangePage = (page) => {
        this.fetchList({page: page})
    }
    
    generatePaginator = () => {
        const { listAddressConfig } = this.state
        const { page, lastPage, total } = listAddressConfig
        const paginationObj = {
            page: page,
            lastPage: lastPage,
            total: total
        }

        return(<SimplePaginator config={ paginationObj } handleChangePage={ this.handleChangePage }/>)
    }

    getFullAddress = (el) => {
        let address = el.address
        if(el.province_name){
            address = `${ address }, ${ el.province_name }`
        }
        if(el.city_name){
            address = `${ address }, ${ el.city_name }`
        }

        if(el.zip_code){
            address = `${ address }, ${ el.zip_code }`
        }

        return address
    }

    handleOpenDelete = (el) => {
        this.setState({
            dialogDelete: {
                ...this.state.dialogDelete,
                open: true,
                data: el
            }
        })
    }

    handleCloseDelete = () => {
        this.setState({
            dialogDelete: {
                open: false,
                data: null,
                processing: false
            }
        })
    }

    toggleProcessingDialogDelete = flag => {
        this.setState({
            dialogDelete: {
                ...this.state.dialogDelete,
                processing: flag ? true : false
            }
        })
    }

    handleConfirmDelete = () => {
        this.toggleProcessingDialogDelete(true)
        const data = this.state.dialogDelete.data
        AccountAPI.deleteAddress(data?.id).then(res => {
            let resultType = 'warning'
            let message = 'Oops failed to make request, please try again'
            if(res.status === 200){
                this.handleCloseDelete()
                this.refreshList()
                resultType = 'success'
                message = 'Successfully delete address'
            }

            handleToast(message, resultType)
        })
    }

    refreshList = () => {
        const { listAddressConfig } = this.state
        const page = listAddressConfig.page
        return this.fetchList({page: page})
    }

    componentDidMount(){
        this.fetchList()
    }

    render() {
        const { listAddressConfig, dialogDelete } = this.state
        const { isLoading, data } = listAddressConfig
        return (
            <AccountTemplate title="My Address" { ...this.props }>
                {
                    isLoading ? 
                    this.showLoading() :
                    <div className="section-account">
                        <h5 className="form-title">
                            <span>Saved Addresses</span>
                            <NavLink className="button-link float-right" to={ `/account/address/add` }>+ Add new Address</NavLink>
                        </h5>
                        <div className="container-address">
                            {
                                data.length === 0 &&
                                <div className="row address-list">
                                    <div className="col-md-12 text-center"><span>No Data</span></div>
                                </div>
                            }
                            {
                                data.map((el, index) => {
                                    const fullAddress = this.getFullAddress(el)

                                    return(
                                        <div className="row address-list" key={ index }>
                                            <div className={ `col-md-8 address-wrap ${ el.is_primary ? 'primary-address' : '' }` }>
                                                <span className="bolder">{ el.receiver_name ? el.receiver_name : '-' }</span>
                                                <span className="bolder">{ el.receiver_number }</span>
                                                <span>{ fullAddress }</span>
                                                <span className="remarks">Remarks: { el.remarks }</span>
                                            </div>
                                            <div className="col-md-4 address-action">
                                                <NavLink to={ `/account/address/${ el.id }` }>
                                                    <span className="icon"><Icon type="far" name="edit" /></span>
                                                    <span>Edit</span>
                                                </NavLink>
                                                <button type="button" onClick={ () => this.handleOpenDelete(el) }>
                                                    <span className="icon"><Icon type="far" name="trash-alt" /></span>
                                                    <span>Delete</span>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        { this.generatePaginator() }
                    </div>
                }
                <ConfirmationDialog 
                    config={ {
                        title: 'Confirmation',
                        text: 'You are about to delete one of your address, are you sure?',
                        open: dialogDelete?.open,
                        processing: dialogDelete?.processing
                    } }
                    handler={ {
                        close: this.handleCloseDelete,
                        confirm: this.handleConfirmDelete
                    } }
                />
            </AccountTemplate>
        )
    }
}

export default AccountAddress