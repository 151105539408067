import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from 'Components/Icons/FontAwesome/Icon'
import UserItems from './UserItems'
import { stringToCurrency } from 'Services/Helpers/Main'
import CatalogApi from 'Services/API/Guest/CatalogAPI'

class Cart extends Component {
    constructor(){
        super()
        this.state = {
            loading: true,
            bundlingDiscount: 0
        }
    }

    toggleLoading = flag => {
        this.setState({
            loading: flag
        })
    }

    calculateSubtotal = arr => {
        let total = 0
        if(arr.length > 0){
            const calculatedArray = arr.map(el => {
                let fee = 0
                if(el.personalized){
                    fee = el.personalized.suitcase.price + el.personalized.tag.price
                }
                return {
                    ...el,
                    personalizedFee: fee
                }
            })
            total = calculatedArray.reduce((sum, { price, quantity, personalizedFee }) => sum + ((price + personalizedFee) * quantity), 0)
        }

        return total
    }

    componentDidMount(){
        const { cartData } = this.props
        if(cartData && cartData.length > 0){
            this.toggleLoading(true)

            // get discount data by cart items
            const bundlingDiscountItemCheck = cartData.map(el => {
                return {
                    product_id: el.id,
                    size_id: el.sizeId
                }
            })

            CatalogApi.claimBundlingPromo({
                items: bundlingDiscountItemCheck
            }).then(res => {
                if(res.data.data){
                    this.setState({
                        bundlingDiscount: res.data.data
                    })
                }
                this.toggleLoading(false)
            })
        }else{
            this.toggleLoading(false)
        }
    }

    render() {
        const { loading, bundlingDiscount } = this.state

        if(loading){
            return(null)
        }
        const { cartData, shipmentInfo } = this.props
        const subtotalRaw = this.calculateSubtotal(cartData)
        
        let shipmentFee = null
        let grandTotal = null
        if(shipmentInfo){
            shipmentFee = shipmentInfo.price
        }

        grandTotal = subtotalRaw + shipmentFee
        if(bundlingDiscount){
            grandTotal = grandTotal - bundlingDiscount
        }

        return (
            <div className="cart-info order-md-2 order-sm-1">
                <div className="info-content">
                    <h3>
                        <NavLink to={ '/catalog' } className="link-back-mobile">
                            <Icon name={ 'chevron-left' }/>
                        </NavLink>
                        <span>Order Summary</span>
                    </h3>
                    <UserItems cartData={ cartData }/>
                    <div className="price-summary">
                        <div className="row-info">
                            <span className="label">subtotal</span>
                            <span className="value">{ stringToCurrency(subtotalRaw) }</span>
                        </div>
                        <div className="row-info">
                            <span className="label">bundling discount</span>
                            <span className="value discount">- { stringToCurrency(bundlingDiscount ? bundlingDiscount : 0) }</span>
                        </div>
                        <div className="row-info">
                            <span className="label">shipping</span>
                            <span className="value">{ shipmentFee ? stringToCurrency(shipmentFee) : 'TBA' }</span>
                        </div>
                        <div className="row-info grand-total">
                            <span className="label">Total Amount</span>
                            <span className="value">{ grandTotal ? stringToCurrency(grandTotal) : 'TBA' }</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cart