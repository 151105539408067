import React, { Component } from 'react'
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators
} from 'react-reactive-form'
import { handleToast } from 'Services/Toastify'
import TextInput from 'Components/Form/DynamicInputs/TextInput'
import NumericInput from 'Components/Form/DynamicInputs/NumericInput'
import AutoCompleteInput from 'Components/Form/DynamicInputs/AutoCompleteInput'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import ShipOptions from './ShipOptions'
import FormButton from 'Components/Form/Buttons/Default'
import PaymentAPI from 'Services/API/Guest/PaymentAPI'

class GuestUserInformation extends Component {
    constructor(){
        super()
        this.state = {
            formData: FormBuilder.group({
                name: [ '' , Validators.required ],
                email: [ '', [Validators.required, Validators.email] ],
                mobile: [ '' , Validators.required ],
                receiverName: [ '' , Validators.required ],
                phoneNumber: [ '', Validators.required ],
                address: [ '', Validators.required ],
                province: [ null, Validators.required],
                city: [ '', Validators.required],
                zipCode:  [ '', Validators.required],
                selectedShipping: [ null, Validators.required]
            }),
            processingPayment: false,
            openForm: false,
            provinceOpt: [],
            cityOpt: []
        }
    }

    loadProvince = () => {
        AccountAPI.getProvinceOpt().then(res => {
            if(res.status === 200){
                let data = res.data.data
                data = data.map(el => {
                    return {
                        value: el.id,
                        label: el.name
                    }
                })

                this.setState({
                    provinceOpt: data
                })
            }
        })
    }

    callbackFillCity = (selectedObj) => {
        const { formData } = this.state
        const provinceId = selectedObj.value
        const cityElement = formData.get('city')
        cityElement.setValue(null)

        AccountAPI.getCitiesOpt(provinceId).then(res => {
            if(res.status === 200){
                let data = res.data.data
                data = data.map(el => {
                    return {
                        value: el.id,
                        label: el.name
                    }
                })

                this.setState({
                    cityOpt: data
                })
            }
        })
    }

    handleSubmitGuestOrder = e => {
        e.preventDefault()
        this.toggleProcessPayment(true)
        const { cartData } = this.props
        const { formData } = this.state

        const formValue = formData.value
        
        let objPurchase = {
            contact_name: formValue.name,
            contact_number: formValue.mobile,
            contact_email: formValue.email,
            address: formValue.address,
            province_id: formValue.province.value,
            province: formValue.province.label,
            city_id: formValue.city.value,
            city: formValue.city.label,
            latitude: null,
            longitude: null,
            zip_code: formValue.zipCode,
            remarks: 'Guest Checkout',
            shipping_code: formValue.selectedShipping.code,
            shipping_service: formValue.selectedShipping.service,
            items: []
        }


        const mappedCartItems = cartData.map(el => {
            // suitcase
            let suitcaseTextLabel = el.personalized && el.personalized.suitcase ? el.personalized.suitcase.letter : null
            let suitcaseTextPosition = null
            if(suitcaseTextLabel !== null){
                suitcaseTextPosition = el.personalized && el.personalized.suitcase ? el.personalized.suitcase.selectedMargin : null
            }

            // tag
            let tagTextLabel = el.personalized && el.personalized.tag ? el.personalized.tag.letter : null
            let tagTextProductId = null
            let tagTextSizeId = null
            let tagTextColorId = null
            if(tagTextLabel !== null){
                tagTextProductId = 2
                tagTextSizeId = 1
                tagTextColorId = el.personalized && el.personalized.tag ? el.personalized.tag.tagColor : null
            }
            
            return {
                product_id: el.id,
                size_id: el.sizeId,
                color_id: el.colorId,
                suitcase_text: suitcaseTextLabel,
                suitcase_text_position: suitcaseTextPosition,
                tag_text: tagTextLabel,
                tag_text_product_id: tagTextProductId,
                tag_text_size_id: tagTextSizeId,
                tag_text_color_id: tagTextColorId
            }
        })

        objPurchase = {
            ...objPurchase,
            items: mappedCartItems
        }

        PaymentAPI.checkout(objPurchase).then(res => {
            this.toggleProcessPayment(false)
            if(res.status === 200){
                this.props.onSyncCart([])
                window.location.replace(res.data.data.payment_url)
            }else{
                handleToast(res.data.message, 'warning')
            }
        }).catch(err => {
            return handleToast('Failed to create a new order', 'warning')
        })
    }

    toggleProcessPayment = flag => {
        this.setState({
            processingPayment: flag ? true: false
        })
    }

    componentDidMount(){
        this.loadProvince()
    }

    render() {
        const { formData, processingPayment } = this.state
        const { isProcessing, cartData, setShipping } = this.props

        return (
            <div className="guest-checkout-card">
                <div className="info-card">
                    <h3>Contact Information</h3>
                    <div className="form-guess-checkout-wrapper">
                        <FieldGroup
                            control={ formData }
                            strict={ false }
                            render={
                                props => {
                                    const { province, city } = props.controls
                                    const selectedAddress = city.value && city.value.value ? {
                                        city_id: city.value.value
                                    } : null

                                    const selectedService = formData.controls.selectedShipping.value
                                    const setService = obj => {
                                        setShipping(obj)
                                        return formData.controls.selectedShipping.setValue(obj)
                                    }

                                    return(
                                        <form className="form-guess-checkout account-form" onSubmit={ this.handleSubmitGuestOrder }>
                                            <div className="guest-form-row">
                                                <FieldControl
                                                    name='name'
                                                    render={ TextInput }
                                                    meta={{ 
                                                        label: {
                                                            text: 'Full Name',
                                                            show: false
                                                        }, 
                                                        placeHolder: 'Full Name'
                                                    }}
                                                />
                                                <FieldControl
                                                    name='email'
                                                    render={ TextInput }
                                                    meta={{ 
                                                        label: {
                                                            text: 'Email',
                                                            show: false
                                                        }, 
                                                        placeHolder: 'Email'
                                                    }}
                                                />
                                                <FieldControl
                                                    name='mobile'
                                                    render={ TextInput }
                                                    meta={{ 
                                                        label: {
                                                            text: 'Phone No',
                                                            show: false
                                                        }, 
                                                        placeHolder: 'Phone No'
                                                    }}
                                                />
                                            </div>
                                            <div className="guest-form-row">
                                                <h3>Shipping Address</h3>
                                                    <FieldControl
                                                        name='address'
                                                        render={ TextInput }
                                                        meta={{ 
                                                            label: {
                                                                text: 'Address',
                                                                show: false,
                                                            }, 
                                                            placeHolder: 'Address'
                                                        }}
                                                    />
                                                    <FieldControl
                                                        name='province'
                                                        strict={ false }
                                                        render={ props => {
                                                            return(
                                                                <AutoCompleteInput 
                                                                    {...props} 
                                                                    options={ this.state.provinceOpt }
                                                                    callBack={ this.callbackFillCity }
                                                                />)
                                                        } }
                                                        meta={{ 
                                                            label: {
                                                                text: 'Province',
                                                                show: false,
                                                            },
                                                            placeHolder: 'Select Province'
                                                        }}
                                                    />
                                                    <FieldControl
                                                        name='city'
                                                        strict={ false }
                                                        render={ props => {
                                                            return(
                                                                <AutoCompleteInput 
                                                                    {...props} 
                                                                    options={ this.state.cityOpt }
                                                                    disabled={ province.value === null ? true : false }
                                                                />)
                                                        } }
                                                        meta={{ 
                                                            label: {
                                                                text: 'City',
                                                                show: false,
                                                            },
                                                            placeHolder: 'Select City'
                                                        }}
                                                    />
                                                    <FieldControl
                                                        name='zipCode'
                                                        render={ NumericInput }
                                                        meta={{ 
                                                            label: {
                                                                text: 'Zip Code',
                                                                show: false,
                                                            }, 
                                                            maxLength: 20,
                                                            placeHolder: 'Zip Code'
                                                        }}
                                                    />
                                                    <FieldControl
                                                        name='receiverName'
                                                        render={ TextInput }
                                                        meta={{ 
                                                            label: {
                                                                text: 'Receiver name',
                                                                show: false
                                                            }, 
                                                            placeHolder: 'Receiver Name'
                                                        }}
                                                    />
                                                    <FieldControl
                                                        name='phoneNumber'
                                                        render={ NumericInput }
                                                        meta={{ 
                                                            label: {
                                                                text: 'Receiver Phone Number',
                                                                show: false,
                                                            }, 
                                                            maxLength: 20,
                                                            placeHolder: 'Receiver Phone Number'
                                                        }}
                                                    />
                                            </div>
                                            <ShipOptions
                                                cartData={ cartData }
                                                selectedAddress={ selectedAddress }
                                                setShipmentInfo={ setService }
                                                shipmentInfo={ selectedService }
                                            />
                                            <div style={{ marginTop: '30px' }} >
                                                <FormButton 
                                                    className="action invert"
                                                    label={ !processingPayment ? `Create Order` : `Processing...` }
                                                    type={ `submit` }
                                                    isProcessing={ isProcessing }
                                                    disabled={ props.invalid || processingPayment }
                                                    onClick={ this.handleSubmitGuestOrder }
                                                />
                                            </div>
                                        </form>
                                    )
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default GuestUserInformation