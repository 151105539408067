import React, { Component } from 'react'
import { stringToCurrency } from 'Services/Helpers/Main'

class PriceInfo extends Component {
    calculateSubtotal = arr => {
        let total = 0
        if(arr.length > 0){
            const calculatedArray = arr.map(el => {
                let fee = 0
                const suitcasePrice = el.personalized && el.personalized.suitcase && el.personalized.suitcase.price ? el.personalized.suitcase.price : 0
                const tagPrice = el.personalized && el.personalized.tag && el.personalized.tag.price ? el.personalized.tag.price : 0
                if(el.personalized){
                    fee = suitcasePrice + tagPrice
                }
                return {
                    ...el,
                    personalizedFee: fee
                }
            })
            total = calculatedArray.reduce((sum, { price, quantity, personalizedFee }) => sum + ((price + personalizedFee) * quantity), 0)
        }
    
        return total
    }

    calculateGrandTotal = arr => {
        const { bundlingDiscount } = this.props
        let total = 0
        if(arr.length > 0){
            const calculatedArray = arr.map(el => {
                let fee = 0
                if(el.personalized){
                    const suitcasePrice = el.personalized && el.personalized.suitcase && el.personalized.suitcase.price ? el.personalized.suitcase.price : 0
                    const tagPrice = el.personalized && el.personalized.tag && el.personalized.tag.price ? el.personalized.tag.price : 0
                    fee = suitcasePrice + tagPrice
                }
                return {
                    ...el,
                    personalizedFee: fee
                }
            })
            total = calculatedArray.reduce((sum, { price, quantity, personalizedFee }) => sum + ((price + personalizedFee) * quantity), 0)
        }

        if(bundlingDiscount){
            total = total - parseInt(bundlingDiscount)
        }
    
        return total
    }

    render() {
        const { items, bundlingDiscount } = this.props
        const cartItems = items
        return (
            <div className="subtotal-information">
                <div className="row-info">
                    <span className="label">subtotal</span>
                    <span className="value">{ stringToCurrency(this.calculateSubtotal(cartItems)) }</span>
                </div>
                <div className="row-info">
                    <span className="label">bundling discount</span>
                    <span className="value discount">-{ stringToCurrency(bundlingDiscount) }</span>
                </div>
                <div className="row-info bold">
                    <span className="label">total price</span>
                    <span className="value">{ stringToCurrency(this.calculateGrandTotal(cartItems)) }</span>
                </div>
            </div>
        )
    }
}

export default PriceInfo