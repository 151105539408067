import rSlugify from 'react-slugify'
import { isValid, format } from 'date-fns'

export const defaultCurrency = 'Rp'

export const getPaginationFromHeader = header => {
    const page = header['page'] ? header['page'] : 1 
    const lastPage = header['last-page'] ? header['last-page'] : 1
    const total = header['total'] ? header['total'] : 0
    
    return {
        page: parseInt(page),
        lastPage: parseInt(lastPage),
        total: parseInt(total)
    }
}

export const dateObjToString = (cellData, formatStyle = 'dd MMM yyyy, HH:mm') => {
    const isValidDate = isValid(new Date(cellData))
    if(isValidDate){
        return format(new Date(cellData), formatStyle)
    }else{
        return null
    }
}

export const thousandSeparator = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const stringToCurrency = x => {
    return `${ defaultCurrency } ${ thousandSeparator(x) }`
}

export const slugify = text => {
    return rSlugify(text)
}

export const getDayTimeText = () => {
    const today = new Date()
    const curHr = today.getHours()

    if (curHr < 12) {
        return 'Morning'
    } else if (curHr < 18) {
        return 'Afternoon'
    }

    return 'Evening'
}

export const getSocialAuthEndpoint = type => {
    if(type === 'facebook'){
        return process.env.REACT_APP_FB_AUTH_ENDPOINT
    }else if(type === 'google'){
        return process.env.REACT_APP_GOOGLE_AUTH_ENDPOINT
    }

    return null
}

export const mapCategoryIdWithText = label => {
    switch(label) {
        case 'suitcases':
            return 1
        case 'luggage-tags':
            return 2
        case 'stickers' :
            return 3
        default:
          return null
    }
}

export const getTagProductId = () => {
    return 2
}

export const getImageOrientation = src => {
    var orientation
    var img = new Image();
    img.src = src;

    if (img.naturalWidth > img.naturalHeight) {
        orientation = 'landscape';
    } else if (img.naturalWidth < img.naturalHeight) {
        orientation = 'portrait';
    } else {
        orientation = 'even';
    }

    return orientation;
}