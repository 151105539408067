import React, { Component } from 'react'
import { handleToast } from 'Services/Toastify'
import { connect } from 'react-redux'
import { authMapStateToProps, authMapDispatchToProps } from 'Constants/Redux/Auth'

class Logout extends Component {
    componentDidMount(){
        this.props.onSuccessLogout()
        this.props.history.push('/')
        handleToast('Your Session has ended.', 'warning')
    }
    render() {
        return (
            <p>Refreshing Page ...</p>
        )
    }
}

export default connect(authMapStateToProps, authMapDispatchToProps)(Logout) 