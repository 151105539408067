import React, { Component } from 'react'
import SlidingPane from 'react-sliding-pane'
import { NavLink } from 'react-router-dom'
import SubMenu from './SubMenu'
import { handleToast } from 'Services/Toastify'

class MobileSideBar extends Component {
    closeSideBar = () => {
        const { sideBarHandler } = this.props
        sideBarHandler.toggleSideBar(false)
    }

    handleLogout = () => {
        handleToast('Successfully Log Out')
        this.props.onSuccessLogout()
        this.closeSideBar()
    }


    render() {
        const { sideBarHandler, currentUser } = this.props
        return (
            <SlidingPane
                className="sidebar-mobile-menu"
                from="left"
                overlayClassName="sidebar-mobile-menu-overlay"
                isOpen={ sideBarHandler?.open }
                hideHeader={ true }
                width={ '250px' }
                onRequestClose={ this.closeSideBar }
            >
                <ul className="mobile-menu">
                    <li>
                        <button className="close-menu" type="button" onClick={ this.closeSideBar }>
                            <img src="/images/icon/close.svg" alt="close" />
                        </button>
                    </li>
                    <li>
                        {
                            !currentUser ? 
                            <NavLink to={ '/auth/sign-in' }>
                                <img src="/images/icon/user.svg" alt="user-sidebar"/>
                                <span>Sign In</span>
                            </NavLink> :
                            <SubMenu name="Account">
                                <ul className="submenu">
                                    <li><NavLink to={ '/account/profile' }>Profile</NavLink></li>
                                    <li><NavLink to={ '/account/address' }>Address</NavLink></li>
                                    <li><NavLink to={ '/account/order-history' }>Orders</NavLink></li>
                                    <li><NavLink to={ '/account/support' }>Support</NavLink></li>
                                </ul>
                            </SubMenu>
                        }
                        
                    </li>
                    <li>
                        <SubMenu name="Shop">
                            <ul className="submenu">
                                <li><NavLink to={ '/catalog/suitcases' }>Suitcases</NavLink></li>
                                <li><NavLink to={ '/catalog/luggage-tags' }>Luggage Tags</NavLink></li>
                                <li><NavLink to={ '/catalog/stickers' }>Stickers</NavLink></li>
                                <li><NavLink to={ '/catalog' }>Shop All</NavLink></li>
                            </ul>
                        </SubMenu>
                    </li>
                    <li>
                        <SubMenu name="Explore">
                            <ul className="submenu">
                                <li><NavLink to={ '/about' }>About Us</NavLink></li>
                            </ul>
                        </SubMenu>
                    </li>
                    <li><a href={ 'https://baller.co.id/blog/' } target="blank">Blog</a></li>
                    <li><NavLink to={ '/help' }>Help</NavLink></li>
                    {
                        currentUser && 
                        <li><button onClick={ this.handleLogout } type="button" className="btn-logout">Log Out</button></li>
                    }
                </ul>
            </SlidingPane>
        )
    }
}

export default MobileSideBar