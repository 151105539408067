import React, { Component } from 'react'
import Step from './Step'
import { NavLink } from 'react-router-dom'
import BundlingPersonalizedAddToCart from 'Components/Buttons/BundlingPersonalizedAddToCart'

class BundlingStep extends Component {
    getProductDetailLink = () => {
        let currentUrlParams = new URLSearchParams(this.props.location.search)
        currentUrlParams.delete('bundling')
        let url = this.props.location.pathname.replace('personalize', 'detail')
        if(currentUrlParams){
            url = `${ url }?${ currentUrlParams.toString() }`
        }

        return url
    }
    getButtonSuitcaseIcon = (el, stepData) => {
        let selectedType = ''
        const selectedMargin = stepData.position
        if(selectedMargin === el){
            selectedType = '-1'
        }
        return `/images/icon/personalize/position/${ el }${ selectedType }.svg`
    }

    renderSuitcaseHeader = stepData => {
        return(
            <div className="col-xl-6 col-md-12 personalize-product order-xl-2 order-md-1 order-sm-1">
                <div className="personalize-image suitcase">
                    <img src="/images/placeholder/personalize/1.png" alt="personalize-suitcase"/>
                    <span className={ `text-letter text-${ stepData.position }` }>{ stepData.value }</span>
                </div>
            </div>
        )
    }

    renderTagHeader = stepData => {
        const textColor = stepData.key === 'tag' ? stepData.textColor : ''
        const letter = stepData.key === 'tag' ? stepData.value : ''
        return(
            <div className="col-xl-6 col-md-12 personalize-product order-xl-2 order-md-1 order-sm-1">
                <div className="personalize-image tag">
                    <img src="/images/placeholder/personalize/tag.jpg" alt="personalize-suitcase"/>
                    <span className={ `text-letter-tag ${ textColor }` }>{ letter }</span>
                </div>
            </div>
        )
    }

    handleChangeSuitcaseValue = el => {
        const { currentStep, update } = this.props
        const newValue = el.target.value
        let stepData = this.props.steps
        
        stepData.find(el => {
            return el.step === parseInt(currentStep)
        }).value = newValue

        return update(stepData)
    }

    handleChangeLetterPosition = position => {
        const { currentStep, update } = this.props
        let stepData = this.props.steps
        
        stepData.find(el => {
            return el.step === parseInt(currentStep)
        }).position = position

        return update(stepData)
    }

    handleChangeTagLetter = el => {
        const { currentStep, update } = this.props
        const newValue = el.target.value
        let stepData = this.props.steps
        
        stepData.find(el => {
            return el.step === parseInt(currentStep)
        }).value = newValue.toUpperCase()
        return update(stepData)
    }

    changeTextTagColor = color => {
        const { currentStep, update } = this.props
        let stepData = this.props.steps
        
        stepData.find(el => {
            return el.step === parseInt(currentStep)
        }).textColor = color
        return update(stepData)
    }

    changeTagVariantColor = colorVariant => {
        const { currentStep, update } = this.props
        let stepData = this.props.steps
        
        stepData.find(el => {
            return el.step === parseInt(currentStep)
        }).tagColor = colorVariant.id
        return update(stepData)
    }

    renderSuitcaseBody = stepData => {
        const marginPost = ['left', 'center', 'right']
        return(
            <>
                <h1>[Bundling] Personalize Suitcase : { stepData.name }</h1>
                <span className="message">Engrave your name, initials, or lucky number</span>
                <div className="input-area">
                    <span className="letter-info">letters</span>
                    <input type="text" value={ stepData.value } placeholder="BLR" maxLength="3" onChange={ this.handleChangeSuitcaseValue }/>
                    <span className="text-guide">Enter up to three letters or numbers</span>
                </div>
                <div className="option-switch">
                    <p className="guide">choose position: <strong>{ stepData.position }</strong></p>
                    <div className="wrap-button">
                        {
                            marginPost.map((el, index) => {
                                return(
                                    <button key={ index } className={ stepData.position === el ? 'selected' : '' } onClick={ () => this.handleChangeLetterPosition(el) }>
                                        <div className="margin-button">
                                            <img src={ this.getButtonSuitcaseIcon(el, stepData) } alt="text-position"/>
                                            <span>{ el }</span>
                                        </div>
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }

    renderTagBody = stepData => {
        const defaultTextColors = ['gold', 'silver']
        const selectedTextColor = stepData.key === 'tag' ? stepData.textColor : ''
        const selectedTagColor = stepData.key === 'tag' ? stepData.tagColor : ''
        const letter = stepData.key === 'tag' ? stepData.value : ''
        const targetTagColor = stepData.availableTagColors ? stepData.availableTagColors.find(el => {
            return el.id === selectedTagColor
        }) : null

        return(
            <>
                <h1>[Bundling] Personalize Tag : { stepData.name }</h1>
                <span className="message">Engrave your name, initials, or lucky number</span>
                <div className="input-area">
                    <span className="letter-info">letters</span>
                    <input type="text" value={ letter } placeholder="BLR" maxLength="3" onChange={ this.handleChangeTagLetter }/>
                    <span className="text-guide">Enter up to three letters or numbers</span>
                </div>
                <div className="option-switch">
                    <p className="guide">choose text color: <strong>{ selectedTextColor }</strong></p>
                    <div className="color-buttons">
                        {
                            defaultTextColors?.map((el, index) => {
                                return(
                                    <button 
                                        className={ `${ el } ${ selectedTextColor === el ? 'selected' : '' }` } 
                                        key={ index }
                                        onClick={ () => this.changeTextTagColor(el) }
                                    >
                                        <span>{ el.toUpperCase() }</span>
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="option-switch">
                    <p className="guide">choose tag color: <strong>{ targetTagColor.name }</strong></p>
                    <div className="color-picker text-left">
                        <div className="color-circles">
                            {
                                stepData.availableTagColors?.map((el, index) => {
                                    return(
                                        <button 
                                            key={ index } 
                                            className={ `color-button ${ targetTagColor.id === el.id ? 'selected' : '' }` }  
                                            type="button" 
                                            onClick={ () => this.changeTagVariantColor(el) }
                                        >
                                            <span className="color" style={ {
                                                backgroundColor: el.hexcode
                                            } } title={ el.name }/>
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderActionButtons = () => {
        const { currentStep, steps, toggleStep, detailData, color } = this.props
        const nextStepLabel = `NEXT STEP TO ${ currentStep + 1 } / ${ steps.length }`
        const StepButtons = () => {
            if(currentStep < steps.length){
                return(
                    <button className="action invert" type="button" onClick={ () => toggleStep(currentStep + 1) }>
                        <span>{ nextStepLabel }</span>
                    </button>
                )
            }else{
                return(
                    <BundlingPersonalizedAddToCart
                        color={ color }
                        className="action invert"
                        detailData={ detailData }
                        history={ this.props.history }
                        bundlings={ steps }
                    >
                        <span>{ `Add Bundling Items to Cart` }</span>
                    </BundlingPersonalizedAddToCart>
                )
            }
        }
        return(
            <div className="action-wrap separate-section">
                <StepButtons />
                {
                    currentStep > 1 ? 
                    <button className="action" type="button" onClick={ () => toggleStep(currentStep - 1) }>
                        <span>return to previous page</span>
                    </button> : 
                    <NavLink className="link-action text-center personalize-detail" to={ this.getProductDetailLink() }>
                        <span>return to product page</span>
                    </NavLink>
                }
            </div>
        )
    }

    render() {
        const { steps, currentStep } = this.props
        const targetStep = steps.find(el => {
            return el.step === parseInt(currentStep)
        })

        return (
            <div className="wrapper-white">
                <div className="container personalize-area">
                    <div className="row">
                        {
                            targetStep.key === 'suitcase' ?
                            this.renderSuitcaseHeader(targetStep) : 
                            this.renderTagHeader(targetStep)
                        }
                        <div className="col-xl-6 col-md-12 personalize-input order-xl-1 order-md-2 order-sm-2">
                            <Step { ...this.props }/>
                            {
                                targetStep.key === 'suitcase' ? 
                                this.renderSuitcaseBody(targetStep) :
                                this.renderTagBody(targetStep)
                            }
                            { this.renderActionButtons(targetStep) }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BundlingStep