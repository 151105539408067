import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Icon from 'Components/Icons/Svg/Icon'

class Footer extends Component {
    render() {
        return (
            <div id="footer">
                <div className="container">
                    <h1>Subscribe to our newsletter to receive exclusive information about us and our collection</h1>
                    <form className="subscribe">
                        <div className="wrap-input">
                            <input type="text" placeholder="Type your email here"/>
                            <button type="submit">
                                <img className="icon-svg" src="/images/icon/arrow-right.png" alt="submit-subscription"/>
                            </button>
                        </div>
                    </form>
                    
                    <ul className="footer-link">
                        <li><NavLink to={ `/help/faq` }>FAQ</NavLink></li>
                        <li><NavLink to={ `/help/returns` }>Returns</NavLink></li>
                        <li><NavLink to={ `/help/claim-warranty` }>Claim Warranty</NavLink></li>
                        <li><NavLink to={ `/help/claim-warranty` }>Careers</NavLink></li>
                        <li><a href={ 'https://baller.co.id/blog/' } target="blank">Blog</a></li>
                    </ul>
                    
                    <ul className="contact-link">
                        <li>
                            <Icon name="whatsapp" />
                            <span className="label">+62 891 011 1234</span>
                        </li>
                        <li>
                            <Icon name="email" />
                            <span className="label">help@baller.co.id</span>
                        </li>
                        <li className="social">
                            <a href="https://www.instagram.com/ballerindonesia" target="blank"><Icon name="ig" /></a>
                        </li>
                        <li className="social">
                            <a href="https://www.facebook.com/ballerindonesiaa" target="blank"><Icon name="fb" /></a>
                        </li>
                    </ul>
                    <div className="cr">
                        <img src="/images/logo/baller-invert.png" alt="footer logo" />
                        <span>© 2020 Baller Indonesia. All Rights Reserved.</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer