import React from 'react'

const Benefit = () => {
    return (
        <div className="products-note wrapper-white">
            <div className="row middle-row justify-content-center">
                <div className="col-md-4 benefit-wrapper">
                    <div className="wrap-icon-benefit">
                        <span className="helper"></span>
                        <img src="/images/icon/benefit/icon-quality.svg" alt="quality 1"/>
                    </div>
                    <h5>Quality tested</h5>
                    <span className="desc">Each suitcase is individually inspected</span>
                </div>
                <div className="col-md-4 benefit-wrapper">
                    <div className="wrap-icon-benefit">
                        <span className="helper"></span>
                        <img src="/images/icon/benefit/icon-warranty.svg" alt="quality 2"/>
                    </div>
                    <h5>5-year warranty</h5>
                    <span className="desc">Start from purchased date</span>
                </div>
                <div className="col-md-4 benefit-wrapper">
                    <div className="wrap-icon-benefit">
                        <span className="helper"></span>
                        <img src="/images/icon/benefit/icon-cs.svg" alt="quality 3"/>
                    </div>
                    <h5>Customer service</h5>
                    <span className="desc">A dedicated network of experts at your service</span>
                </div>
            </div>
        </div>
    )
}

export default Benefit