import React from 'react'

const Step = (props) => {
    const { steps, currentStep } = props
    let stepLength = steps.length

    return (
        <h3>Step { currentStep } of { stepLength }</h3>
    )
}

export default Step