import React, { Component } from 'react'
import AccountTemplate from '../../Section/AccountTemplate'
import AddressForm from './AddressForm'

class EditAddress extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false,
            provinceOpt: []
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    handleSubmitForm = formData => {
        this.toggleProcess(true)
    }

    render() {
        const { params } = this.props.match
        const accountConfig = {
            backPathUrl: '/account/address',
            backPathLabel: 'Back to my address'
        }
        return (
            <AccountTemplate config={ accountConfig } title="Edit Address" { ...this.props }>
                <div className="section-account">
                    <AddressForm { ...this.props } formType={ 'edit' } editId={ params.id }/>
                </div>
            </AccountTemplate>
        )
    }
}

export default EditAddress