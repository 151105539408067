import React from 'react'

const Testimonials = () => {
    return (
        <div className="products-note wrapper-white">
            <div className="container-intro">
                <div className="row">
                    <div className="col-md-6 order-md-1 order-sm-2">
                        <div className="card-info">
                            <div className="head">
                                <span className="subtitle">testimonials</span>
                                <h3>Meet the duo: Baller ♡ you</h3>
                            </div>
                            <div className="body">
                                <span>♥ ♥ ♥ ♥ ♥ 5/5</span>
                                <p className="mt-3">
                                    “Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus.”
                                </p>
                                <span className="author">@marischkaprue</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-md-2 order-sm-1">
                        <img src="images/home/testimonial/1.jpg" alt="testimonial banner 1"/>
                    </div>
                </div>
            </div>
            <div className="container-intro pt-5">
                <div className="row ">
                    <div className="col-md-6 order-md-2 order-sm-2">
                        <div className="card-info">
                            <div className="body">
                                <span>♥ ♥ ♥ ♥ ♥ 5/5</span>
                                <p className="mt-3">
                                    “Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus.”
                                </p>
                                <span className="author">@citrascholastika</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-md-1 order-sm-1">
                        <img src="images/home/testimonial/2.jpg" alt="testimonial banner 2"/>
                    </div>
                </div>
            </div>
            <div className="container-intro pt-5">
                <div className="row ">
                    <div className="col-md-6 order-md-1 order-sm-2">
                        <div className="card-info">
                            <div className="body">
                                <span>♥ ♥ ♥ ♥ ♥ 5/5</span>
                                <p className="mt-3">
                                    “Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus.”
                                </p>
                                <span className="author">@louiseans</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-md-2 order-sm-1">
                        <img src="images/home/testimonial/3.jpg" alt="testimonial banner 3"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials