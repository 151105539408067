import React, { Component } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import CartSlider from '../Cart/Slider'
import Loader from 'Components/Loader/Skeleton'
import { NavLink } from 'react-router-dom'
import { handleToast } from 'Services/Toastify'

class QuickNav extends Component {
    constructor(){
        super()
        this.state = {
            loadedUser: null,
            isLoading: true,
            showUserMenu: false
        }
    }

    toggleCart = () => {
        const { cartPlugin } = this.props
        cartPlugin.toggleCart(true)
    }

    toggleUserMenu = () => {
        const newFlag = !this.state.showUserMenu
        this.setState({
            showUserMenu: newFlag
        })
    }

    closeUserMenu = () => {
        this.setState({
            showUserMenu: false
        })
    }

    componentDidMount(){
        const { currentUser } = this.props
        this.setState({
            loadedUser: currentUser,
            isLoading: false
        })
    }

    renderUserButton = () => {
        const { loadedUser } = this.state
        if(loadedUser){
            return(
                <ClickAwayListener onClickAway={ this.closeUserMenu }>
                    <button className="user-profile" type="button" onClick={ () => this.toggleUserMenu() }>
                        <img src="/images/icon/user.svg" alt="user-quicknav"/>
                    </button>
                </ClickAwayListener>
            )
        }else{
            return(
                <NavLink to={ '/auth/register' } className="user-profile">
                    <img src="/images/icon/user.svg" alt="user-quicknav"/>
                </NavLink>
            )
        }
    }

    handleLogout = () => {
        this.setState({
            loadedUser: null,
            showUserMenu: false
        })
        handleToast('Successfully Log Out')
        this.props.onSuccessLogout()
    }

    render() {
        const { cartPlugin } = this.props
        const { isLoading, showUserMenu } = this.state
        const cartQty = cartPlugin?.cartInfo?.items?.length

        return (
            <>
                <div className="quicknav-area">
                    {
                        !isLoading ? 
                        <>
                            <button className="cart-icon" type="button" onClick={ this.toggleCart }>
                                <img src="/images/icon/icon-luggage.svg" alt="cart"/>
                                <span className={ `cart-counter` }>{ cartQty ? cartQty : 0 }</span>
                            </button>
                            { this.renderUserButton() }
                        </> :
                        <div className="wrap-loader">
                            <Loader width={ 50 } height={ 30 } duration={ 1 } style={ { marginRight: '5px' } }/>
                            <Loader width={ 50 } height={ 30 } duration={ 1 }/>
                        </div>
                    }
                    <ul className={ `quick-link-user ${ !showUserMenu ? 'hide' : '' }` }>
                        <li><NavLink to={ '/account/profile' }>Account Profile</NavLink></li>
                        <li><NavLink to={ '/account/address' }>Account Addresses</NavLink></li>
                        <li><NavLink to={ '/account/order-history' }>Order History</NavLink></li>
                        <li><NavLink to={ '/account/support' }>Support</NavLink></li>
                        <li><button onClick={ this.handleLogout } type="button" className="btn-logout">Log Out</button></li>
                    </ul>
                </div>
                <CartSlider { ...this.props }/>
            </>
        )
    }
}

export default QuickNav