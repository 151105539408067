import React, { Component } from 'react'
import PageTemplate from 'Components/Page/Template'
import DefaultForm from 'Components/Form/Default'
import FormButton from 'Components/Form/Buttons/Default'
import { NavLink, Redirect } from 'react-router-dom'
import AuthApi from 'Services/API/Guest/AuthAPI'
import { handleToast } from 'Services/Toastify'

class ResetPassForm extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false,
            token: true
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    handleSubmit = (formData) => {
        this.toggleProcess(true)
        const { value } = formData
        const query = new URLSearchParams(this.props.location.search)
        const token = query.get('t')

        AuthApi.resetPassword({
            password: value.password,
            password_confirmation: value.password,
            recover_password_token: token
            
        }).then(response => {
            this.toggleProcess(false)
            let type = 'warning'
            let message = 'Failed to reset your password, please try again later.'
            if(response.status === 200){
                type = 'success'
                message = `Successfully reset your password, you can sign in to baller now`
                this.props.history.push('/auth/sign-in/email')
            }
            handleToast(message, type)
        })
    }

    render() {
        const query = new URLSearchParams(this.props.location.search)
        const token = query.get('t')
        if(!token){
            return(<Redirect to={ '/' } />)
        }
        return (
            <PageTemplate { ...this.props }>
                <div className="container">
                    <div className="auth-content">
                        <h3>New Password</h3>
                        <DefaultForm 
                            { ...this.props }
                            className={ `auth-form` }
                            isProcessing={ this.state.isProcessing }
                            handleSubmit={ this.handleSubmit }
                            formInputs={ 
                                [
                                    {
                                        name: 'password',
                                        type: 'TextInputPassword',
                                        defaultValue: '',
                                        validator: 'required',
                                        meta: {
                                            label: {
                                                text: 'Password',
                                                show: false
                                            },
                                            placeHolder: 'Password'
                                        }
                                    }
                                ]
                            }
                            actionComponent={ props => {
                                return(
                                    <div className="form-group form-action-d-flex mb-3">
                                        <FormButton 
                                            className="btn submit-btn"
                                            label={ `Save Password` }
                                            type={ `submit` }
                                            isProcessing={ this.state.isProcessing }
                                            disabled={ props.invalid } 
                                        />
                                    </div>
                                )
                            }}
                        />
                        <NavLink to={ '/auth/sign-in' } className="link-outside">
                            <span>Sign in with another account</span>
                        </NavLink>
                    </div>
                </div>
            </PageTemplate>
        )
    }
}

export default ResetPassForm 