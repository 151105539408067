import React from 'react'

const BreadCrumb = ({allStep, currentStep}) => {
    const getKeyByValue = (obj, value) => Object.keys(obj).find(key => obj[key] === value)
    const currentStepindex = getKeyByValue(allStep, currentStep)

    return (
        <div className="breadcrumb-checkout">
            {
                allStep.map((el, index) => {
                    let classNameInit = ''
                    if(currentStep === el){
                        classNameInit = 'current'
                    }

                    if(index <= currentStepindex){
                        classNameInit = `visited ${ classNameInit }`
                    }
                    return(
                        <div className={ classNameInit } key={ index }>
                            { el }
                            {
                                index <= 1 && <span className="arrow">{ `>` }</span>
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default BreadCrumb