import React, { Component } from 'react'
import SubCategory from './SubCategory'

class index extends Component {
    render() {
        const { categories } = this.props
        if(!categories){
            return null
        }
        
        let categoryComponent = []
        categories.forEach((element, index) => {
            categoryComponent.push(
                <div className="info-area catalog-wrapper" key={ index }>
                    <div className="container text-left">
                        <div className="row">
                            <div className="col-md-12 category-information"><h5>{ element.name }</h5></div>
                        </div>
                        { element.sub_categories.map((el, index) => <SubCategory key={ index } data={ el }/>) }
                    </div>
                </div>
            )
        })

        return categoryComponent
    }
}

export default index