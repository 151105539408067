import React from 'react'
import { NavLink } from 'react-router-dom'

const Personalize = (props) => {
    const { bundlings, detailData } = props.detailConfig
    const { personalized } = detailData
    let currentUrlParams = new URLSearchParams(props.location.search)

    if(!personalized.suitcase && !personalized.tag){
        return null
    }
    if(bundlings){
        const bundlingData = bundlings.bundling
        const extraBundlingItem = bundlingData.map(el => {
            return el.size_id
        }) 
        currentUrlParams.set('bundling', extraBundlingItem.join())
    }

    let url = props.location.pathname.replace('detail', 'personalize')
    if(currentUrlParams){
        url = `${ url }?${ currentUrlParams.toString() }`
    }

    return (
        <NavLink className="link-action text-center personalize-detail" to={ url }>
            <span>personalize it for free</span>
        </NavLink>
    )
}

export default Personalize