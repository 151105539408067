import React from 'react'
import Accordion from 'Components/Accordion'

const FAQ = ({faqData}) => {
    return (
        <div className="section wrapper-white">
            <div className="container">
                <div className="col-md-12 faq-wrapper">
                    {
                        faqData.map((el, index) => {
                            return(
                                <div className="wrap-question" key={ index }>
                                    <h3>{ el.headTitle }</h3>
                                    <Accordion data={ el.body } />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQ