import React from 'react'

const HomeIntro = () => {
    return (
        <div className="products-note wrapper-white">
            <div className="container-intro">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card-info">
                            <div className="head">
                                <span className="subtitle">mix and match</span>
                                <h3>Meet the duo: Baller ♡ you</h3>
                            </div>
                            <div className="body">
                                <p className="notes">
                                Lorem Ipsum dolor sip rorentum ronaldo amet Lorem Ipsum dolor sv rorentum ronaldo amet  ip amet Lorem Ipsum dolor sip amet Lorem Ipsum dolor sip amet.
                                Lorem Ipsum dolor sip amet Lorem Ipsum dolor sip amet Lorem Ip rorentum ronaldo amet  sum dolor sip amet Lorem Ipsum dolor sip amet
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img className="intro-image" src="images/home/intro/1.jpg" alt="intro banner 1"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeIntro