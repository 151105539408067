const axiosDefault = {
    baseURL: process.env.REACT_APP_API_HOST,
    validateStatus: function (status) {
        return status < 500 // pass all http code status and handling by self
    },
    timeout: 30000,
    headers: { 
        'content-type': 'application/json',
    },
}

export default axiosDefault;