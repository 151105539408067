import TextInput from './TextInput'
import TextInputPassword from './TextInputPassword'
import CheckBoxInput from './CheckBoxInput'
import TextAreaInput from './TextAreaInput'
import SelectBoxInput from './SelectBoxInput'

export default {
    TextInput,
    TextAreaInput,
    TextInputPassword,
    CheckBoxInput,
    SelectBoxInput
}