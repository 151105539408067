import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { authMapStateToProps, authMapDispatchToProps } from 'Constants/Redux/Auth'
import RouteList from './List'
import { Redirect } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

const renderComponent = (props, route, currentUser, onSuccessLogout, onSuccessUpdateUser) => {
    if(route.authorizedOnly && !currentUser){
        return (<Redirect to={ '/auth/sign-in' }/>)
    }

    if(route.authRoute && currentUser){
        return (<Redirect to={ '/account/profile' }/>)
    }

    return(
        <route.Component 
            currentUser={ currentUser } 
            onSuccessLogout={ onSuccessLogout } 
            onSuccessUpdateUser={ onSuccessUpdateUser }
            { ...props } 
        />
    )
}

const Handler = (props) => {
    return(
        <>
            <ScrollToTop />
            <Switch>
                {
                    RouteList.map((route, index) => {
                        const { currentUser, onSuccessLogout, onSuccessUpdateUser } = props
                        return(
                            <Route 
                                key={ index }
                                exact={ route.exact }
                                path={ route.path } 
                                render={ 
                                    props => renderComponent(props, route, currentUser, onSuccessLogout, onSuccessUpdateUser)
                                } 
                            />
                        )
                    })
                }
            </Switch>
        </>
    )
}

export default connect(authMapStateToProps, authMapDispatchToProps)(Handler)