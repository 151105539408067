import React from 'react'

const MainBanner = () => {
    return (
        <div className="help-main-banner">
            <img src="/images/faq/banner.jpg" alt="banner faq" />
            <div className="banner-title">
                <h2>Frequently Asked <br />Questions</h2>
            </div>
        </div>
    )
}

export default MainBanner