import React, { Component } from 'react'
import DefaultForm from 'Components/Form/Default'
import FormButton from 'Components/Form/Buttons/Default'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import { handleToast } from 'Services/Toastify'

class UserInfoForm extends Component {
    constructor(){
        super()
        this.state = {
            isProcessing: false
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    handleSubmit = (formData) => {
        const { dialogToggle, setUserInfo } = this.props
        this.toggleProcess(true)
        AccountAPI.updateProfile({
            name: formData.value.name,
            email: formData.value.email,
            phone_number: formData.value.phone_number
        }).then(res => {
            this.toggleProcess(false)
            if(res.status === 200){
                setUserInfo({
                    name: formData.value.name,
                    email: formData.value.email,
                    phone_number: formData.value.phone_number
                })
                const { currentUser } = this.props
                let updatedCurrentUser = {
                    ...currentUser,
                    name: formData.value.name,
                    phone_number: formData.value.phone_number
                }
                handleToast('Successfully update your profile')
                this.props.onSuccessUpdateUser(updatedCurrentUser)
            }else{
                handleToast('Oops, something wrong, please try again later.', 'warning')
            }
        })

        dialogToggle(false)
    }

    render() {
        const { userInfo } = this.props
        const { isProcessing } = this.state
        return (
            <div className="section-account checkout-section-form">
                <h5 className="form-title">Edit Contact Information</h5>
                <DefaultForm 
                    { ...this.props }
                    className={ `account-checkout-form` }
                    isProcessing={ isProcessing }
                    handleSubmit={ this.handleSubmit }
                    formInputs={ 
                        [
                            {
                                name: 'name',
                                type: 'TextInput',
                                defaultValue: userInfo.name,
                                validator: 'required',
                                meta: {
                                    label: {
                                        text: 'Full Name',
                                        show: false
                                    },
                                    placeHolder: 'Full Name'
                                }
                            },
                            {
                                name: 'email',
                                type: 'TextInput',
                                defaultValue: userInfo.email,
                                validator: 'required,email',
                                meta: {
                                    label: {
                                        text: 'Email',
                                        show: false
                                    },
                                    placeHolder: 'Email',
                                    disabled: true
                                }
                            },
                            {
                                name: 'phone_number',
                                type: 'TextInput',
                                defaultValue: userInfo.phone_number,
                                validator: 'required',
                                meta: {
                                    label: {
                                        text: 'Mobile Number',
                                        show: false
                                    },
                                    placeHolder: 'Mobile Number'
                                }
                            },
                        ]
                    }
                    actionComponent={ props => {
                        return(
                            <div className="form-group form-action-d-flex mb-3">
                                <FormButton 
                                    className="action invert"
                                    label={ `Change` }
                                    type={ `submit` }
                                    isProcessing={ this.state.isProcessing }
                                    disabled={ props.invalid } 
                                />
                            </div>
                        )
                    }}
                />
            </div>
        )
    }
}

export default UserInfoForm