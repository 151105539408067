import { toast } from 'react-toastify'

export const handleToast = (message, setType) => {
    let type = 'success'
    if(setType){
        type = setType
    }
    toast(message, {
        className: `global-toastify ${ type }`,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 50
    })
}