import React from 'react'
import PageTemplate from 'Components/Page/Template'
import LeftMenu from './LeftMenu'
import Icon from 'Components/Icons/FontAwesome/Icon'
import { NavLink } from 'react-router-dom'
import { getDayTimeText } from 'Services/Helpers/Main'

const AccountTemplate = (props) => {
    const { config } = props

    const dayTimeText = getDayTimeText()
    
    let currentUser = ''
    if(props.currentUser){
        currentUser = `, ${ props.currentUser.name }`
    }
    return (
        <PageTemplate { ...props }>
            <div id="account-template">
                <div className="container">
                    <div className="row account-greeting-wrapper">
                        <div className="col-md-12">
                        <h3 className="greetings">Good { dayTimeText }{ currentUser }</h3>
                        </div>
                    </div>
                    <div className="row account-content-wrapper">
                        <LeftMenu { ...props }/>
                        <div className="col-md-9">
                            {
                                config?.backPathUrl && config?.backPathLabel &&
                                <NavLink className="back-button-page" to={ config.backPathUrl }>
                                    <span className="icon"><Icon name="chevron-left" /></span>
                                    <span className="text">{ config.backPathLabel }</span>
                                </NavLink>
                            }
                            <div className="account-content">
                                <h3 className="title">{ props.title }</h3>
                                { props.children }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    )
}

export default AccountTemplate