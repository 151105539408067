import React from 'react'
import { dateObjToString } from 'Services/Helpers/Main'

const OrderInfo = (props) => {
    const { detailData } = props

    let addressText = detailData.city
    if(detailData.sub_disctrict){
        addressText = `${ addressText }, ${ detailData.sub_disctrict }`
    }

    if(detailData.zip_code){
        addressText = `${ addressText }, ${ detailData.zip_code }`
    }
    
    return (
        <div className="row content">
            <div className="col-md-6 card-detail">
                <div className="label">ship to</div>
                <div className="value">
                    <strong>{ detailData.contact_name }</strong>
                    <span>{ detailData.contact_number }</span>
                </div>
            </div>
            <div className="col-md-6 card-detail">
                <div className="label">shipping</div>
                <div className="value">
                    <strong>{ detailData.shipping_method }</strong>
                    <span>{ detailData.resi_no ? detailData.resi_no : 'waiting for track number..' }</span>
                </div>
            </div>
            <div className="col-md-6 card-detail">
                <div className="label">address</div>
                <div className="value">
                    <strong>{ detailData.address }</strong>
                    <span>{ addressText }</span>
                    <span>
                        <em>Remarks: { detailData.remarks ? detailData.remarks : '-' }</em>
                    </span>
                </div>
            </div>
            <div className="col-md-6 card-detail">
                <div className="label">information</div>
                <div className="value">
                    <span>Order Date : <strong>{ dateObjToString(detailData.created_at) }</strong></span>
                    <span>Payment Method: <strong>{ detailData.payment_method }</strong></span>
                    <span>Payment Date : <strong>{ dateObjToString(detailData.paid_at) }</strong></span>
                </div>
            </div>
        </div>
    )
}

export default OrderInfo