import axios from 'axios'
import GuestConfig from '../GuestConfig'

class AuthAPI {
    static login = json => axios.post( '/login' , json, GuestConfig )
    static register = json => axios.post( '/register' , json, GuestConfig )
    static recoverPassword = email => axios.post( '/users/recover_password', { email: email }, GuestConfig ) 
    static resetPassword = json => axios.post( '/users/recover_new_password', json, GuestConfig )
}

export default AuthAPI