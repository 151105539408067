import { HomePageConfig } from 'Screens/Home/config'
import { AboutPageConfig } from 'Screens/About/config'
import { AuthPageConfig } from 'Screens/Auth/config'
import { HelpPageConfig } from 'Screens/Help/config'
import { CatalogPageConfig } from 'Screens/Catalog/config'
import { PersonalizePageConfig } from 'Screens/Personalize/config'
import { AccountPageConfig } from 'Screens/Account/config'
import { CheckoutPageConfig } from 'Screens/Checkout/config'
import { PaymentPageConfig } from 'Screens/Payment/config'

let ScreenLists = []
const PageConfigCombined = [
    AuthPageConfig,
    AccountPageConfig,
    HomePageConfig,
    AboutPageConfig,
    HelpPageConfig,
    CatalogPageConfig,
    PersonalizePageConfig,
    CheckoutPageConfig,
    PaymentPageConfig
]


PageConfigCombined.forEach(element => {
    element.routes.map(route => {
        return ScreenLists.push(route)
    })
})

export default ScreenLists