import React from 'react'

const TextAreaInput = (props) => {
    const { handler, touched, hasError, meta, errors } = props
    let classIndicator = touched && errors ? 'form-control error-input' : 'form-control'
    const label = meta.label
    let errClass = touched && hasError("required") ? 'has-error' : ''
    return(
        <div className="form-group">
            {
                label.show &&
                <label htmlFor={ label.text } className="placeholder"><b>{ label.text }</b></label>
            }
            <textarea 
                id={ label.text } 
                className={ `${ classIndicator } ${ errClass }` } 
                type="text" 
                placeholder={ meta.placeHolder } 
                autoFocus={ meta.autoFocus }
                readOnly={ meta.readOnly }
                { ...handler() }
            />
            <span className="warning-input">
                { touched && hasError("required") && `${ label.text } is required` }
            </span>
        </div>
    )
}

export default TextAreaInput