import axios from 'axios'
import { store } from '../Redux/Store'

const AuthorizedAxios = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_API_HOST,
        method: 'get',
        validateStatus: function (status) {
            return status !== 401 && status < 500 
        },
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 30000,
    }

    // Create instance
    let instance = axios.create(defaultOptions)

    // Set the AUTH token for any request
    instance.interceptors.request.use(config => {
        const reduxLoginData = store.getState().AuthReducer.currentUser
        const tokenFromRedux = reduxLoginData ? reduxLoginData.api_token : null
        config.headers.Authorization =  tokenFromRedux ? `Bearer ${ tokenFromRedux }` : ''
        return config
    });

    // redirect to unauthorize
    instance.interceptors.response.use(null, function(error) {
        const resp = error.response
        if (typeof resp === 'undefined') {
            return Promise.reject(error)
        }

        if(resp.status === 401){
            window.location.href = '/auth/logout'
        }

        return Promise.reject(error)
    });

    return instance;
}

export default AuthorizedAxios()