import React, { Component } from 'react'
import ResponsiveDialog from 'Components/Dialogs/Responsive'
import UserInfoForm from './Forms/UserInfo'

class UserInformation extends Component {
    constructor(){
        super()
        this.state = {
            openForm: false
        }
    }

    toggleOpenForm = (flag) => {
        const { isProcessingPayment } = this.props
        if(isProcessingPayment){
            return false
        }
        this.setState({
            openForm: flag ? true : false
        })
    }

    render() {
        const { openForm } = this.state
        const { userInfo } = this.props
        return (
            <>
                {
                    userInfo && 
                    <div className="info-card">
                        <h3>Contact Information</h3>
                        <div className="info-box">
                            <span className="highlight">{ userInfo.name }</span>
                            <span>{ userInfo.phone_number }</span>
                            <span>{ userInfo.email }</span>
                            <button className="button-link" onClick={ () => this.toggleOpenForm(true) }>change</button>
                        </div>
                    </div>
                }
                <ResponsiveDialog open={ openForm } handler={ this.toggleOpenForm } className="dialog-medium">
                    <div className="form-wrapper-checkout">
                        <UserInfoForm { ...this.props } dialogToggle={ this.toggleOpenForm }/>
                    </div>
                </ResponsiveDialog>
            </>
        )
    }
}

export default UserInformation