import React, { Component } from 'react'

class Image extends Component {
    constructor(props){
        super(props)
        this.state = {
            images: props.imageData ? props.imageData : null,
            selectedPreview: props.imageData && props.imageData[0] ? props.imageData[0] : null
        }
    }

    handleSetPreview = (obj) => {
        this.setState({
            selectedPreview: obj
        })
    }


    UNSAFE_componentWillReceiveProps(nextProps){
        if(this.props.imageData !== nextProps.imageData){
            this.setState({
                images: nextProps.imageData ? nextProps.imageData : null,
                selectedPreview: nextProps.imageData && nextProps.imageData[0] ? nextProps.imageData[0] : null
            })
        }
    }

    render() {
        const { images, selectedPreview } = this.state
        return (
            <>
                <div className="product-gallery">
                    <div className="preview-big animate__animated animate__fadeIn">
                        {
                            selectedPreview && selectedPreview.image_url ?
                            <img src={ selectedPreview.image_url } alt="img-preview"/> : 
                            null
                        }
                    </div>
                    <ul className="thumbnails">
                        {
                            images.map((el, index) => {
                                let selectedClass = ''
                                if(selectedPreview === el){
                                    selectedClass = 'selected'
                                }
                                return(
                                    <li key={ index }>
                                        <button 
                                            type="button" 
                                            onClick={ () => this.handleSetPreview(el) }
                                        >
                                            <img className={ selectedClass } src={ el.image_url } alt="img-thumb"/>
                                        </button>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </>
        )
    }
}

export default Image