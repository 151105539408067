import React from 'react'
import Main from './Main'

export const HomePageConfig = {
    pageName: 'Home',
    routes: [   
        {
            path: '/',
            exact: true,
            authorizedOnly: false,
            Component: props => {
                return(<Main { ...props } />)
            }
        }
    ]
}