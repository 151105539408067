import React from 'react'
import Loader from 'Components/Loader/Skeleton'

const LoaderInfo = () => {
    return (
        <div className="col-md-6 order-md-1 order-sm-2 information-detail">
            <p><Loader count={ 1 } height={ 25 } style={ {marginTop: '20px'} }/></p>
            <p><Loader count={ 3 } height={ 50 } style={ {marginTop: '20px'} }/></p>
            <p><Loader count={ 3 } height={ 50 } style={ {marginTop: '20px'} }/></p>
            <p><Loader count={ 1 } height={ 120 } style={ {marginTop: '20px'} }/></p>
        </div>
    )
}

export default LoaderInfo