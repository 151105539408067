import React from 'react'
import OrderStatus from 'Components/Status/Order'
import OrderInfo from './OrderInfo'
import OrderItems from './OrderItems'
import OrderSummary from './OrderSummary'

const OrderDetailTemplate = (props) => {
    const { detailData } = props
    if(!detailData) return null

    return (
        <div className="order-detail-container">
            <div className="header">
                <h3>Order Detail #{ detailData.order_code }</h3>
                <OrderStatus value={ detailData.status } />
            </div>
            <OrderInfo detailData={ detailData } /> 
            <OrderItems detailData={ detailData } />
            <OrderSummary detailData={ detailData }/>
        </div>
    )
}

export default OrderDetailTemplate