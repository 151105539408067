import { 
    initialState,
    SUCCESS_SYNC_CART,
    TOGGLE_CART_SLIDER
} from '../Constants/Cart'

export function CartReducer(state = initialState, action) {
    switch (action.type) {
        case SUCCESS_SYNC_CART:
            return {
                ...state,
                cartData: action.items
            }
        case TOGGLE_CART_SLIDER:
            return {
                ...state,
                openSliderCart: action.flag
            }
        default:
            return state
    }
}