import React, { Component } from 'react'
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators
} from 'react-reactive-form'
import TextInput from 'Components/Form/DynamicInputs/TextInput'
import TextAreaInput from 'Components/Form/DynamicInputs/TextAreaInput'
import NumericInput from 'Components/Form/DynamicInputs/NumericInput'
import AutoCompleteInput from 'Components/Form/DynamicInputs/AutoCompleteInput'
import DynamicFormButtons from 'Components/Form/Buttons/DynamicFormButtons'
import AccountAPI from 'Services/API/Authorized/AccountAPI'
import CheckBoxInput from 'Components/Form/DynamicInputs/CheckBoxInput'
import Loader from 'Components/Loader/Skeleton'
import { handleToast } from 'Services/Toastify'

class AddressForm extends Component {
    constructor(){
        super()
        this.state = {
            formData: FormBuilder.group({
                receiverName: [ '' , Validators.required ],
                phoneNumber: [ '', Validators.required ],
                address: [ '', Validators.required ],
                province: [ null, Validators.required],
                city: [ '', Validators.required],
                isPrimary: [ false , Validators.required],
                zipCode:  [ '', Validators.required],
                remarks: ['']
            }),
            isProcessing: false,
            loadingEdit: false,
            detailDataRaw: null,
            provinceOpt: [],
            cityOpt: []
        }
    }

    toggleProcess = flag => {
        this.setState({
            isProcessing: flag ? true : false
        })
    }

    loadProvince = () => {
        AccountAPI.getProvinceOpt().then(res => {
            if(res.status === 200){
                let data = res.data.data
                data = data.map(el => {
                    return {
                        value: el.id,
                        label: el.name
                    }
                })

                this.setState({
                    provinceOpt: data
                })
            }
        })
    }

    callbackFillCity = (selectedObj) => {
        const { formData } = this.state
        const provinceId = selectedObj.value
        const cityElement = formData.get('city')
        cityElement.setValue(null)

        AccountAPI.getCitiesOpt(provinceId).then(res => {
            if(res.status === 200){
                let data = res.data.data
                data = data.map(el => {
                    return {
                        value: el.id,
                        label: el.name
                    }
                })

                this.setState({
                    cityOpt: data
                })
            }
        })
    }

    onSubmitForm = (e) => {
        e.preventDefault()
        const { formType, editId } = this.props
        const { formData } = this.state
        this.toggleProcess(true)
        
        const formValue = formData.value

        const objPost = {
            receiver_name: formValue.receiverName,
            receiver_number: formValue.phoneNumber,
            address: formValue.address,
            province_id: formValue.province.value,
            city_id: formValue.city.value,
            latitude: '0',
            longitude: '0',
            zip_code: formValue.zipCode,
            remarks: formValue.remarks,
            is_primary: formValue.isPrimary ? true : false
        }

        if(formType === 'add'){
            AccountAPI.createAddress(objPost).then(res => {
                this.toggleProcess(false)
                if(res.status === 200){
                    this.props.history.push('/account/address')
                    handleToast('Successfully add new address')
                }else{
                    handleToast('Oops, something wrong, please try again later.', 'warning')
                }
            })
        }else{
            if(editId){
                // edit hook
                AccountAPI.updateAddress(editId, objPost).then(res => {
                    this.toggleProcess(false)
                    if(res.status === 200){
                        this.props.history.push('/account/address')
                        handleToast('Successfully update your address')
                    }else{
                        handleToast('Oops, something wrong, please try again later.', 'warning')
                    }
                })
            }
        }
    }

    handleCancel = () => {
        this.props.history.push('/account/address')
    }

    patchFormValue = rawData => {
        const { formData } = this.state

        formData.controls.receiverName.patchValue(rawData.receiver_name)
        formData.controls.phoneNumber.patchValue(rawData.receiver_number)
        formData.controls.address.patchValue(rawData.address)
        formData.controls.province.patchValue({
            value: rawData.province_id,
            label: rawData.province_name
        })
        formData.controls.city.patchValue({
            value: rawData.city_id,
            label: rawData.city_name
        })
        formData.controls.zipCode.patchValue(rawData.zip_code)
        formData.controls.remarks.patchValue(rawData.remarks)
        formData.controls.isPrimary.patchValue(rawData.is_primary ? true : false)

        this.setState({
            loadingEdit: false
        })
        return
    }

    showLoading = () => {
        return(
            <>
                <Loader count={ 5 } height={ 35 } style={{ marginBottom: '20px', marginTop: '15px', display:'block' }}/>
            </>
        )
    }

    componentDidMount(){
        const { formType, editId } = this.props
        let rawData = null
        let provinceId = null
        if(formType === 'edit' && editId){
            this.setState({
                loadingEdit: true
            })
            AccountAPI.detailAddress(editId).then(res => {
                if(res.status === 200){
                    rawData = res.data.data
                    this.setState({
                        detailDataRaw: rawData
                    })
                    provinceId = res.data.data.province_id
                    AccountAPI.getProvinceOpt().then(res => {
                        if(res.status === 200){
                            let data = res.data.data
                            data = data.map(el => {
                                return {
                                    value: el.id,
                                    label: el.name
                                }
                            })
            
                            this.setState({
                                provinceOpt: data
                            })
                            
                            AccountAPI.getCitiesOpt(provinceId).then(res => {
                                if(res.status === 200){
                                    let data = res.data.data
                                    data = data.map(el => {
                                        return {
                                            value: el.id,
                                            label: el.name
                                        }
                                    })
                    
                                    this.setState({
                                        cityOpt: data
                                    })

                                    this.patchFormValue(rawData)
                                }
                            })
                        }
                    })
                }
            })
        }else{
            this.loadProvince()
        }
    }

    render() {
        const { formData, isProcessing, loadingEdit } = this.state
        if(loadingEdit){
            return this.showLoading()
        }
        return (
            <>
                <FieldGroup
                    control={ formData }
                    strict={ false }
                    render={
                        props => {
                            const { province } = props.controls
                            return(
                                <form className={ `default-form account-form mt-30 manual-form` } onSubmit={ this.onSubmitForm }>
                                    <FieldControl
                                        name='receiverName'
                                        render={ TextInput }
                                        meta={{ 
                                            label: {
                                                text: 'Receiver Name',
                                                show: false
                                            }, 
                                            placeHolder: 'Receiver Name'
                                        }}
                                    />
                                    <FieldControl
                                        name='phoneNumber'
                                        render={ NumericInput }
                                        meta={{ 
                                            label: {
                                                text: 'Phone Number',
                                                show: false,
                                            }, 
                                            maxLength: 20,
                                            placeHolder: 'Phone Number'
                                        }}
                                    />
                                    <FieldControl
                                        name='address'
                                        render={ TextInput }
                                        meta={{ 
                                            label: {
                                                text: 'Address',
                                                show: false,
                                            }, 
                                            placeHolder: 'Address'
                                        }}
                                    />
                                    <FieldControl
                                        name='province'
                                        strict={ false }
                                        render={ props => {
                                            return(
                                                <AutoCompleteInput 
                                                    {...props} 
                                                    options={ this.state.provinceOpt }
                                                    callBack={ this.callbackFillCity }
                                                />)
                                        } }
                                        meta={{ 
                                            label: {
                                                text: 'Province',
                                                show: false,
                                            },
                                            placeHolder: 'Select Province'
                                        }}
                                    />
                                    <FieldControl
                                        name='city'
                                        strict={ false }
                                        render={ props => {
                                            return(
                                                <AutoCompleteInput 
                                                    {...props} 
                                                    options={ this.state.cityOpt }
                                                    disabled={ province.value === null ? true : false }
                                                />)
                                        } }
                                        meta={{ 
                                            label: {
                                                text: 'City',
                                                show: false,
                                            },
                                            placeHolder: 'Select City'
                                        }}
                                    />
                                    <FieldControl
                                        name='zipCode'
                                        render={ NumericInput }
                                        meta={{ 
                                            label: {
                                                text: 'Zip Code',
                                                show: false,
                                            }, 
                                            maxLength: 20,
                                            placeHolder: 'Zip Code'
                                        }}
                                    />
                                    <FieldControl
                                        name='remarks'
                                        render={ TextAreaInput }
                                        meta={{ 
                                            label: {
                                                text: 'Remarks',
                                                show: false
                                            }, 
                                            placeHolder: 'Remarks'
                                        }}
                                    />
                                    <FieldControl
                                        name='isPrimary'
                                        strict={ false }
                                        render={ CheckBoxInput }
                                        meta={{
                                            id: 'checkbox-input',
                                            label: {
                                                text: 'Make as primary address',
                                                show: true
                                            }
                                        }}
                                    />
                                    <DynamicFormButtons 
                                        invalid={ props.invalid }
                                        processing={ isProcessing }
                                        confirmConfig={
                                            {
                                                label: 'Save Address'
                                            }
                                        }
                                        cancelConfig={
                                            {
                                                label: 'Cancel',
                                                onClick: this.handleCancel
                                            }
                                        }
                                    />
                                </form>
                            ) 
                        }
                    } 
                />  
            </>
        )
    }
}

export default AddressForm