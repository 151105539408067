import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cartMapStateToProps, cartMapDispatchToProps } from 'Services/Redux/Constants/Cart'

class BundlingPersonalizedAddToCart extends Component {
    onClick = () => {
        const { bundlings, cartData, detailData, color, onSyncCart, onToggleCartSlider, history } = this.props
        let groupBundling = []

        bundlings.forEach(el => {
            const target = groupBundling.find(bundling => {
                return bundling.sizeId === el.sizeId
            })
            if(target){
                if(el.key === 'suitcase'){
                    if(el.value){
                        target.suitcase = {
                            letter: el.value.toUpperCase(),
                            position: el.position
                        }
                    }
                }else if(el.key === 'tag'){
                    if(el.value){
                        target.tag = {
                            letter: el.value.toUpperCase(),
                            tagColor: el.tagColor,
                            textColor : el.textColor,
                        }
                    }
                }
            }else{
                groupBundling.push({
                    sizeId: el.sizeId,
                    suitcase: {
                        letter: el.key === 'suitcase' ? el.value.toUpperCase() : '',
                        position: el.key === 'suitcase' ? el.position : ''
                    },
                    tag: {
                        letter: el.key === 'tag' ? el.value.toUpperCase() : '',
                        tagColor: el.key === 'tag' ? el.tagColor : '',
                        textColor : el.key === 'tag' ? el.textColor : '',
                    }
                })
            }
        })
        // generate cart data final
        const { personalized } = detailData
        let personalizeData = null
        let cartDataFinal = cartData
        let canEditPersonalize = false
        if(personalized.suitcase || personalized.tag){
            canEditPersonalize = true
        }

        groupBundling.map(el => {
            const targetSize = detailData.available_sizes.find(size => {
                return size.id === el.sizeId
            })

            let targetItem = null
            if(targetSize && targetSize.available_colors){
                targetItem = targetSize.available_colors.find(el => {
                    return el.id === parseInt(color)
                })
            }

            if(!targetItem){
                return null
            }

            personalizeData = {
                suitcase: {
                    letter: el.suitcase.letter,
                    selectedMargin: el.suitcase.position,
                    price: parseInt(detailData.personalized.suitcase_price)
                },
                tag: {
                    letter: el.tag.letter,
                    textColor: el.tag.textColor,
                    tagColor: el.tag.tagColor,
                    tagProductId: detailData.id,
                    tagSizeId: el.sizeId,
                    price: parseInt(detailData.personalized.tag_price)
                },
            }
            
            return cartDataFinal.push({
                id: detailData.id,
                name: detailData.name,
                image: targetItem.product_images ? targetItem.product_images[0] : null,
                sizeId: el.sizeId,
                sizeName: targetSize.name,
                quantity: 1,
                price: targetSize.price + personalizeData.suitcase.price + personalizeData.tag.price,
                colorId: targetItem.id,
                colorName: targetItem.name,
                personalized: personalizeData,
                canEditPersonalize: canEditPersonalize
            })
        })

        onSyncCart(cartDataFinal)
        onToggleCartSlider(true)
        return  history.push('/catalog')
    }

    render() {
        const { children, disabled, className } = this.props
        return (
            <button
                className={ className }
                type="button"
                disabled={ disabled }
                onClick={ this.onClick }
            >
                { children }
            </button>
        )
    }
}

export default connect(cartMapStateToProps, cartMapDispatchToProps)(BundlingPersonalizedAddToCart) 