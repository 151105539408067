import React from 'react'
import { NavLink } from 'react-router-dom'

const ShowCase = () => {
    return (
        <div className="products-note">
            <div className="container-intro">
                <div className="row">
                    <div className="col-md-5">
                        <div className="card-info">
                            <div className="head">
                                <h1>The Continental</h1>
                            </div>
                            <div className="body">
                                <p className="showcase">
                                Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus.
                                </p>
                                <NavLink className="button-showcase" to={ `/catalog/suitcases` }>Shop Suitcases</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <img src="images/home/suitcases/suitcases.jpg" alt="showcase"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowCase